import "./App.css";
import globalContext from "./Contexts/GlobalContext";
import { useEffect, useState } from "react";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import AlertMessage from "./components/AlertMessage";
import MenuBar from "./components/menubar/MenuBar";
import Offline from "./components/Offline";

function App() {
  const router = useRoutes(routes);

  const [alertView, setAlertView] = useState(false);
  const [alertMode, setAlertMode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDuration, setAlertDuration] = useState(3000);
  const [alertConfirmation, setAlertConfirmation] = useState(false);
  const [alertMessageConfirmation, setAlertMessageConfirmation] = useState("");
  const [alertResultConfirmation, setAlertResultConfirmation] = useState(false);

  const [isMenuBarOpen, setIsMenuBarOpen] = useState("");

  const [status, setStatus] = useState(() => {
    if (navigator.onLine) {
      return true;
    } else {
      return false;
    }
  });
  useEffect(() => {
    window.ononline = (e) => {
      setStatus(true);
    };
    window.onoffline = (e) => {
      setStatus(false);
    };
  }, [status]);

  return (
    <>
      <globalContext.Provider
        value={{
          alertView,
          setAlertView,
          alertMode,
          setAlertMode,
          alertMessage,
          setAlertMessage,
          alertDuration,
          setAlertDuration,
          alertConfirmation,
          setAlertConfirmation,
          alertMessageConfirmation,
          setAlertMessageConfirmation,
          alertResultConfirmation,
          setAlertResultConfirmation,
          isMenuBarOpen,
          setIsMenuBarOpen,
        }}
      >
        {!status ? (
          <>{<Offline setStatus={setStatus} />}</>
        ) : (
          <>
            {alertView && <AlertMessage bottom={0} left={0} />}
            {isMenuBarOpen === "true" && <MenuBar />}
            {router}
          </>
        )}
      </globalContext.Provider>
    </>
  );
}

export default App;
