import React, { useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";

export default function ProductModal({ setIsProductModalOpen, productModal }) {
  const [status, setStatus] = useState(2);
  return (
    <div
      className="fixed w-full h-screen top-0 bottom-0 flex flex-col items-center justify-center gap-y-[4px] bg-[#222222d2] z-[1000]"
      onClick={() => {
        setIsProductModalOpen(false);
      }}
    >
      <div
        className="w-[300px] min-[480px]:w-[650px] min-[1024px]:w-[800px] flex items-center justify-end "
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          className="text-white cursor-pointer"
          onClick={() => {
            setIsProductModalOpen(false);
          }}
        >
          <IoCloseCircleOutline size={30} />
        </span>
      </div>
      <div
        className=" flex items-center justify-center w-[300px] min-[480px]:w-[650px] min-[1024px]:w-[800px] h-[350px] min-[480px]:h-[600px]  bg-color-BG rounded-xl shadow-[0px_0px_5px_rgba(0,0,0,0.3)]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="relative w-full  min-[480px]:w-[350px] min-[1024px]:w-[500px] h-full flex items-center justify-center bg-white rounded-md min-[480px]:rounded-tl-xl min-[480px]:rounded-bl-xl">
          <img
            src={`${productModal?.image}`}
            className="object-contain w-[200px] h-[200px] min-[480px]:w-[300px] min-[480px]:h-[300px]"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "../assets/images/products/Not_available.svg";
            }}
          />

          <img
            style={{ width: "160px", height: "60px" }}
            src="../assets/images/manufacturers/1global.png"
            className="absolute top-[10px] left-[10px] object-contain  "
          />
        </div>
        <div className="hidden min-[480px]:flex min-[480px]:flex-col min-[480px]:items-center min-[480px]:w-[300px] min-[1024px]:w-[300px] h-full justify-between px-[16px] ">
          <div className="w-full">
            <div className="w-full flex items-center justify-start text-color-TXT-dark font-bold text-[18px] mt-[16px]">
              {productModal?.mfrPartNumber}
            </div>
            <div className="w-full text-justify whitespace-normal break-words font-light text-color-TXT-dark text-[14px] mt-[16px]">
              {productModal?.description}
            </div>
          </div>

          <div className="w-full mb-[16px]">
            <div className="w-full flex items-center justify-between mt-[24px]">
              <div className="text-[12px] font-bold">Manufacturer</div>
              <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
              <div className="text-[12px]">{productModal?.manufacturer}</div>
            </div>

            <div className="w-full flex items-center justify-between mt-[16px]">
              <div className="text-[12px] font-bold">Price</div>
              <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
              <div className="text-[12px] flex items-center justify-center gap-x-[4px]">
                <span>{productModal?.price}</span>
                <span>$</span>
              </div>
            </div>

            <div
              className={`w-full h-[30px] flex items-center justify-center rounded-md border-[1px] mt-[16px] ${
                status === 0 && "border-[#42e350] text-[#00b00f] bg-green-200"
              } ${
                status === 1 && "border-[#b83b3b] text-[#b80000] bg-red-200"
              } ${
                status === 2 && "border-[#ffdf52] text-[#baa204] bg-yellow-100"
              } `}
            >
              Active
            </div>

            <div
              className={`w-full  flex items-center justify-center rounded-md border-[1px] mt-[16px] cursor-pointer bg-white p-[16px]`}
            >
              <span className="text-color-B w-[45px] h-full flex items-center justify-center">
                <FaFilePdf size={20} />
              </span>
              <span className="flex-1 flex items-center justify-center">
                Download Datasheet
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
