import React from "react";
import { AiFillLinkedin, AiFillWechat } from "react-icons/ai";
import { BsChatDotsFill, BsTwitterX } from "react-icons/bs";
import { FaInstagram, FaRegCopyright, FaTelegramPlane } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";

export default function MFooter() {
  return (
    <>
      <div className="w-full flex flex-col items-center justify-between bg-color-A p-[20px]">
        <div className="w-full text-justify text-[13px]  text-color-TXT mt-[8px]">
          <span className="font-bold text-[15px] text-color-A-2">
            IC World Electronics
          </span>
          &nbsp; is recognized as a reliable distributor of electronic
          components and automation products worldwide. Founded in 2014, IC
          World Electronics has always been a pioneer in the mail-order catalog
          business and a key resource for design engineers.
        </div>

        <div className="w-full flex items-center justify-start mt-[32px]">
          <span className="font-bold text-[16px] text-color-TXT">
            FOLLOW US
          </span>
        </div>
        <div className="w-full flex gap-x-[8px] mt-[8px] ">
          <a
            className="cursor-pointer text-[20px] text-color-BG-1 hover:text-white"
            href="https://www.instagram.com/icworldelectronics?igsh=MWs3cnZwOTVsMmdqNQ=="
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            className="cursor-pointer text-[20px] text-color-BG-1 hover:text-white"
            href="https://linkedin.com/company/icworldelectronics/?viewAsMember=true"
            target="_blank"
          >
            <AiFillLinkedin />
          </a>
          <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
            <FaTelegramPlane />
          </span>
          <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
            <AiFillWechat />
          </span>
          <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
            <BsTwitterX />
          </span>
        </div>

        <div className="w-full flex items-center justify-center gap-x-3 flex-wrap mt-[40px] text-[12px]">
          <span className="flex items-center justify-start gap-x-[8px] rounded-[5px] cursor-pointer font-normal text-color-TXT-1 hover:text-white whitespace-nowrap resize-none">
            Terms & Conditions
          </span>
          <span className="font-semibold text-[12px] text-color-TXT-1">|</span>
          <span className="flex items-center justify-start gap-x-[8px] rounded-[5px] cursor-pointer font-normal text-color-TXT-1 hover:text-white whitespace-nowrap resize-none">
            Privacy Notice
          </span>
        </div>
        <div className="w-full flex items-center justify-center gap-x-3 flex-wrap  text-[12px]">
          <span className="flex items-center justify-start gap-x-[8px] rounded-[5px] cursor-pointer font-normal text-color-TXT-1 hover:text-white whitespace-nowrap resize-none">
            Blog
          </span>
          <span className="font-semibold text-[12px] text-color-TXT-1">|</span>

          <span className="flex items-center justify-start gap-x-[8px] rounded-[5px] cursor-pointer font-normal text-color-TXT-1 hover:text-white whitespace-nowrap resize-none">
            Accessibility Statement
          </span>
        </div>
      </div>
      <span className="w-full flex items-center justify-center gap-x-[8px] text-[10px] font-normal text-color-TXT-1 py-1 bg-color-TXT-dark">
        Copyright <FaRegCopyright /> 2014 - 2024 IcWorldelect.com, All Rights
        Reserved
      </span>
    </>
  );
}
