import React from "react";
import ReactLoading from "react-loading";

export default function PreLoading() {
  return (
    <div className="fixed w-full h-screen top-0 bottom-0 flex flex-col items-center justify-center gap-y-[4px] bg-[#222222d2] z-[1000]">
      <ReactLoading type={"spin"} color={"#ECECEB"} height={90} width={90} />
    </div>
  );
}
