const serverErrorMessage = (code) => {
  switch (code) {
    case 1001:
      return "Invalid Username Or Password";
    case 1002:
      return "This Customer is Already Exist In List";
    case 4004:
      return "An unexpected error  occurred";
    default:
      return "ُSomething Went Wrong Please Try Again";
  }
};
export { serverErrorMessage };
