import React from "react";
import { AiFillLinkedin, AiFillWechat } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import { FaInstagram, FaTelegramPlane } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";

export default function SharePage({ setIsProductShareOpen }) {
  return (
    <div
      className="fixed w-full h-screen top-0 bottom-0 flex flex-col items-center justify-center gap-y-[4px] bg-[#222222d2] z-[1000]"
      onClick={() => {
        setIsProductShareOpen(false);
      }}
    >
      <div className="w-full px-[16px] min-[768px]:w-[400px] min-[768px]:px-0 flex flex-col items-center justify-center gap-y-[4px] ">
        <div
          className="w-full flex items-center justify-end "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span
            className="text-white cursor-pointer"
            onClick={() => {
              setIsProductShareOpen(false);
            }}
          >
            <IoCloseCircleOutline size={30} />
          </span>
        </div>
        <div
          className="w-full h-[300px] flex flex-col items-center justify-center gap-y-[8px] bg-white rounded-xl px-[24px]"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full flex items-center justify-start text-color-TXT-dark text-[20px] font-bold">
            Share
          </div>
          <div className="w-full">
            <div className="w-full flex items-center justify-start">
              Share this on:
            </div>
            <div className="w-full flex items-center justify-start gap-x-[8px] mt-[4px]">
              <span
                className="cursor-pointer text-[20px] bg-color-A-1 p-[4px] rounded-sm text-color-BG"
                onClick={() => {
                  const url = window.location.href;

                  window.open(
                    `https://www.instagram.com/create/story/?text=${encodeURIComponent(
                      url
                    )}`,
                    "_blank"
                  );
                }}
              >
                <FaInstagram />
              </span>
              <span className="cursor-pointer text-[20px] bg-color-A-1 p-[4px] rounded-sm text-color-BG">
                <AiFillLinkedin />
              </span>
              <span className="cursor-pointer text-[20px] bg-color-A-1 p-[4px] rounded-sm text-color-BG">
                <FaTelegramPlane />
              </span>
              <span className="cursor-pointer text-[20px] bg-color-A-1 p-[4px] rounded-sm text-color-BG">
                <AiFillWechat />
              </span>
              <span className="cursor-pointer text-[20px] bg-color-A-1 p-[4px] rounded-sm text-color-BG">
                <BsTwitterX />
              </span>
            </div>
          </div>
          <div className="w-full mt-[16px]">
            <div className="w-full flex items-center justify-start">
              Or copy the link below:
            </div>
            <div className="w-full py-[4px] px-[16px] border-[1px] border-color-TXT-dark rounded-sm  flex items-center justify-start gap-x-[8px] mt-[4px] overflow-auto">
              {window.location.href}
            </div>
          </div>

          <div className="w-full flex items-center justify-start mt-[8px]">
            <button
              className="w-[80px] py-[4px] rounded-md bg-color-B text-color-BG hover:bg-[#471c1c]"
              onClick={() => {
                setIsProductShareOpen(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
