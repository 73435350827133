import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import MManufactures from "../modules/MManufactures";
import ScrollToFirst from "../components/ScrollToFirst";

export default function Manufacturers() {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("");
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 740) setViewMode("M");
      else setViewMode("D");
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.screen.width]);
  return (
    <>
      <Header />

      {viewMode === "M" ? (
        <MManufactures />
      ) : (
        <div className="w-full h-full min-h-screen flex flex-col justify-between items-center gap-y-[24px] bg-color-BG">
          <div className="absolute w-full h-[250px] bg-color-A z-0"></div>
          <div className="relative w-full h-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0  flex justify-between items-start gap-x-[32px] z-10">
            <div className="h-full flex-1 flex-col justify-center items-start">
              <div className="pr-[16px] py-[32px]">
                <h1 className="text-color-TXT text-[24px] font-bold">
                  MANUFACTURERS
                </h1>
                <p className="text-color-TXT-1 text-[14px] mt-[20px] text-justify">
                  ICWorldelect is an authorized distributor of electronic.
                  <br />
                  This means The customers can rest assured that the product
                  they order is authentic and comes to ICWorldelect directly
                  from the manufacturer.
                </p>
                <button
                  className="flex justify-center items-center text-[13px] font-bold Type_2 px-[16px] py-[8px] mt-[24px]"
                  onClick={() => {
                    navigate("/manufacturers/linecard");
                  }}
                >
                  Manufacturer Linecard
                </button>
              </div>
              <div className="absolute h-[100px] w-[calc(100%-300px)] -left-5 bg-gradient-to-b from-color-BG to-transparent mt-[3px] z-20"></div>
              <div className="absolute h-[100px] w-[calc(100%-300px)] bottom-0 -left-5 bg-gradient-to-t from-color-BG to-transparent mt-[61px] z-20"></div>
              <div className="h-[750px] w-full flex justify-start items-start rounded-xl gap-x-[16px] mt-[6px]">
                <div className="w-full h-full flex flex-col justify-between items-start gap-y-[20px] overflow-hidden px-[4px]">
                  <img
                    src="../assets/images/manufacturers/1global.png"
                    className="item1_1 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/aaronia.png"
                    className="item1_2 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/able-power-products.png"
                    className="item1_3 relative w-[200px] h-[80px] rounded-xl object-contain py-[4px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/ablic.png"
                    className="item1_4 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/adtek.png"
                    className="item1_5 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/aismalibar.png"
                    className="item1_6 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/aker.png"
                    className="item1_7 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/amtech.png"
                    className="item1_8 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/fath.png"
                    className="item1_9 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                  <img
                    src="../assets/images/manufacturers/cassia.png"
                    className="item1_10 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl "
                  />
                </div>
                <div className="w-full h-full flex flex-col justify-between items-start gap-y-[20px] overflow-hidden px-[4px]">
                  <img
                    src="../assets/images/manufacturers/balluff.png"
                    className="item2_1 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/bel_fuse.png"
                    className="item2_2 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/bluelec.png"
                    className="item2_3 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/boyd.png"
                    className="item2_4 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/caig.png"
                    className="item2_5 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/ceva.png"
                    className="item2_6 relative w-[200px] h-[80px] rounded-xl object-contain py-[16px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/comair.png"
                    className="item2_7 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/digi_international.png"
                    className="item2_8 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/knowles.png"
                    className="item2_9 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/desco.png"
                    className="item2_10 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
                <div className="w-full h-full flex flex-col justify-between items-start gap-y-[20px] overflow-hidden px-[4px]">
                  <img
                    src="../assets/images/manufacturers/e2ip-technologies.png"
                    className="item3_1 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/eao_corporation.png"
                    className="item3_2 relative w-[200px] h-[80px] rounded-xl object-contain py-[4px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="item3_3 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/chipsee.png"
                    className="item3_4 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/choovio.png"
                    className="item3_5 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/chromeled.png"
                    className="item3_6 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="item3_7 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/cliff.png"
                    className="item3_8 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/vishay.png"
                    className="item3_9 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/ranatec.png"
                    className="item3_10 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
                <div className="w-full h-full flex flex-col justify-between items-start gap-y-[20px] overflow-hidden px-[4px]">
                  <img
                    src="../assets/images/manufacturers/cui.png"
                    className="item4_1 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/dialight.png"
                    className="item4_2 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/dirak.png"
                    className="item4_3 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/dremel.png"
                    className="item4_4 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/e-switch.png"
                    className="item4_5 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/fabru.png"
                    className="item4_6 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/eaton.png"
                    className="item4_7 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/endress-hauser.png"
                    className="item4_8 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="item4_9 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                  <img
                    src="../assets/images/manufacturers/xvisio.png"
                    className="item4_10 relative w-[200px] h-[80px] rounded-xl object-contain py-[12px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </div>
            </div>
            <div className="relative mt-[90px] mr-6">
              <span className="absolute w-[300px] h-[300px] rounded-xl top-4 left-4 border-[3px] border-color-A-2"></span>
              <img
                src="../assets/images/img-microchips.png"
                className="relative w-[300px] h-[300px] top-0 left-0 rounded-xl object-cover z-30"
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
      <ScrollToFirst />
    </>
  );
}
