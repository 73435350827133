import React from "react";

export default function ProductBox({
  productTitle,
  productImg,
  productDesc,
  hasLabel,
  labelImg,
  width = "auto",
  height = "auto",
}) {
  return (
    <div
      style={{ width: width, height: height }}
      className="flex flex-col items-center justify-start rounded-md px-[20px] cursor-pointer"
    >
      <p className="w-full flex items-center justify-start mt-5 text-[12px] font-bold text-color-A whitespace-nowrap  overflow-hidden text-ellipsis">
        {productTitle}
      </p>

      <div className="w-full flex items-center justify-center my-5">
        <img
          src={productImg}
          style={{ width: "120px", height: "120px" }}
          className="rounded-md  object-contain "
        />
      </div>

      <div className="w-full text-[12px] overflow-hidden">
        {hasLabel && (
          <img
            src={labelImg}
            style={{ width: "45px", height: "45px" }}
            className="float-left mr-3"
          />
        )}

        <p className=" text-justify h-[150px] py-[1px]">{productDesc}</p>
      </div>
    </div>
  );
}
