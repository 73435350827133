import "../assets/Css/Styles/general.css";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import ScrollToFirst from "../components/ScrollToFirst";

export default function AboutUs() {
  const [viewMode, setViewMode] = useState("");
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 740) setViewMode("M");
      else setViewMode("D");
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.screen.width]);
  return (
    <>
      <Header />

      {viewMode === "M" ? (
        <div className="w-full h-full min-h-screen flex flex-col justify-between items-center gap-y-[24px] bg-color-BG pb-[24px]">
          <div className="w-full flex items-center justify-center px-[20px]">
            <div className="w-full flex-1 p-[24px] mt-[16px] text-color-TXT-dark">
              <span className="w-full flex items-center justify-start text-[48px] font-bold text-[#6d6d6d] text-justify">
                ABOUT US
              </span>
              <p className="text-[14px] mt-[24px] font-medium text-justify">
                IC World Electronics is recognized as a reliable distributor of
                electronic components and automation products worldwide. Founded
                in 2014, IC World Electronics has always been a pioneer in the
                mail-order catalog business and a key resource for design
                engineers.
                <br />
                Today, IC World Electronics offers one of the world’s largest
                selection of electronic components in stock and available for
                immediate shipment.
                <br />
                <br />
                We are proud to be recognized as a trusted supplier in the
                electronics industry
              </p>
            </div>
          </div>

          <div className="w-full flex flex-col items-center justify-center gap-y-[20px] p-[20px] mt-[16px] ">
            <div className="relative w-full h-full flex flex-col justify-start items-start rounded-3xl p-[24px] bg-color-TXT-dark">
              <h3 className="text-color-TXT text-[28px] font-bold">
                Availability
              </h3>
              <p className="text-color-TXT-1 text-[14px] mt-[24px]">
                Product availability is the ability to provide a given product
                based on customer demand, which is a measure of whether a
                product is available in stock and customers can order it or not.
              </p>
              <IoCheckmarkDoneOutline className="absolute text-[80px] right-0 -top-[35px] text-color-A-2" />
            </div>
            <div className="relative w-full h-full flex flex-col justify-start items-start rounded-3xl p-[24px] bg-color-TXT-dark">
              <h3 className="text-color-TXT text-[28px] font-bold">On-Time</h3>
              <p className="text-color-TXT-1 text-[14px] mt-[24px]">
                On-time delivery is a performance indicator that shows the
                amount of on-time delivery, and large stores have this
                capability.
              </p>
              <IoCheckmarkDoneOutline className="absolute text-[80px] right-0 -top-[35px] text-color-A-2" />
            </div>
            <div className="relative w-full h-full flex flex-col justify-start items-start rounded-3xl p-[24px] bg-color-TXT-dark">
              <h3 className="text-color-TXT text-[28px] font-bold">
                Performance
              </h3>
              <p className="text-color-TXT-1 text-[14px] mt-[24px]">
                Excellent overall performance and appropriate against customer
                orders.
              </p>
              <IoCheckmarkDoneOutline className="absolute text-[80px] right-0 -top-[35px] text-color-A-2" />
            </div>
          </div>

          <div className="w-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 flex justify-center items-center ">
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper w-[800px] h-[400px] rounded-2xl"
            >
              <SwiperSlide>
                <div className="w-full h-full  flex items-center justify-center">
                  <img
                    src="../assets/images/img-office-1.webp"
                    className="w-full h-[400px] object-cover rounded-2xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full  flex items-center justify-center">
                  <img
                    src="../assets/images/img-office-2.webp"
                    className="w-full h-[400px] object-cover rounded-2xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      ) : (
        <div className="w-full h-full min-h-screen flex flex-col justify-between items-center gap-y-[24px] bg-color-BG pb-[24px]">
          <div className="w-full h-[450px] px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 flex justify-between items-center gap-x-[32px]">
            <div className="w-full flex-1 mr-[45px] text-[#2c2c2c]">
              <span className="w-full flex items-center justify-start text-[68px] font-bold text-[#d6d6d6] resize-none whitespace-nowrap">
                ABOUT US
              </span>
              <p className="text-justify text-[14px] mt-[16px] font-medium">
                IC World Electronics is recognized as a reliable distributor of
                electronic components and automation products worldwide. Founded
                in 2014, IC World Electronics has always been a pioneer in the
                mail-order catalog business and a key resource for design
                engineers.
                <br />
                Today, IC World Electronics offers one of the world’s largest
                selection of electronic components in stock and available for
                immediate shipment.
                <br />
                <br />
                We are proud to be recognized as a trusted supplier in the
                electronics industry
              </p>
            </div>
            <div className="relative mr-6">
              <span className="absolute w-[300px] h-[300px] rounded-xl top-4 left-4 border-[3px] border-color-A-2"></span>
              <img
                src="../assets/images/img-Company.png"
                className="relative w-[300px] h-[300px] top-0 left-0 rounded-xl object-cover"
              />
            </div>
          </div>

          <div className="h-[300px] w-full flex justify-center bg-color-A">
            <div className="w-full h-full flex justify-between items-center px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 gap-x-10 py-[50px]">
              <div className="relative w-full h-full flex flex-col justify-start items-start rounded-3xl p-[24px] bg-[#29292946]">
                <h3 className="text-color-TXT text-[28px] font-bold">
                  Availability
                </h3>
                <p className="text-color-TXT-1 text-[14px] mt-[24px]">
                  Product availability is the ability to provide a given product
                  based on customer demand, which is a measure of whether a
                  product is available in stock and customers can order it or
                  not.
                </p>
                <IoCheckmarkDoneOutline className="absolute text-[80px] right-0 -top-[35px] text-color-A-2" />
              </div>
              <div className="relative w-full h-full flex flex-col justify-start items-start rounded-3xl p-[24px] bg-[#29292946]">
                <h3 className="text-color-TXT text-[28px] font-bold">
                  On-Time
                </h3>
                <p className="text-color-TXT-1 text-[14px] mt-[24px]">
                  On-time delivery is a performance indicator that shows the
                  amount of on-time delivery, and large stores have this
                  capability.
                </p>
                <IoCheckmarkDoneOutline className="absolute text-[80px] right-0 -top-[35px] text-color-A-2" />
              </div>
              <div className="relative w-full h-full flex flex-col justify-start items-start rounded-3xl p-[24px] bg-[#29292946]">
                <h3 className="text-color-TXT text-[28px] font-bold">
                  Performance
                </h3>
                <p className="text-color-TXT-1 text-[14px] mt-[24px]">
                  Excellent overall performance and appropriate against customer
                  orders.
                </p>
                <IoCheckmarkDoneOutline className="absolute text-[80px] right-0 -top-[35px] text-color-A-2" />
              </div>
            </div>
          </div>

          <div className="w-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 flex justify-center items-center my-[40px]">
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper w-[800px] h-[400px] rounded-2xl"
            >
              <SwiperSlide>
                <div className="w-full h-full  flex items-center justify-center">
                  <img
                    src="../assets/images/img-office-1.webp"
                    className="w-full h-[400px] object-cover rounded-2xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-full  flex items-center justify-center">
                  <img
                    src="../assets/images/img-office-2.webp"
                    className="w-full h-[400px] object-cover rounded-2xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      <Footer />
      <ScrollToFirst />
    </>
  );
}
