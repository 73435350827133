import React from "react";
import { useNavigate } from "react-router-dom";

export default function Error_404() {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full h-screen flex flex-col items-center justify-center bg-color-BG">
        <h1 className="text-[120px] font-bold text-color-B">404</h1>
        <h2 className="text-[24px] font-semibold text-[#2c2c2c] mt-[16px]">
          PAGE NOT FOUND
        </h2>
        <p className="text-[14px] font-semibold text-[#2c2c2c] mt-[8px]">
          Uh oh, we can't seem to find the page you're looking for. Let's try
          again.
        </p>
        <button
          className="flex justify-center items-center text-[13px] font-bold Type_3 px-[32px] py-[8px] mt-[24px]"
          onClick={() => {
            navigate("/");
          }}
        >
          Back To Home
        </button>
      </div>
    </>
  );
}
