import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";

import "../assets/Css/Styles/FeaturedProducts.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FEATURED_PRODUCTS } from "../constants/constanst_featuredProducts";
import ProductBox from "./ProductBox";

export default function FeaturedProducts() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className = "pg") {
      return '<span className="' + className + '">' + " " + "</span>";
    },
  };
  const [swiper, setSwiper] = useState(null);
  const [slidesPVPG, setSlidesPVPG] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 740) setSlidesPVPG(1);
      else if (window.screen.width <= 1024) setSlidesPVPG(2);
      else setSlidesPVPG(4);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.screen.width]);
  return (
    <div className="w-full h-auto">
      <div className="w-full text-color-TXT-dark font-bold">
        Featured Products
      </div>
      <div className="w-full h-[400px] relative mt-2">
        <Swiper
          slidesPerView={slidesPVPG}
          slidesPerGroup={slidesPVPG}
          spaceBetween={25}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[Pagination]}
          className="customeWrapper1 rounded-[5px]"
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
        >
          {FEATURED_PRODUCTS.map((fp) => (
            <SwiperSlide>
              <ProductBox
                productTitle={fp?.productTitle}
                productImg={fp?.productImg}
                productDesc={fp?.productDesc}
                hasLabel={fp?.hasLabel}
                labelImg={fp?.labelImg}
                width="100%"
                height="100%"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="absolute w-full flex items-center justify-center gap-x-[200px] min-[768px]:gap-x-[128px] bottom-0 z-20">
          <span
            className="text-color-TXT-dark text-[20px] cursor-pointer p-1 "
            onClick={() => {
              swiper.slidePrev();
            }}
          >
            <FaChevronLeft />
          </span>

          <span
            className="text-color-TXT-dark text-[20px] cursor-pointer p-1"
            onClick={() => {
              swiper.slideNext();
            }}
          >
            <FaChevronRight />
          </span>
        </div>
      </div>
    </div>
  );
}
