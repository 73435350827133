const SERVERMODE = {
  REQUEST_OK: "ok",
  INVALID_USER_PASS: "userOrPassInvalid",
  CUSTOMER_IS_EXIST: "customerExist",

  REQUEST_ERROR: "Error",
  REQUEST_BAD: "BadRequest",
  REQUEST_WRONG: "Wrong",
  REQUEST_FIELD: "QueryFailed",
  REQUEST_REPEAT: "RepeatRequest",
  REQUEST_NOT_FOUND: "RequestNotFound",
  REQUEST_NOT_SUPPORT: "RequestNotSupport",
  REQUEST_TOKEN_EXPIRE: "ExpireToken",
  REQUEST_TOKEN_WRONG: "WrongToken",
  REQUEST_TOKEN_ERROR: "ErrorToken",

  USER_NOT_FOUND: "UserNotFound",
  USER_NOT_FOUND_IDENTIFI: "UserIdentifiIDNotFound",
  USER_UNABLE_SET_IDENTIFI: "UserUnableSetIdentifiID",
  CAR_NOT_FOUND: "CarNotFound",
  WRONG_KM_REPLACE: "WrongKmReplace",
  WRONG_KM_CURRENT: "WrongKmCurrent",
  WRONG_CODE: "WrongCode",
  EXPIRE_CODE: "ExpireCode",
  SERVICE_NOT_EXIST: "NotExistService",
  DATA_ILLOGICAL: "IllogicalData",
  SERVICE_NOT_EXIST: "HistoryNotFound",
};

export { SERVERMODE };
