import "./MenuBar.css";
import globalContext from "../../Contexts/GlobalContext";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { GrClose } from "react-icons/gr";
import { FaInstagram, FaTelegramPlane } from "react-icons/fa";
import { AiFillLinkedin, AiFillWechat } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";

export default function MenuBar({ isMenuBarOpen, setIsMenuBarOpen }) {
  const contextData = useContext(globalContext);
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();
  const box1 = useRef();
  const box2 = useRef();
  const box3 = useRef();
  const box4 = useRef();
  const menubarContainer = useRef();
  useEffect(() => {
    if (contextData?.isMenuBarOpen === "true") {
      menubarContainer?.current?.classList?.remove("outMenu");
      menubarContainer?.current?.classList?.add("inMenu");
    } else if (isMenuBarOpen === "false") {
      menubarContainer?.current?.classList?.remove("inMenu");
      menubarContainer?.current?.classList?.add("outMenu");
      setTimeout(() => {
        contextData?.setIsMenuBarOpen("");
      }, 500);
    } else {
    }
  }, [contextData?.isMenuBarOpen]);

  return (
    <>
      <div
        className={`absolute  w-[100vw] h-screen z-[1000] backdrop-blur-sm  ${
          contextData?.isMenuBarOpen === "true" ? "block" : "hidden"
        }`}
        onClick={() => {
          contextData?.setIsMenuBarOpen("false");
        }}
      ></div>

      <div
        ref={menubarContainer}
        className={`fixed w-[100vw] bg-[#f7f7f7] h-full top-0 -left-[100vw] flex flex-col items-center justify-start bg-color-primary-B  z-[1000] overflow-hidden ${
          contextData?.isMenuBarOpen === "true" ? "block" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full h-[60px] flex items-center justify-between bg-color-A px-[20px] text-[18px] font-bold ">
          <div
            className=""
            onClick={() => {
              navigate("/");
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            <span className="text-[#84C7F2]">IC World</span>
            <span className="text-[#ECECEB]"> Electronics</span>
          </div>
          <span
            className="text-[#ECECEB] text-[20px]"
            onClick={() => {
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            <GrClose />
          </span>
        </div>
        <div className="w-full px-[20px]">
          <div
            className=" w-full h-[50px] flex items-center justify-start text-[15px] gap-x-3 text-color-TXT-dark border-b-[1px] border-[#2c2c2c14]"
            onClick={() => {
              navigate("/");
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            {window.location.pathname === "/" && (
              <span className="w-[3px] h-[30px] rounded-full bg-color-A "></span>
            )}
            <span
              className={`${
                window.location.pathname === "/" && "font-bold text-color-A"
              }`}
            >
              Home
            </span>
          </div>

          <div
            className=" w-full h-[50px] flex items-center justify-start text-[15px] gap-x-3 text-color-TXT-dark border-b-[1px] border-[#2c2c2c14]"
            onClick={() => {
              navigate("/products");
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            {window.location.pathname?.includes("products") && (
              <span className="w-[3px] h-[30px] rounded-full bg-color-A "></span>
            )}
            <span
              className={`${
                window.location.pathname
                  ?.toLocaleLowerCase()
                  ?.includes("products") && "font-bold text-color-A"
              }`}
            >
              Products
            </span>
          </div>
          <div
            className="w-full h-[50px] flex items-center justify-start  text-[15px] gap-x-3 text-color-TXT-dark border-b-[1px] border-[#2c2c2c14]"
            onClick={() => {
              navigate("/manufacturers");
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            {window.location.pathname
              ?.toLocaleLowerCase()
              ?.includes("manufacturers") && (
              <span className="w-[3px] h-[30px] rounded-full bg-color-A "></span>
            )}
            <span
              className={`${
                window.location.pathname
                  ?.toLocaleLowerCase()
                  ?.includes("manufacturers") && "font-bold text-color-A"
              }`}
            >
              Manufacturers
            </span>
          </div>
          <div
            className="w-full h-[50px] flex items-center justify-start text-[15px] gap-x-3 text-color-TXT-dark border-b-[1px] border-[#2c2c2c14]"
            onClick={() => {
              navigate("/about-us");
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            {window.location.pathname?.toLocaleLowerCase() === "/about-us" && (
              <span className="w-[3px] h-[30px] rounded-full bg-color-A "></span>
            )}
            <span
              className={`${
                window.location.pathname?.toLocaleLowerCase() === "/about-us" &&
                "font-bold text-color-A"
              }`}
            >
              About Us
            </span>
          </div>

          <div
            className="w-full h-[50px] flex items-center justify-start text-[15px] gap-x-3 text-color-TXT-dark border-b-[1px] border-[#2c2c2c14]"
            onClick={() => {
              navigate("/contact-us");
              contextData?.setIsMenuBarOpen("false");
            }}
          >
            {window.location.pathname?.toLocaleLowerCase() ===
              "/contact-us" && (
              <span className="w-[3px] h-[30px] rounded-full bg-color-A "></span>
            )}
            <span
              className={`${
                window.location.pathname?.toLocaleLowerCase() ===
                  "/contact-us" && "font-bold text-color-A"
              }`}
            >
              Contact Us
            </span>
          </div>
        </div>

        <div className="w-full flex items-center justify-center gap-x-[12px] mt-[8px] absolute bottom-[5%]">
          <a
            className="cursor-pointer text-[20px] text-color-A-1 "
            href="https://www.instagram.com/icworldelectronics?igsh=MWs3cnZwOTVsMmdqNQ=="
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            className="cursor-pointer text-[20px] text-color-A-1 "
            href="https://linkedin.com/company/icworldelectronics/?viewAsMember=true"
            target="_blank"
          >
            <AiFillLinkedin />
          </a>
          <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
            <FaTelegramPlane />
          </span>
          <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
            <AiFillWechat />
          </span>
          <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
            <BsTwitterX />
          </span>
        </div>
      </div>
    </>
  );
}
