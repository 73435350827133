import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Manufacturers from "./pages/Manufacturers";
import ContactUs from "./pages/ContactUs";
import Error_404 from "./pages/Error-404";
import Manufacturers_Linecard from "./pages/Manufacturers-Linecard";
import Product from "./pages/Product";
import Products from "./pages/Products";
import ProductSingle from "./pages/ProductSingle";

let routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/manufacturers",
    element: <Manufacturers />,
  },
  {
    path: "/manufacturers/linecard",
    element: <Manufacturers_Linecard />,
  },
  {
    path: "/product/*",
    element: <Product />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/product/ic-partnumber/:icPtn",
    element: <ProductSingle />,
  },
  // ------------------- 404 Code ------------------- //
  {
    path: "*",
    element: <Error_404 />,
  },
];

export default routes;
