import { FiDollarSign } from "react-icons/fi";
import { serverErrorMessage } from "../constants/serverMessage";
import { SERVERMODE } from "../constants/serverMode";
import { MdCurrencyYuan, MdEuro } from "react-icons/md";

export const addCommas = (num) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, "");

export const currencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return <FiDollarSign />;
    case "CNY":
      return <MdCurrencyYuan />;
    case "EUR":
      return <MdEuro />;
    default:
      break;
  }
};

export const capitalize = (str) => {
  let result = str.charAt(0).toUpperCase() + str.slice(1);
  return result;
};

export const getUrlSearchParam = (urlSearch, param) => {
  var paramUNtilEnd = "";
  if (urlSearch.includes(param)) {
    paramUNtilEnd = urlSearch.substring(
      urlSearch.indexOf(param) + param.length,
      urlSearch.length
    );
  }
  if (paramUNtilEnd.includes("&")) {
    paramUNtilEnd = paramUNtilEnd.substring(0, paramUNtilEnd.indexOf("&"));
  }
  console.log("paramUNtilEnd", paramUNtilEnd);
  return paramUNtilEnd;
};

export function AlertAlarm(
  contextData,
  Message,
  Mode = "SUCCES",
  Confirmation = false,
  MessageConfirmation = ""
) {
  if (contextData.alertView == false) {
    let Duration = 3000;
    contextData.setAlertView(true);
    contextData.setAlertMode(Mode);
    contextData.setAlertMessage(Message);

    if (Confirmation == true) {
      Duration = 10000;
      contextData.setAlertConfirmation(true);
      contextData.setAlertMessageConfirmation(MessageConfirmation);
      contextData.setAlertDuration(Duration);
    }

    setTimeout(() => {
      contextData.setAlertView(false);
      contextData.setAlertConfirmation(false);
      contextData.setAlertDuration(3000);
      contextData.setAlertMode("");
      contextData.setAlertMessage("");
      contextData.setAlertMessageConfirmation("");
    }, Duration);
  }
}

export function handleServerMode(
  mode,
  message = null,
  contextData,
  cookies,
  removeCookies,
  navigate
) {
  switch (mode) {
    case SERVERMODE.INVALID_USER_PASS: {
      AlertAlarm(contextData, serverErrorMessage(1001), "ERROR", false, "");
      break;
    }
    case SERVERMODE.CUSTOMER_IS_EXIST: {
      AlertAlarm(contextData, serverErrorMessage(1002), "ERROR", false, "");
      break;
    }
    default: {
      AlertAlarm(
        contextData,
        message || serverErrorMessage(4004),
        "ERROR",
        false,
        ""
      );
      break;
    }
  }
}

export function getMessage(code) {
  switch (code) {
    case 301:
      return "Fill the filed with only English letters";
    case 302:
      return "Field must be at least 3 characters";
    case 303:
      return "Please Enter Valid value";
    case 304:
      return "This Field Is Required";
    case 4004:
      return "An unexpected error  occurred";
    default:
      return "ُSomething Went Wrong Please Try Again";
  }
}
