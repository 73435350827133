import React, { useContext, useEffect, useState, version } from "react";
import { Manufactures } from "../constants/constanst_manufactures";
import Header from "../components/Header";
import ScrollToFirst from "../components/ScrollToFirst";
import Footer from "../components/Footer";
import { FaSearch } from "react-icons/fa";
import PreLoading from "../components/PreLoading";
import { SERVERMODE } from "../constants/serverMode";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import globalContext from "../Contexts/GlobalContext";
import {
  AlertAlarm,
  addCommas,
  handleServerMode,
  removeNonNumeric,
} from "../Js/functions";
import { PRODUCT_CATEGORY } from "../constants/constanst_productCategory";

export default function Products() {
  const BASE_URL = process.env.REACT_APP_ROOT_API_ADDRESS;
  const Nav = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();
  const contextData = useContext(globalContext);
  const location = useLocation();

  const [gettingProductFinish, setGettingProductFinish] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchedCount, setSearchedCount] = useState(0);
  const [isPreLoading, setIsPreLoading] = useState(false);
  const [categoryOriginal, setCategoryOrginal] = useState([]);
  const [categoryCopy, setCategoryCopy] = useState([]);

  const getCategoryServer = (version) => {
    setIsPreLoading(true);
    let reqBody = {};
    if (version) reqBody.version = version;
    axios
      .post(`${BASE_URL}/category`, reqBody)
      .then((res) => {
        var responseMessage = res?.data?.message;
        var responseMode = res?.data?.mode;
        if (responseMode === SERVERMODE.REQUEST_OK) {
          setCategoryOrginal(res?.data?.data?.categories);
          setCategoryCopy(res?.data?.data?.categories);
          localStorage.setItem(
            "productCategory",
            JSON.stringify(res?.data?.data?.categories)
          );
          localStorage.setItem(
            "productCategoryVersion",
            res?.data?.data?.version
          );
          setIsPreLoading(false);
          setGettingProductFinish(true);
        } else if (responseMode === "upToDateCategory") {
          setCategoryOrginal(
            JSON.parse(localStorage?.getItem("productCategory"))
          );
          setCategoryCopy(JSON.parse(localStorage?.getItem("productCategory")));
          setIsPreLoading(false);
          setGettingProductFinish(true);
        } else {
          handleServerMode(
            responseMode,
            responseMessage,
            contextData,
            cookies,
            removeCookies,
            Nav
          );
          setIsPreLoading(false);
          setGettingProductFinish(true);
        }
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message;
        if (errMessage?.length > 5) {
          AlertAlarm(contextData, errMessage, "ERROR", false, "");
        } else {
          AlertAlarm(contextData, "Something Went Wrong", "ERROR", false, "");
        }

        if (JSON.parse(localStorage?.getItem("productCategory"))?.length > 40) {
          setCategoryOrginal(
            JSON.parse(localStorage?.getItem("productCategory"))
          );
          setCategoryCopy(JSON.parse(localStorage?.getItem("productCategory")));
          setIsPreLoading(false);
        }
        setIsPreLoading(false);
        setGettingProductFinish(true);
      });
  };

  useEffect(() => {
    try {
      if (
        JSON.parse(localStorage?.getItem("productCategory"))?.length > 40 &&
        localStorage?.getItem("productCategoryVersion")
      ) {
        let categoryVersion = localStorage?.getItem("productCategoryVersion");
        getCategoryServer(categoryVersion);
      } else {
        getCategoryServer();
      }
    } catch (error) {
      localStorage.removeItem("productCategory");
      localStorage.removeItem("productCategoryVersion");
      getCategoryServer();
    }
  }, []);

  useEffect(() => {
    /******  search for url state search ****/
    if (categoryOriginal?.length > 0) {
      const search = location.search;
      const params = new URLSearchParams(search);
      const productSearch = params.get("productSearch");

      if (productSearch) {
        setSearchValue(productSearch);
        searchCategory(productSearch);
      }
    }
  }, [categoryOriginal]);

  const searchCategory = (SVAL) => {
    if (SVAL !== "") {
      setIsSearched(true);
    } else {
      setIsSearched(false);
    }

    let searchResult = [];
    let resultWrodCount = 0;
    categoryOriginal?.find((item) => {
      if (
        item?.master?.toLocaleLowerCase()?.includes(SVAL?.toLocaleLowerCase())
      ) {
        resultWrodCount++;

        if (!searchResult?.includes(item)) {
          searchResult.push(item);
        }
      }

      item?.sub?.find((findItem) => {
        if (
          findItem?.title
            ?.toLocaleLowerCase()
            .includes(SVAL?.toLocaleLowerCase())
        ) {
          resultWrodCount++;
          if (!searchResult?.includes(item)) {
            searchResult.push(item);
          }
        }

        findItem?.branch?.find((subFindItem) => {
          if (
            subFindItem?.title
              ?.toLocaleLowerCase()
              .includes(SVAL?.toLocaleLowerCase())
          ) {
            resultWrodCount++;
            if (!searchResult?.includes(item)) {
              searchResult.push(item);
            }
          }
        });
      });
    });

    if (searchResult?.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setCategoryCopy(searchResult);
      setSearchedCount(resultWrodCount);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setCategoryCopy(categoryOriginal);
      setSearchedCount(resultWrodCount);
    }
  };

  const moveToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop + 60,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header />
      <div className="relative min-h-screen min-[768px]:min-h-full w-full h-full  flex flex-col justify-start items-center  bg-color-BG">
        <div
          className={`${
            isPreLoading && "invisible"
          } w-full max-w-[1200px] px-[20px] min-[1240px]:px-0 flex items-center justify-start  text-[22px] font-bold mt-[20px]`}
        >
          Product Index
        </div>
        <p
          className={`${
            isPreLoading && "invisible"
          } w-full max-w-[1200px] px-[20px] min-[1240px]:px-0 flex items-center justify-start  text-[14px]  mt-[4px]`}
        >
          The products of different companies are listed in different categories
        </p>
        <div
          className={`${
            isPreLoading && "invisible"
          } relative w-full h-full max-w-[1200px] px-[20px] min-[1240px]:px-0 flex flex-col min-[768px]:flex-row items-start justify-between gap-x-[40px] mt-[20px] mb-[24px] z-10`}
        >
          <div className="w-full min-[768px]:w-[275px] min-[1240px]:w-[320px] h-auto min-[768px]:h-[700px] sticky top-[60px] min-[768px]:top-[100px] ">
            <div className="hidden w-full min-[768px]:flex items-center justify-start mt-[30px] border-b-[2px]  border-color-A text-color-A font-bold">
              Filters
            </div>

            <div className="relative w-full h-[40px] mt-[16px]">
              <input
                value={searchValue}
                placeholder="Search Within"
                className="w-full h-full text-[14px] text-color-TXT-dark bg-[#fff] border-[1px] border-[#CCCCCC] pl-[16px] pr-[48px] rounded-md placeholder:text-[#CCCCCC] placeholder:text-[14px] outline-none"
                onChange={(e) => {
                  setIsSearched(false);
                  setSearchValue(e.target.value);
                  if (e.target.value === "") searchCategory("");
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") searchCategory(searchValue);
                }}
              />

              <button
                className="absolute right-[8px] top-0 w-[25px] h-full text-color-TXT-dark flex items-center justify-center"
                onClick={() => {
                  searchCategory(searchValue);
                }}
              >
                <FaSearch />
              </button>
            </div>

            {isSearched && (
              <div className="w-full flex items-center justify-between text-[10px] text-color-TXT-dark mt-[4px] px-[4px]">
                <span className="text-color-TXT-dark">
                  Results items Found :
                </span>
                <span className="border-b-[1px] border-color-TXT-dark">
                  {searchedCount}
                </span>
              </div>
            )}

            <div className="hidden w-full min-[768px]:flex items-center justify-start mt-[30px] border-b-[2px]  border-color-A text-color-A font-bold">
              Categories
            </div>

            <div className="hidden w-full h-[500px] min-[768px]:flex flex-col items-start justify-start gap-y-[8px] overflow-auto mt-[16px]">
              {categoryCopy?.map((item) => (
                <div
                  className="text-start text-[12px] text-[#666666] font-bold cursor-pointer hover:border-b-[1px] border-[#CCCCCC]"
                  onClick={() => {
                    moveToSection(item?.id);
                  }}
                >
                  {item?.master}
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-between items-start  text-color-TXT-dark  overflow-auto">
            {categoryCopy?.map((item) => (
              <>
                <div
                  key={item.id}
                  id={item.id}
                  className=" h-full w-full text-[20px] font-semibold border-b-[2px] mt-[24px] border-color-A text-color-A"
                >
                  <span
                    className={`${
                      item.master
                        ?.toLocaleLowerCase()
                        .includes(searchValue?.toLocaleLowerCase()) &&
                      isSearched &&
                      "text-color-B"
                    }`}
                  >
                    {item.master}
                  </span>
                </div>
                <ul className="w-full columns-1 min-[768px]:columns-2 mt-[8px]">
                  {item?.sub?.map((tit) => (
                    <li className="flex flex-col items-start justify-start text-[14px] break-inside-avoid-column">
                      <Link
                        to={`/product/${tit?.link}?ic=${btoa(`1:25`).replaceAll(
                          "=",
                          ""
                        )}`}
                      >
                        <div
                          className={` ${
                            !tit?.branch && "cursor-pointer group"
                          }  pr-[20px]`}
                        >
                          <span
                            className={`group-hover:border-b-[1px] group-hover:text-[#666666] text-[12px] ${
                              tit.title
                                ?.toLocaleLowerCase()
                                ?.includes(searchValue?.toLocaleLowerCase()) &&
                              isSearched
                                ? "text-color-B"
                                : "text-color-TXT-dark"
                            }  border-color-TXT-dark `}
                          >
                            {tit.title}
                          </span>
                          <span className="group-hover:border-b-[1px] text-[10px] text-[#666666] border-color-TXT-dark ml-[8px] ">
                            {addCommas(removeNonNumeric(tit.count))} items
                          </span>
                        </div>
                      </Link>
                      {tit?.branch?.length > 0 && (
                        <ul className="ml-[2px] mb-[4px] py-[4px] px-[8px]  border-l-[2px] border-[#CCCCCC] flex flex-col items-center justify-start gap-y-[4px]">
                          {tit.branch.map((Stit) => (
                            <Link
                              className="w-full"
                              to={`/product/${tit?.link}?ic=${btoa(
                                `1:25`
                              ).replaceAll("=", "")}`}
                            >
                              <li className="w-full text-start group cursor-pointer">
                                <span
                                  className={`group-hover:border-b-[1px] group-hover:text-[#666666] border-color-TXT-dark text-[12px] ${
                                    Stit.title
                                      ?.toLocaleLowerCase()
                                      ?.includes(
                                        searchValue?.toLocaleLowerCase()
                                      ) && isSearched
                                      ? "text-color-B"
                                      : "text-color-TXT-dark"
                                  } `}
                                >
                                  {Stit.title}
                                </span>
                                <span className="ml-[8px] group-hover:border-b-[1px] border-color-TXT-dark text-[10px] text-[#666666]">
                                  {addCommas(removeNonNumeric(Stit.count))}
                                  item
                                </span>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            ))}

            {categoryCopy?.length === 0 && gettingProductFinish === true && (
              <div className="w-full h-screen top-0 left-0 fixed flex flex-col items-center justify-center bg-color-BG ">
                <img
                  src="../assets/images/404.svg"
                  className="w-[250px] h-[250px]"
                />
                <p className="w-full text-center text-[18px] text-color-TXT-dark mt-[16px]">
                  Some Things Went Wrong Please Try Again
                </p>
                <div className="w-full flex items-center justify-center">
                  <button
                    className="bg-color-B text-color-BG  font-bold rounded-md px-[40px] py-[8px] mt-[32px]"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <ScrollToFirst />
      </div>
      {isPreLoading && <PreLoading />}
      <Footer />
      <ScrollToFirst />
    </>
  );
}
