import React from "react";
import { FaCheck, FaRegTrashAlt } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import { addCommas, removeNonNumeric } from "../Js/functions";
import MultiRangeSlider from "multi-range-slider-react";

export default function MProductFilter({
  setIsMobileFilterProductOpen,
  category,
  selectedManufacturersInFilter,
  setSelectedManufacturersInFilter,
  selectedManufacturersInFilterCopy,
  setSelectedManufacturersInFilterCopy,
  isClearFilterShown,
  isOnFilter,
  applyFilter,
  clearFilters,
  filteredProducts,
  totalProducts,
  filterPriceMin,
  filterPriceMax,
  minPriceCopy,
  maxPriceCopy,
  isPhotoSelectInFilterCopy,
  setIsPhotoSelectInFilterCopy,
  isDataSheetSelectInFilterCopy,
  setIsDataSheetSelectInFilterCopy,
  set_maxPriceCopy,
  set_minPriceCopy,
}) {
  return (
    <div className="fixed inset-0 w-full min-[768px]:hidden  flex flex-col items-center justify-between bg-color-BG overflow-auto z-[100000] py-[16px]">
      <div className="w-full h-full flex flex-col items-center justify-start">
        <div className="w-full flex items-center justify-end  px-[16px]">
          <span
            className="text-color-TXT-dark cursor-pointer"
            onClick={() => {
              setIsMobileFilterProductOpen(false);
            }}
          >
            <IoCloseCircleOutline size={30} />
          </span>
        </div>
        <div className=" flex items-center justify-center gap-x-[4px]  text-[16px]">
          {isClearFilterShown && (
            <>
              <span className="font-bold">{filteredProducts}</span>
              <span className="">of</span>
            </>
          )}
          <span className="font-bold">
            {addCommas(removeNonNumeric(totalProducts))}
          </span>
          <span>Resulsts</span>
        </div>

        <div className="w-full flex-1 max-h-[300px] px-[16px] mt-[16px]">
          <div className="w-full h-full flex flex-col items-start justify-start bg-[#FFF] pb-[16px] pt-[8px] rounded-md border-[1px] border-[#CCCCCC] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]">
            <div className="w-full flex items-center justify-start font-bold px-[16px]">
              Manufacturer
            </div>
            <div className="w-full h-[2px] rounded-lg bg-[#CCCCCC] mt-[8px]"></div>
            <div className=" w-full h-full flex flex-col items-start justify-start  overflow-auto mt-[8px]">
              {category?.map((item) => (
                <div
                  className={`w-full text-start text-[12px] text-[#666666] cursor-pointer hover:border-b-[1px] border-[#CCCCCC] py-[4px] px-[16px] ${
                    selectedManufacturersInFilterCopy?.includes(item?.id)
                      ? "bg-color-B rounded-sm text-color-BG"
                      : ""
                  }`}
                  onClick={() => {
                    if (!selectedManufacturersInFilterCopy.includes(item?.id))
                      setSelectedManufacturersInFilterCopy([
                        item?.id,
                        ...selectedManufacturersInFilterCopy,
                      ]);
                    else {
                      setSelectedManufacturersInFilterCopy(
                        selectedManufacturersInFilterCopy.filter(
                          (value) => value != item.id
                        )
                      );
                    }
                  }}
                >
                  {item?.mfr}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full px-[16px] mt-[16px]">
          <div className="w-full h-[300px] flex flex-col items-center justify-between gap-y-[20px] ">
            <div className="w-full h-[200px] flex flex-col items-start justify-start bg-[#FFF] pb-[16px] pt-[8px] rounded-md border-[1px] border-[#CCCCCC] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]">
              <div className="w-full flex items-center justify-start font-bold px-[16px]">
                Price
              </div>
              <div className="w-full h-[2px] rounded-lg bg-[#CCCCCC] mx-auto mt-[8px]"></div>
              <div className=" w-full flex flex-col items-start justify-start gap-y-[8px] overflow-auto mt-[8px] px-[16px]">
                <div className="w-full relative">
                  <div className="absolute w-full flex items-center justify-between ">
                    <span className="text-color-TXT-dark font-bold text-[11px]">
                      {addCommas(removeNonNumeric(filterPriceMin))} $
                    </span>
                    <span className="text-color-TXT-dark font-bold text-[11px]">
                      {addCommas(removeNonNumeric(filterPriceMax))} $
                    </span>
                  </div>
                  <MultiRangeSlider
                    min={filterPriceMin}
                    max={filterPriceMax}
                    step={5}
                    minValue={minPriceCopy}
                    maxValue={maxPriceCopy}
                    style={{
                      border: "none",
                      boxShadow: "none",
                    }}
                    ruler="false"
                    label="false"
                    subSteps="true"
                    barInnerColor="#e43d4062"
                    thumbLeftColor="#E43D40"
                    thumbRightColor="#E43D40"
                    onInput={(e) => {
                      set_minPriceCopy(e.minValue);
                      set_maxPriceCopy(e.maxValue);
                    }}
                  />
                </div>
                <div className="w-full mt-3 flex items-center justify-center">
                  {addCommas(removeNonNumeric(minPriceCopy))} $&nbsp;-&nbsp;
                  {addCommas(removeNonNumeric(maxPriceCopy))} $
                </div>
              </div>
            </div>

            <div className="w-full h-full flex flex-col items-start justify-start bg-[#FFF] pb-[16px] pt-[8px] rounded-md border-[1px] border-[#CCCCCC] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]">
              <div className="w-full flex items-center justify-start font-bold px-[16px]">
                Media
              </div>
              <div className="w-full h-[2px] rounded-lg bg-[#CCCCCC]  mt-[8px]"></div>
              <div className=" w-full h-full flex flex-col items-start justify-start gap-y-[8px] overflow-auto mt-[8px] px-[16px]">
                <div
                  className="flex items-center justify-start gap-x-[4px] text-start text-[12px] text-[#666666] cursor-pointer"
                  onClick={() => {
                    setIsPhotoSelectInFilterCopy((prev) => !prev);
                  }}
                >
                  <div className="w-[15px] h-[15px] rounded-sm border-[1px] border-color-TXT-dark flex items-center justify-center">
                    {isPhotoSelectInFilterCopy && (
                      <FaCheck color="#E43D40" size={30} />
                    )}
                  </div>
                  <span className="">Photo</span>
                </div>

                <div
                  className="flex items-center justify-start gap-x-[4px] text-start text-[12px] text-[#666666] cursor-pointer"
                  onClick={() => {
                    setIsDataSheetSelectInFilterCopy((prev) => !prev);
                  }}
                >
                  <div className="w-[15px] h-[15px] rounded-sm border-[1px] border-color-TXT-dark flex items-center justify-center">
                    {isDataSheetSelectInFilterCopy && (
                      <FaCheck color="#E43D40" size={30} />
                    )}
                  </div>
                  <span className="">Datasheet</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[100px] flex flex-col  items-center justify-end gap-x-[16px] gap-y-[8px] px-[16px] mt-[32px]">
        <button
          disabled={!isOnFilter}
          className={`w-full m h-[33px] ${
            isOnFilter
              ? "bg-color-B text-color-BG "
              : "bg-[#d5d4d4] text-color-TXT-dark"
          } text-[14px]  rounded-md flex items-center justify-center`}
          onClick={applyFilter}
        >
          Apply All Filters
        </button>

        {isClearFilterShown && (
          <button
            onClick={clearFilters}
            className="w-full  h-[33px] bg-color-TXT-dark text-color-B text-[14px]  rounded-md flex items-center justify-center"
          >
            <FaRegTrashAlt />
          </button>
        )}
      </div>
    </div>
  );
}
