import "../assets/Css/Styles/general.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToFirst from "../components/ScrollToFirst";
import { BsShareFill } from "react-icons/bs";
import { GoChevronRight } from "react-icons/go";
import { GoChevronLeft } from "react-icons/go";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { FaCheck, FaFilePdf, FaRegTrashAlt, FaSearch } from "react-icons/fa";
import { TbEyeSearch } from "react-icons/tb";
import ProductModal from "../components/ProductModal";
import SharePage from "../components/SharePage";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import globalContext from "../Contexts/GlobalContext";
import axios from "axios";
import { SERVERMODE } from "../constants/serverMode";

import MultiRangeSlider from "multi-range-slider-react";
import {
  AlertAlarm,
  addCommas,
  capitalize,
  currencySymbol,
  getUrlSearchParam,
  handleServerMode,
  removeNonNumeric,
} from "../Js/functions";
import { debounce } from "@mui/material";
import MProductFilter from "../components/MProductFilter";
import PreLoading from "../components/PreLoading";

export default function Product() {
  const BASE_URL = process.env.REACT_APP_ROOT_API_ADDRESS;
  const Nav = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();
  const contextData = useContext(globalContext);
  const [isPreLoading, setIsPreLoading] = useState(false);
  const locationUse = useLocation();
  const [searchParamsWW, setSearchParamsRR] = useSearchParams();
  const [pageMode, setPageMode] = useState("");

  const [paramSearchSize, setParamSearchSize] = useState("Defualt");

  const [category, setCategory] = useState([]);

  const apiRef = useGridApiRef();

  const [filterPriceMin, setFilterPriceMin] = useState(0);
  const [filterPriceMax, setFilterPriceMax] = useState(0);

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isProductShareOpen, setIsProductShareOpen] = useState(false);
  const [productModal, setProductModal] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [productRows, setProductRows] = useState([
    // {
    //   id: 1,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "3500",
    //   status: "status",
    //   dataSheet: "dataSheet1",
    // },
    // {
    //   id: 2,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "kkoopp",
    //   manufacturer: "qqwwee",
    //   description: "fdfadaadadasd",
    //   price: "10500",
    //   status: "status",
    //   dataSheet: "dataSheet2",
    // },
    // {
    //   id: 3,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "jhfgfjhfj",
    //   manufacturer: "Worjjgfjghld",
    //   description: "mammad",
    //   price: "2250",
    //   status: "status",
    //   dataSheet: "dataSheet3",
    // },
    // {
    //   id: 4,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "ali",
    //   manufacturer: "alireza",
    //   description: "description",
    //   price: "95600",
    //   status: "status",
    //   dataSheet: "dataSheet4",
    // },
    // {
    //   id: 5,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "11600",
    //   status: "status",
    //   dataSheet: "dataSheet5",
    // },
    // {
    //   id: 6,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "10000",
    //   status: "status",
    //   dataSheet: "dataSheet6",
    // },
    // {
    //   id: 7,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "21250",
    //   status: "status",
    //   dataSheet: "dataSheet7",
    // },
    // {
    //   id: 8,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "36500",
    //   status: "status",
    //   dataSheet: "dataSheet8",
    // },
    // {
    //   id: 9,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "34500",
    //   status: "status",
    //   dataSheet: "dataSheet9",
    // },
    // {
    //   id: 10,
    //   image: "../assets/images/products/WT-1205_tmb.jpg",
    //   mfrPartNumber: "World",
    //   manufacturer: "World",
    //   description: "description",
    //   price: "700000",
    //   status: "status",
    //   dataSheet: "dataSheet10",
    // },
  ]);
  const productColumns = [
    {
      field: "image",
      headerName: "#",
      minWidth: 100,
      Width: 56,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
      renderCell: (params) => (
        <div className="w-full h-full flex items-center justify-center">
          <img
            style={{ width: "55px", height: "55px" }}
            className=""
            src={params.value === null ? "null" : params.value}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "../assets/images/products/Not_available.svg";
            }}
          />
        </div>
      ),
    },
    {
      field: "mfrPartNumber",
      headerName: "Mfr Part Number",
      flex: 1,
      minWidth: 200,
      editable: false,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span
            className="text-color-A font-bold cursor-pointer hover:border-b-[1px] border-color-A"
            onClick={() => {}}
          >
            {params.value.split("-")[0]}
          </span>

          <span className="text-[10px] text-[#9d9b9b] mt-[8px]">
            ICN: {params.value.split("-")[1]}
          </span>
        </div>
      ),
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      editable: false,
      flex: 1,
      minWidth: 200,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 300,
      editable: false,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
    },
    {
      field: "price",
      headerName: "Price",
      editable: false,
      minWidth: 100,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
      renderCell: (params) => (
        <div className="flex items-center justify-center">
          <span className="" onClick={() => {}}>
            {params.value.split("-")[0]}
          </span>

          <span className="text-[10px] ">
            {currencySymbol(params.value.split("-")[1])}
          </span>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      minWidth: 100,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
    },
    {
      field: "dataSheet",
      headerName: "Datasheet",
      minWidth: 120,
      headerClassName: "MuiDataGrid-header-cellWithBorder",
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="w-full flex items-center justify-center gap-x-[12px]">
            <button
              className=" flex justify-center items-center gap-x-2  text-color-B"
              onClick={(e) => {}}
            >
              <FaFilePdf size={20} />
            </button>

            <button
              className=" flex justify-center items-center gap-x-2  text-color-A-1"
              onClick={(e) => {
                setProductModal(productRows[params.id]);
                setIsProductModalOpen(true);
              }}
            >
              <TbEyeSearch size={20} />
            </button>
          </div>
        );
      },
    },
  ];

  const [minPrice, set_minPrice] = useState(0);
  const [maxPrice, set_maxPrice] = useState(0);
  const [minPriceCopy, set_minPriceCopy] = useState(0);
  const [maxPriceCopy, set_maxPriceCopy] = useState(0);

  const [isPhotoSelectInFilter, setIsPhotoSelectInFilter] = useState(
    window.location.search.includes("&image=1") ? true : false
  );
  const [isDataSheetSelectInFilter, setIsDataSheetSelectInFilter] = useState(
    window.location.search.includes("&datasheet=1") ? true : false
  );
  const [isPhotoSelectInFilterCopy, setIsPhotoSelectInFilterCopy] = useState(
    window.location.search.includes("&image=1") ? true : false
  );
  const [isDataSheetSelectInFilterCopy, setIsDataSheetSelectInFilterCopy] =
    useState(window.location.search.includes("&datasheet=1") ? true : false);

  const [selectedManufacturersInFilter, setSelectedManufacturersInFilter] =
    useState([]);
  const [
    selectedManufacturersInFilterCopy,
    setSelectedManufacturersInFilterCopy,
  ] = useState([]);

  const [isMobileFilterProductOpen, setIsMobileFilterProductOpen] =
    useState(false);

  //.......................*....................//
  const [categorySub, setCategorySub] = useState("");
  const [categoryBranch, setCategoryBranch] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [totalProducts, setTotalProducts] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState(0);
  const [isOnFilter, setIsOnFilter] = useState(false);
  const [isClearFilterShown, setIsClearFilterShown] = useState(false);

  useEffect(() => {
    setTotalPages(Math.ceil(totalProducts / pageSize));
  }, [pageSize, productRows]);

  useEffect(() => {
    if (isProductModalOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    if (isProductShareOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [isProductModalOpen, isProductShareOpen]);

  const fillProductRows = (rowParam) => {
    let tmpArr = [];
    for (let i = 0; i < rowParam?.length; i++) {
      var tmpObj = {
        id: i,
        image: rowParam[i]?.image,
        mfrPartNumber:
          rowParam[i]?.mfr_part_number + "-" + rowParam[i]?.ice_part_number,
        manufacturer: rowParam[i]?.mfr,
        description: rowParam[i]?.description,
        price: rowParam[i]?.price + "-" + rowParam[i]?.currency,
        status: rowParam[i]?.status,
        dataSheet: rowParam[i]?.dataSheet,
      };

      tmpArr.push(tmpObj);
    }

    setProductRows(tmpArr);
  };

  const fetchProductInPtnSearchMode = () => {
    var urlPtn = getUrlSearchParam(window.location.search, "ptn=");
    var urlIc = getUrlSearchParam(window.location.search, "ic=");
    setIsPreLoading(true);
    var reqBody = {
      ic: urlIc,
      searchKey: urlPtn,
    };
    axios
      .post(`${BASE_URL}/product/ptn`, reqBody)
      .then((res) => {
        var responseMessage = res?.data?.message;
        var responseMode = res?.data?.mode;
        if (responseMode === SERVERMODE.REQUEST_OK) {
          fillProductRows(res?.data?.result);
          setIsPreLoading(false);
        } else {
          handleServerMode(
            responseMode,
            responseMessage,
            contextData,
            cookies,
            removeCookies,
            Nav
          );
          setIsPreLoading(false);
        }
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message;
        if (errMessage?.length > 5) {
          AlertAlarm(contextData, errMessage, "ERROR", false, "");
        } else {
          AlertAlarm(contextData, "Something Went Wrong", "ERROR", false, "");
        }

        setIsPreLoading(false);
      });
  };

  const fetchProduct = () => {
    setIsPreLoading(true);
    axios
      .get(`${BASE_URL}${window.location.pathname}${window.location.search}`)
      .then((res) => {
        var responseMessage = res?.data?.message;
        var responseMode = res?.data?.mode;
        if (responseMode === SERVERMODE.REQUEST_OK) {
          setCategorySub(res?.data?.result?.cat?.sub);
          setCategoryBranch(res?.data?.result?.cat?.branch);
          setCategoryTitle(res?.data?.result?.cat?.title);

          setTotalProducts(res?.data?.result?.data?.total);
          setFilteredProducts(res?.data?.result?.data?.filter);

          if (window.location.search.includes("price=")) {
            var locationSearchPrice = window.location.search;
            var restOfLocationSearchPrice = locationSearchPrice.substring(
              locationSearchPrice.indexOf("price=") + 6,
              locationSearchPrice.length
            );
            var resRestLocationSearchPrice = restOfLocationSearchPrice;
            if (restOfLocationSearchPrice.includes("&")) {
              resRestLocationSearchPrice = restOfLocationSearchPrice.substring(
                0,
                restOfLocationSearchPrice.indexOf("&")
              );
            }

            var splitedtResRestLocationSearchPrice =
              resRestLocationSearchPrice.split(",");
            set_minPrice(splitedtResRestLocationSearchPrice[0]);
            set_maxPrice(splitedtResRestLocationSearchPrice[1]);
            set_minPriceCopy(splitedtResRestLocationSearchPrice[0]);
            set_maxPriceCopy(splitedtResRestLocationSearchPrice[1]);
          } else {
            set_minPrice(res?.data?.result?.data?.price[0]);
            set_maxPrice(res?.data?.result?.data?.price[1]);
            set_minPriceCopy(res?.data?.result?.data?.price[0]);
            set_maxPriceCopy(res?.data?.result?.data?.price[1]);
          }

          setFilterPriceMin(res?.data?.result?.data?.price[0]);
          setFilterPriceMax(res?.data?.result?.data?.price[1]);
          setCategory(res?.data?.result?.mfr);

          fillProductRows(res?.data?.result?.products);

          setIsPreLoading(false);
        } else if (responseMode === "emptyProducts") {
          if (window.location.search.includes("price=")) {
            var locationSearchPrice = window.location.search;
            var restOfLocationSearchPrice = locationSearchPrice.substring(
              locationSearchPrice.indexOf("price=") + 6,
              locationSearchPrice.length
            );
            var resRestLocationSearchPrice = restOfLocationSearchPrice;
            if (restOfLocationSearchPrice.includes("&")) {
              resRestLocationSearchPrice = restOfLocationSearchPrice.substring(
                0,
                restOfLocationSearchPrice.indexOf("&")
              );
            }

            var splitedtResRestLocationSearchPrice =
              resRestLocationSearchPrice.split(",");
            set_minPrice(splitedtResRestLocationSearchPrice[0]);
            set_maxPrice(splitedtResRestLocationSearchPrice[1]);
            set_minPriceCopy(splitedtResRestLocationSearchPrice[0]);
            set_maxPriceCopy(splitedtResRestLocationSearchPrice[1]);
          }
          setCategory([]);
          fillProductRows([]);
          setFilteredProducts(0);
          setIsPreLoading(false);
        } else {
          handleServerMode(
            responseMode,
            responseMessage,
            contextData,
            cookies,
            removeCookies,
            Nav
          );
          setIsPreLoading(false);
        }
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message;
        if (errMessage?.length > 5) {
          AlertAlarm(contextData, errMessage, "ERROR", false, "");
        } else {
          AlertAlarm(contextData, "Something Went Wrong", "ERROR", false, "");
        }
        setIsPreLoading(false);
      });
  };

  useEffect(() => {
    if (window.location.search.includes("ptn=")) {
      setPageMode("ptnSearch");
      fetchProductInPtnSearchMode();
    } else {
      setPageMode("");
      fetchProduct();
    }
  }, []);

  function arraysEqualIgnoreIndex(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort the arrays
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    // Compare the sorted arrays
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    if (window.location.pathname.includes("filter")) {
      setIsOnFilter(true);
    }

    if (
      !arraysEqualIgnoreIndex(
        selectedManufacturersInFilter,
        selectedManufacturersInFilterCopy
      )
    ) {
      setIsOnFilter(true);
    } else if (isPhotoSelectInFilter != isPhotoSelectInFilterCopy) {
      setIsOnFilter(true);
    } else if (isDataSheetSelectInFilter != isDataSheetSelectInFilterCopy) {
      setIsOnFilter(true);
    } else if (minPrice != minPriceCopy) {
      setIsOnFilter(true);
    } else if (maxPrice != maxPriceCopy) {
      setIsOnFilter(true);
    } else {
      setIsOnFilter(false);
    }
  }, [
    selectedManufacturersInFilter,
    selectedManufacturersInFilterCopy,
    isPhotoSelectInFilterCopy,
    isDataSheetSelectInFilterCopy,
    minPriceCopy,
    maxPriceCopy,
  ]);

  const applyFilter = () => {
    if (isMobileFilterProductOpen) setIsMobileFilterProductOpen(false); /// close mobile filter modal if open ///
    var endPoint = "";
    var dd = "";

    var locationSearch = window.location.search;
    if (minPrice != minPriceCopy || maxPrice != maxPriceCopy)
      if (locationSearch.includes("&price=")) {
        dd = window.location.search.substring(
          window.location.search.indexOf("&price="),
          window.location.search.length
        );

        var ddIgnoreIndexZero = dd.substring(1, dd.length);
        if (ddIgnoreIndexZero.includes("&")) {
          dd =
            "&" +
            ddIgnoreIndexZero.substring(0, ddIgnoreIndexZero.indexOf("&"));
        }
        locationSearch = locationSearch.replace(dd, "");
        endPoint += `&price=${minPriceCopy},${maxPriceCopy}`;
      } else {
        endPoint += `&price=${minPriceCopy},${maxPriceCopy}`;
      }

    if (isPhotoSelectInFilterCopy != isPhotoSelectInFilter) {
      if (locationSearch.includes("&image=1")) {
        locationSearch = locationSearch.replace("&image=1", "");
      } else {
        endPoint += "&image=1";
      }
    }

    if (isDataSheetSelectInFilterCopy != isDataSheetSelectInFilter) {
      if (locationSearch.includes("&datasheet=1")) {
        locationSearch = locationSearch.replace("&datasheet=1", "");
      } else {
        endPoint += "&datasheet=1";
      }
    }

    if (
      !arraysEqualIgnoreIndex(
        selectedManufacturersInFilter,
        selectedManufacturersInFilterCopy
      )
    ) {
      if (locationSearch.includes("&mfr=")) {
        var urlMfr = window.location.search.substring(
          window.location.search.indexOf("&mfr="),
          window.location.search.length
        );

        locationSearch = locationSearch.replace(urlMfr, "");
        if (selectedManufacturersInFilterCopy.length > 0) {
          var mfr = "&mfr=";

          for (let val of selectedManufacturersInFilterCopy) {
            mfr += `${val},`;
          }
          mfr = mfr.slice(0, -1);
          endPoint += mfr;
        }
      } else {
        var mfr = "&mfr=";
        for (let val of selectedManufacturersInFilterCopy) {
          mfr += `${val},`;
        }
        mfr = mfr.slice(0, -1);
        endPoint += mfr;
      }
    }

    Nav(`${window.location.pathname}${locationSearch}${endPoint}`);
  };

  useEffect(() => {
    console.log("searchParamsWW", searchParamsWW.size);
    const params = new URLSearchParams(window.location.search);
    setParamSearchSize(params.size);
    if (params.size > 1) {
      setIsClearFilterShown(true);
    } else {
      setIsClearFilterShown(false);
    }
    setIsPhotoSelectInFilter(
      window.location.search.includes("image=1") ? true : false
    );

    setIsDataSheetSelectInFilter(
      window.location.search.includes("datasheet=1") ? true : false
    );
    setIsPhotoSelectInFilterCopy(
      window.location.search.includes("image=1") ? true : false
    );
    setIsDataSheetSelectInFilterCopy(
      window.location.search.includes("datasheet=1") ? true : false
    );

    if (window.location.search.includes("&mfr=")) {
      var urlMfr = window.location.search.substring(
        window.location.search.indexOf("&mfr=") + 5,
        window.location.search.length
      );
      if (urlMfr.includes("&")) {
        urlMfr = urlMfr.substring(0, urlMfr.indexOf("&"));
      }
      var urlMfrArr = urlMfr.split(",").map((item) => +item);
      setSelectedManufacturersInFilter(urlMfrArr);
      setSelectedManufacturersInFilterCopy(urlMfrArr);
    } else {
      setSelectedManufacturersInFilter([]);
      setSelectedManufacturersInFilterCopy([]);
    }

    if (window.location.search.includes("ptn=")) {
      setPageMode("ptnSearch");
      fetchProductInPtnSearchMode();
    } else {
      setPageMode("");
      fetchProduct();
    }
    setIsOnFilter(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [window.location.search]);

  const clearFilters = () => {
    if (isMobileFilterProductOpen) setIsMobileFilterProductOpen(false); /// close mobile filter modal if open ///
    const params = new URLSearchParams(locationUse.search);
    const pp = params.get("ic");

    Nav(`${window.location.pathname}?ic=${pp}`);
  };

  const handlePaginition = (size, currecnt) => {
    const params = new URLSearchParams(locationUse.search);
    var icString = `${currecnt}:${size}`;
    var btoaIcString = btoa(icString).replaceAll("=", "");
    params.set("ic", btoaIcString);
    window.history.replaceState(null, null, "?" + params.toString());
  };

  return (
    <>
      <Header />

      {!isMobileFilterProductOpen && (
        <div className="relative w-full h-full min-h-screen px-[20px] min-[1240px]:px-0  flex flex-col justify-start items-center bg-color-BG">
          <div className="w-full max-w-[1200px] flex  flex-col-reverse min-[768px]:flex-row gap-y-[8px] items-center justify-between mt-[16px]">
            <div className="w-full flex-wrap min-[768px]:w-auto flex items-center justify-start  gap-x-[4px]">
              <Link to="/products">
                <span className="cursor-pointer text-[10px] min-[768px]:text-[12px] text-color-TXT-dark font-bold hover:border-b-[1px] border-color-A hover:text-color-A">
                  Product Index
                </span>
              </Link>

              <span>
                <GoChevronRight />
              </span>
              {categorySub && (
                <>
                  <Link to={`/products?productSearch=${categorySub}`}>
                    <span className="cursor-pointer text-[10px] min-[768px]:text-[12px] text-color-TXT-dark font-bold hover:border-b-[1px] border-color-A hover:text-color-A">
                      {categorySub}
                    </span>
                  </Link>
                  <span>
                    <GoChevronRight />
                  </span>
                </>
              )}
              {categoryBranch && (
                <>
                  <Link to={`/products?productSearch=${categoryBranch}`}>
                    <span className="cursor-pointer text-[10px] min-[768px]:text-[12px] text-color-TXT-dark font-bold hover:border-b-[1px] border-color-A hover:text-color-A">
                      {categoryBranch}
                    </span>
                  </Link>
                  <span>
                    <GoChevronRight />
                  </span>
                </>
              )}
              <span className="text-[10px] min-[768px]:text-[12px] text-color-TXT-dark">
                {window.location.search.includes("ptn=") ? (
                  <>{getUrlSearchParam(window.location.search, "ptn=")}</>
                ) : (
                  <>
                    {window.location.pathname
                      .replaceAll("/product/", "")
                      .replaceAll("-", " ")}
                  </>
                )}
              </span>
            </div>

            <div
              className="w-full min-[768px]:w-auto flex items-center justify-end gap-x-[4px] cursor-pointer text-[14px] text-color-A-1"
              onClick={() => {
                setIsProductShareOpen(true);
              }}
            >
              <span>share</span>
              <span className="text-[12px]">
                <BsShareFill />
              </span>
            </div>
          </div>

          <div className="w-full max-w-[1200px] flex items-center justify-start mt-[16px]">
            <span className="text-color-TXT-dark text-[20px] font-bold ">
              {window.location.search.includes("ptn=") ? (
                <>
                  {capitalize(
                    getUrlSearchParam(window.location.search, "ptn=")
                  )}
                </>
              ) : (
                <>
                  {capitalize(
                    window.location.pathname
                      .replaceAll("/product/", "")
                      .replaceAll("-", " ")
                  )}
                </>
              )}
            </span>
          </div>

          <div className="w-full max-w-[1200px] flex items-center justify-start mt-[8px]">
            <span className="text-color-TXT-dark text-[16px] font-light">
              {categoryTitle}
            </span>
          </div>
          {pageMode !== "ptnSearch" && (
            <>
              <div className="hidden relative w-full max-w-[1200px] min-[768px]:flex  items-start justify-start gap-x-[32px] mt-[32px]">
                <div className="w-[240px] min-[1024px]:w-[300px] h-[380px] flex flex-col items-start justify-start bg-[#FFF] pb-[16px] pt-[8px] rounded-md border-[1px] border-[#CCCCCC] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]">
                  <div className="w-full flex items-center justify-start font-bold px-[16px]">
                    Manufacturer
                  </div>
                  <div className="w-full h-[2px] rounded-lg bg-[#CCCCCC] mt-[8px]"></div>
                  <div className=" w-full h-[300px] flex flex-col items-start justify-start  overflow-auto mt-[8px]">
                    {category?.map((item) => (
                      <div
                        className={`w-full text-start text-[12px] text-[#666666] cursor-pointer hover:border-b-[1px] border-[#CCCCCC] py-[4px] px-[16px] ${
                          selectedManufacturersInFilterCopy?.includes(item?.id)
                            ? "bg-color-B rounded-sm text-color-BG"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            !selectedManufacturersInFilterCopy.includes(
                              item?.id
                            )
                          )
                            setSelectedManufacturersInFilterCopy([
                              item?.id,
                              ...selectedManufacturersInFilterCopy,
                            ]);
                          else {
                            setSelectedManufacturersInFilterCopy(
                              selectedManufacturersInFilterCopy.filter(
                                (value) => value != item.id
                              )
                            );
                          }
                        }}
                      >
                        {item?.mfr}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="w-[240px] min-[1024px]:w-[300px] h-[380px] flex flex-col items-center justify-between gap-y-[20px]">
                  <div className="w-full h-[200px] flex flex-col items-start justify-start bg-[#FFF] pb-[16px] pt-[8px] rounded-md border-[1px] border-[#CCCCCC] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]">
                    <div className="w-full flex items-center justify-start font-bold px-[16px]">
                      Price
                    </div>
                    <div className="w-full h-[2px] rounded-lg bg-[#CCCCCC] mx-auto mt-[8px]"></div>
                    <div className=" w-full flex flex-col items-start justify-start gap-y-[8px] overflow-auto mt-[8px] px-[16px]">
                      <div className="w-full relative">
                        <div className="absolute w-full flex items-center justify-between ">
                          <span className="text-color-TXT-dark font-bold text-[11px]">
                            {addCommas(removeNonNumeric(filterPriceMin))} $
                          </span>
                          <span className="text-color-TXT-dark font-bold text-[11px]">
                            {addCommas(removeNonNumeric(filterPriceMax))} $
                          </span>
                        </div>
                        <MultiRangeSlider
                          min={filterPriceMin}
                          max={filterPriceMax}
                          step={5}
                          minValue={minPriceCopy}
                          maxValue={maxPriceCopy}
                          style={{
                            border: "none",
                            boxShadow: "none",
                          }}
                          ruler="false"
                          label="false"
                          subSteps="true"
                          barInnerColor="#e43d4062"
                          thumbLeftColor="#E43D40"
                          thumbRightColor="#E43D40"
                          onInput={(e) => {
                            set_minPriceCopy(e.minValue);
                            set_maxPriceCopy(e.maxValue);
                          }}
                        />
                      </div>
                      <div className="w-full mt-3 flex items-center justify-center">
                        {addCommas(removeNonNumeric(minPriceCopy))}{" "}
                        $&nbsp;-&nbsp;
                        {addCommas(removeNonNumeric(maxPriceCopy))} $
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-full flex flex-col items-start justify-start bg-[#FFF] pb-[16px] pt-[8px] rounded-md border-[1px] border-[#CCCCCC] shadow-[0px_0px_2px_rgba(0,0,0,0.1)]">
                    <div className="w-full flex items-center justify-start font-bold px-[16px]">
                      Media
                    </div>
                    <div className="w-full h-[2px] rounded-lg bg-[#CCCCCC]  mt-[8px]"></div>
                    <div className=" w-full h-full flex flex-col items-start justify-start gap-y-[8px] overflow-auto mt-[8px] px-[16px]">
                      <div
                        className="flex items-center justify-start gap-x-[4px] text-start text-[12px] text-[#666666] cursor-pointer"
                        onClick={() => {
                          setIsPhotoSelectInFilterCopy((prev) => !prev);
                        }}
                      >
                        <div className="w-[15px] h-[15px] rounded-sm border-[1px] border-color-TXT-dark flex items-center justify-center">
                          {isPhotoSelectInFilterCopy && (
                            <FaCheck color="#E43D40" size={30} />
                          )}
                        </div>
                        <span className="">Photo</span>
                      </div>

                      <div
                        className="flex items-center justify-start gap-x-[4px] text-start text-[12px] text-[#666666] cursor-pointer"
                        onClick={() => {
                          setIsDataSheetSelectInFilterCopy((prev) => !prev);
                        }}
                      >
                        <div className="w-[15px] h-[15px] rounded-sm border-[1px] border-color-TXT-dark flex items-center justify-center">
                          {isDataSheetSelectInFilterCopy && (
                            <FaCheck color="#E43D40" size={30} />
                          )}
                        </div>
                        <span className="">Datasheet</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden min-[768px]:flex flex-1 h-[380px]  flex-col  items-end justify-end  px-[16px] min-[1024px]:px-0">
                  <div className="w-full flex flex-col min-[1024px]:flex-row items-center justify-end gap-x-[0px] gap-y-[8px]">
                    <div className="flex items-center justify-center gap-x-[4px]  text-[12px] mr-[48px]">
                      {isClearFilterShown && (
                        <>
                          <span className="font-bold">{filteredProducts}</span>
                          <span className="">of</span>
                        </>
                      )}

                      <span className="font-bold">
                        {addCommas(removeNonNumeric(totalProducts))}
                      </span>
                      <span>Resulsts</span>
                    </div>

                    <button
                      disabled={!isOnFilter}
                      className={`w-full min-[1024px]:w-[175px] h-[33px] ${
                        isOnFilter
                          ? "bg-color-B text-color-BG "
                          : "bg-[#d5d4d4] text-color-TXT-dark"
                      } text-[14px]  rounded-md flex items-center justify-center`}
                      onClick={applyFilter}
                    >
                      Apply All Filters
                    </button>

                    {isClearFilterShown && (
                      <button
                        className="w-full min-[1024px]:w-[33px] h-[33px]  text-color-B text-[14px]  rounded-md flex items-center justify-center"
                        onClick={clearFilters}
                      >
                        <FaRegTrashAlt />
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="min-[768px]:hidden w-full flex items-center justify-end mt-[16px]">
                <button
                  className="py-[3px] px-[4px] rounded-md text-color-A font-bold text-[12px] border-[2px] border-[#CCCCCC] flex items-center justify-center"
                  onClick={() => {
                    setIsMobileFilterProductOpen(true);
                  }}
                >
                  Filters
                </button>

                {/* <span className="font-bold text-[20px] text-[#04c90477] ml-[20px]">
              paramSearchSize: {paramSearchSize}
            </span> */}
              </div>
            </>
          )}

          <div className="relative w-full max-w-[1200px] h-auto my-[16px] min-[768px]:mt-[32px]">
            <DataGrid
              apiRef={apiRef}
              rows={productRows}
              columns={productColumns}
              onPageSizeChange={(e) => {
                setCurrentPage(e.page);
              }}
              onPaginationModelChange={(e) => {
                setPageSize(e.pageSize);
                setCurrentPage(e.page + 1);
                handlePaginition(e.pageSize, e.page + 1);
              }}
              disableColumnMenu
              // loading={true}
              autoHeight
              rowHeight={80}
              disableRowSelectionOnClick
              disableColumnFilter
              // slots={{ toolbar: GridToolbar }}
              sx={{
                border: "none",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "&.MuiDataGrid-root .MuiDataGrid-row:hover": {
                  backgroundColor: "inherit",
                },
                "&.MuiDataGrid-root .Mui-hovered": {
                  backgroundColor: "inherit",
                },
              }}
              componentsProps={{
                cell: {
                  className: "MuiDataGrid-cellWithBorder",
                },
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              pageSizeOptions={[25, 50, 100]}
            />

            <div className="absolute right-0 bottom-[16px] flex items-center justify-center gap-x-[16px]">
              <button
                className="flex items-center justify-center 
              "
              >
                <GoChevronLeft size={20} />
              </button>
              <div className=" right-[30px] bottom-[16px]">
                {currentPage} of {totalPages}
              </div>
              <button
                className="flex items-center justify-center 
              "
              >
                <GoChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      )}

      {isProductModalOpen && (
        <ProductModal
          setIsProductModalOpen={setIsProductModalOpen}
          productModal={productModal}
        />
      )}
      {isProductShareOpen && (
        <SharePage setIsProductShareOpen={setIsProductShareOpen} />
      )}

      {isMobileFilterProductOpen && (
        <MProductFilter
          setIsMobileFilterProductOpen={setIsMobileFilterProductOpen}
          category={category}
          selectedManufacturersInFilter={selectedManufacturersInFilter}
          setSelectedManufacturersInFilter={setSelectedManufacturersInFilter}
          selectedManufacturersInFilterCopy={selectedManufacturersInFilterCopy}
          setSelectedManufacturersInFilterCopy={
            setSelectedManufacturersInFilterCopy
          }
          isClearFilterShown={isClearFilterShown}
          isOnFilter={isOnFilter}
          applyFilter={applyFilter}
          clearFilters={clearFilters}
          filteredProducts={filteredProducts}
          totalProducts={totalProducts}
          filterPriceMin={filterPriceMin}
          filterPriceMax={filterPriceMax}
          isPhotoSelectInFilterCopy={isPhotoSelectInFilterCopy}
          setIsPhotoSelectInFilterCopy={setIsPhotoSelectInFilterCopy}
          isDataSheetSelectInFilterCopy={isDataSheetSelectInFilterCopy}
          setIsDataSheetSelectInFilterCopy={setIsDataSheetSelectInFilterCopy}
          minPriceCopy={minPriceCopy}
          maxPriceCopy={maxPriceCopy}
          set_maxPriceCopy={set_maxPriceCopy}
          set_minPriceCopy={set_minPriceCopy}
        />
      )}

      {isPreLoading && <PreLoading />}
      <Footer />
      <ScrollToFirst />
    </>
  );
}
