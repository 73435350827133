import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToFirst from "../components/ScrollToFirst";
import { GoChevronRight } from "react-icons/go";
import { Link, useParams } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import "../assets/Css/Styles/general.css";
import SharePage from "../components/SharePage";

export default function ProductSingle() {
  const params = useParams();
  const [isProductShareOpen, setIsProductShareOpen] = useState(false);

  useEffect(() => {
    console.log(params);
  }, []);
  return (
    <>
      <Header />
      <div className="relative w-full h-full min-h-screen  flex flex-col justify-start items-center px-[20px] min-[1240px]:px-0 bg-color-BG">
        <div className="w-full max-w-[1200px] flex  flex-col-reverse min-[768px]:flex-row gap-y-[8px] items-center justify-between mt-[16px] ">
          <div className="w-full flex-wrap min-[768px]:w-auto flex items-center justify-start  gap-x-[4px]">
            <Link to="/products">
              <span className="cursor-pointer text-[10px] min-[768px]:text-[12px] text-color-TXT-dark font-bold hover:border-b-[1px] border-color-A hover:text-color-A">
                Product Index
              </span>
            </Link>

            <span>
              <GoChevronRight />
            </span>
            {true && (
              <>
                <Link to={`/products?productSearch=${""}`}>
                  <span className="cursor-pointer text-[10px] min-[768px]:text-[12px] text-color-TXT-dark font-bold hover:border-b-[1px] border-color-A hover:text-color-A">
                    sssssdsd
                  </span>
                </Link>
                <span>
                  <GoChevronRight />
                </span>
              </>
            )}
            {true && (
              <>
                <Link to={`/products?productSearch=${""}`}>
                  <span className="cursor-pointer text-[10px] min-[768px]:text-[12px] text-color-TXT-dark font-bold hover:border-b-[1px] border-color-A hover:text-color-A">
                    aadaadaad
                  </span>
                </Link>
                <span>
                  <GoChevronRight />
                </span>
              </>
            )}
            <span className="text-[10px] min-[768px]:text-[12px] text-color-TXT-dark">
              {window.location.pathname
                .replaceAll("/product/", "")
                .replaceAll("-", " ")}
            </span>
          </div>
          <div
            className="w-full min-[768px]:w-auto flex items-center justify-end gap-x-[4px] cursor-pointer text-[14px] text-color-A-1"
            onClick={() => {
              setIsProductShareOpen(true);
            }}
          >
            <span>share</span>
            <span className="text-[12px]">
              <BsShareFill />
            </span>
          </div>
        </div>

        <div className="w-full flex-1 flex items-start justify-center max-w-[1200px] my-[30px]">
          <div
            className="flex-col min-[768px]:flex-row  flex items-center justify-center w-full h-[600px] min-[768px]:h-[550px]  rounded-xl shadow-[0px_0px_5px_rgba(0,0,0,0.3)] bg-[#FFF] py-[16px]"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="relative w-full min-[768px]:w-[70%]  h-full flex items-center justify-center bg-[#FFF] rounded-md min-[480px]:rounded-tl-xl min-[480px]:rounded-bl-xl">
              <img
                src="../../assets/images/products/tdk_film-caps-pc-terminals_image.jpg"
                className="object-contain w-[200px] h-[200px] min-[480px]:w-[300px] min-[480px]:h-[300px] mt-[32px] min-[768px]:mt-0"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    "../../assets/images/products/Not_available.svg";
                }}
              />

              <img
                style={{ width: "160px", height: "60px" }}
                src="../../assets/images/manufacturers/1global.png"
                className="absolute top-0 left-0 object-contain  "
              />

              <button
                className={`hidden absolute bottom-[16px] left-[16px]   min-[768px]:flex items-center justify-center gap-x-[8px] rounded-md border-[1px] mt-[16px] cursor-pointer bg-color-B text-color-BG Type_4 p-[8px]`}
              >
                <span className="  h-full flex items-center justify-center ">
                  <FaFilePdf size={16} />
                </span>
                <span className="flex-1 flex items-center justify-center text-[12px] ">
                  Download Datasheet
                </span>
              </button>
            </div>
            <div className=" w-full flex flex-col items-center  h-full justify-between px-[16px]  pb-0 min-[768px]:pb-[16px]">
              <div className="w-full">
                <div className="w-full flex items-center justify-start text-color-TXT-dark font-bold text-[18px] ">
                  productModal?.mfrPartNumber
                </div>
                <div className="w-full text-justify whitespace-normal break-words font-light text-color-TXT-dark text-[14px] mt-[16px]">
                  productModal?.description
                </div>
              </div>

              <div className="w-full ">
                <div className="w-full flex items-center justify-between mt-[24px]">
                  <div className="text-[12px] font-bold">Manufacturer</div>
                  <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
                  <div className="text-[12px]">productModal?.manufacturer</div>
                </div>

                <div className="w-full flex items-center justify-between mt-[16px]">
                  <div className="text-[12px] font-bold">Price</div>
                  <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
                  <div className="text-[12px] flex items-center justify-center gap-x-[4px]">
                    <span>productModal?.pric</span>
                    <span>$</span>
                  </div>
                </div>

                <div className="w-full flex items-center justify-between mt-[16px]">
                  <div className="text-[12px] font-bold">Ic Manifacture </div>
                  <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
                  <div className="text-[12px] flex items-center justify-center gap-x-[4px]">
                    <span>adaada</span>
                    <span>$</span>
                  </div>
                </div>

                <div className="w-full flex items-center justify-between mt-[16px]">
                  <div className="text-[12px] font-bold">Currency</div>
                  <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
                  <div className="text-[12px] flex items-center justify-center gap-x-[4px]">
                    <span>Dollar</span>
                  </div>
                </div>

                <div className="w-full flex items-center justify-between mt-[16px]">
                  <div className="text-[12px] font-bold">Status</div>
                  <div className="w-full  flex-1 border-b-[1.5px] border-dashed border-color-TXT-dark mx-[6px] pt-[4px]"></div>
                  <div className="text-[12px] flex items-center justify-center gap-x-[4px]">
                    <span>Active</span>
                  </div>
                </div>

                <button
                  className={`min-[768px]:hidden w-full flex items-center justify-center gap-x-[8px] rounded-md border-[1px] mt-[16px] cursor-pointer bg-color-B text-color-BG Type_4 p-[8px]`}
                >
                  <span className="  h-full flex items-center justify-center ">
                    <FaFilePdf size={16} />
                  </span>
                  <span className="flex-1 flex items-center justify-center text-[12px] ">
                    Download Datasheet
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full hidden min-[768px]:block h-[800px] max-w-[1200px] mb-[16px]">
          <iframe src="../../assets/pdf/2.pdf" className=" w-full h-full" />
        </div>
      </div>
      {isProductShareOpen && (
        <SharePage setIsProductShareOpen={setIsProductShareOpen} />
      )}
      <Footer />
      <ScrollToFirst />
    </>
  );
}
