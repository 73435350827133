import React, { useEffect, useState } from "react";
import { FaTelegramPlane, FaInstagram, FaRegCopyright } from "react-icons/fa";
import { BsChatDotsFill, BsTwitterX } from "react-icons/bs";
import { AiFillWechat, AiFillLinkedin } from "react-icons/ai";
import { MdEmail, MdPhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MFooter from "./MFooter";

export default function Footer() {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("");
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 740) setViewMode("M");
      else setViewMode("D");
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.screen.width]);
  return (
    <>
      {viewMode === "M" ? (
        <MFooter />
      ) : (
        <div className="w-full flex flex-col items-center justify-between bg-color-A">
          <div className="relative flex items-center justify-between w-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 min-h-[250px]">
            <div className="h-full flex flex-col justify-between items-center py-[12px]">
              <div className="h-full flex justify-center items-center">
                <div className="w-[150px] flex items-center justify-cente">
                  <img
                    src="../assets/images/IC.png"
                    className="w-[150px] object-contain h-[150px]"
                  />
                </div>
                <div className="flex-1 items-center justify-center text-justify text-[14px] pl-[32px] text-color-TXT">
                  <span className="font-bold text-[16px] text-color-A-2">
                    IC World Electronics
                  </span>
                  &nbsp; is recognized as a reliable distributor of electronic
                  components and automation products worldwide. Founded in 2014,
                  IC World Electronics has always been a pioneer in the
                  mail-order catalog business and a key resource for design
                  engineers.
                </div>
              </div>
              <div className="h-[40px] w-full flex justify-start items-center mt-[24px] gap-x-[12px]">
                <span className="cursor-pointer font-normal text-[12px] text-color-TXT-1 hover:text-white">
                  Terms & Conditions
                </span>

                <span className="font-semibold text-[12px] text-color-TXT-1">
                  |
                </span>

                <span className="cursor-pointer font-normal text-[12px] text-color-TXT-1 hover:text-white">
                  Privacy Notice
                </span>

                <span className="font-semibold text-[12px] text-color-TXT-1">
                  |
                </span>

                <span className="cursor-pointer font-normal text-[12px] text-color-TXT-1 hover:text-white">
                  Blog
                </span>

                <span className="font-semibold text-[12px] text-color-TXT-1">
                  |
                </span>

                <span className="cursor-pointer font-normal text-[12px] text-color-TXT-1 hover:text-white">
                  Accessibility Statement
                </span>
              </div>
            </div>

            <div className="h-[240px] py-3 px-[32px]">
              <div className="w-[3px] h-full rounded-lg bg-color-BG opacity-50"></div>
            </div>

            <div className="w-[450px] h-full flex flex-col justify-start items-start py-[24px] gap-y-[12px]">
              <span className="font-semibold text-[14px] text-color-TXT">
                CONTACT US
              </span>
              <div className="flex flex-col justify-start items-start gap-y-[12px]">
                <span className="flex items-start justify-center gap-x-[8px] rounded-[5px] text-[14px] font-normal text-color-TXT-1">
                  <span className="w-[25px] h-[25px] flex justify-center items-center rounded-full bg-color-BG-1">
                    <MdPhone className="text-[16px] text-color-A" />
                  </span>
                  <div className="flex flex-col items-start justify-center">
                    <a
                      className="hover:text-white cursor-pointer"
                      href="tel:8613352954383"
                    >
                      (+86) 13352954383
                    </a>
                    <a
                      className="hover:text-white cursor-pointer"
                      href="tel:8675526664383"
                    >
                      (+86) 75526664383
                    </a>
                  </div>
                </span>
                <span className="flex items-center justify-center gap-x-[8px] rounded-[5px] cursor-pointer text-[14px] font-normal text-color-TXT-1 hover:text-white">
                  <span className="w-[25px] h-[25px] flex justify-center items-center rounded-full bg-color-BG-1">
                    <MdEmail className="text-[14px] text-color-A" />
                  </span>
                  <a href="mailto:info@icworldelect.com">
                    info@icworldelect.com
                  </a>
                </span>
              </div>
              <span className="font-semibold text-[14px] text-color-TXT mt-[18px]">
                FOLLOW US
              </span>
              <div className="flex gap-x-[8px]">
                <a
                  className="cursor-pointer text-[20px] text-color-BG-1 hover:text-white"
                  href="https://www.instagram.com/icworldelectronics?igsh=MWs3cnZwOTVsMmdqNQ=="
                  target="_blank"
                >
                  <FaInstagram />
                </a>
                <a
                  className="cursor-pointer text-[20px] text-color-BG-1 hover:text-white"
                  href="https://linkedin.com/company/icworldelectronics/?viewAsMember=true"
                  target="_blank"
                >
                  <AiFillLinkedin />
                </a>
                <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
                  <FaTelegramPlane />
                </span>
                <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
                  <AiFillWechat />
                </span>

                <span className="cursor-pointer text-[20px] text-color-TXT-dark hover:text-color-BG-1">
                  <BsTwitterX />
                </span>
              </div>
            </div>
          </div>

          <span className="w-full h-7 flex justify-center items-center text-[10px] gap-x-1 font-semibold bg-[#2c2c2c] text-color-TXT">
            Copyright <FaRegCopyright /> 2014 - 2024 IcWorldelect.com, All
            Rights Reserved
          </span>
        </div>
      )}
    </>
  );
}
