import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

export default function ScrollToFirst() {
  const [isShownScrollBtn, setIsShownScrollBtn] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 100
        ? setIsShownScrollBtn(true)
        : setIsShownScrollBtn(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {isShownScrollBtn && (
        <div
          className="fixed w-[40px] h-[40px] flex items-center justify-center rounded-full bg-color-B text-color-TXT-dark bottom-[20px] right-[20px] z-20 cursor-pointer"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FaArrowUp />
        </div>
      )}
    </>
  );
}
