import "../assets/Css/Styles/general.css";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import globalContext from "../Contexts/GlobalContext";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { REGEX } from "../constants/constanst";
import { AlertAlarm, getMessage, handleServerMode } from "../Js/functions";
import { useCookies } from "react-cookie";
import { SERVERMODE } from "../constants/serverMode";
import { BsChatDotsFill } from "react-icons/bs";
import { MdEmail, MdLocationOn, MdPhone } from "react-icons/md";
import ScrollToFirst from "../components/ScrollToFirst";

export default function ContactUs() {
  const BASE_URL = process.env.REACT_APP_ROOT_API_ADDRESS;
  const Nav = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();
  const contextData = useContext(globalContext);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [text, setText] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailEr] = useState("");
  const [subjectErr, setSubjectErr] = useState("");
  const [textErr, setTextErr] = useState("");

  const sendEmail = () => {
    if (!isLoading) {
      if (checkInputValidation()) {
        setIsLoading(true);
        axios
          .post(`${BASE_URL}/message/set`, {
            fullName: name + " " + lastName,
            email: email,
            subject: Subject,
            message: text,
          })
          .then((res) => {
            var responseMessage = res?.data?.message;
            var responseMode = res?.data?.mode;
            if (responseMode === SERVERMODE.REQUEST_OK) {
              AlertAlarm(
                contextData,
                "Your Email Submited Successfully",
                "SUCCES",
                false,
                ""
              );
              setName("");
              setLastName("");
              setEmail("");
              setSubject("");
              setText("");
              setIsLoading(false);
            } else {
              handleServerMode(
                responseMode,
                responseMessage,
                contextData,
                cookies,
                removeCookies,
                Nav
              );
              setIsLoading(false);
            }
          })
          .catch((err) => {
            var errMessage = err?.response?.data?.message;
            if (errMessage?.length > 5) {
              AlertAlarm(contextData, errMessage, "ERROR", false, "");
            } else {
              AlertAlarm(
                contextData,
                "Something Went Wrong",
                "ERROR",
                false,
                ""
              );
            }
            setIsLoading(false);
          });
      }
    }
  };

  const checkInputValidation = () => {
    let isOk = true;
    if (
      !REGEX.STRING.test(name) ||
      name === "" ||
      !REGEX.EMPTY_SPACE.test(name)
    ) {
      isOk = false;
      setNameErr(getMessage(301));
    }
    if (name.replaceAll(" ", "")?.length < 3) {
      isOk = false;
      setNameErr(getMessage(302));
    }
    if (
      !REGEX.STRING.test(lastName) ||
      lastName === "" ||
      !REGEX.EMPTY_SPACE.test(lastName)
    ) {
      isOk = false;
      setLastNameErr(getMessage(301));
    }
    if (lastName.replaceAll(" ", "")?.length < 3) {
      isOk = false;
      setLastNameErr(getMessage(302));
    }
    if (!REGEX.EMAIL.test(email)) {
      isOk = false;
      setEmailEr(getMessage(303));
    }
    if (
      !REGEX.STRING.test(Subject) ||
      Subject === "" ||
      !REGEX.EMPTY_SPACE.test(Subject)
    ) {
      isOk = false;
      setSubjectErr(getMessage(301));
    }
    if (Subject.replaceAll(" ", "")?.length < 3) {
      isOk = false;
      setSubjectErr(getMessage(302));
    }
    if (
      !REGEX.STRING.test(text) ||
      text === "" ||
      !REGEX.EMPTY_SPACE.test(text)
    ) {
      isOk = false;
      setTextErr(getMessage(301));
    }
    if (text.replaceAll(" ", "")?.length < 3) {
      isOk = false;
      setTextErr(getMessage(302));
    }

    return isOk;
  };

  const [viewMode, setViewMode] = useState("");
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width < 740) setViewMode("M");
      else setViewMode("D");
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.screen.width]);
  return (
    <>
      <Header />
      {viewMode === "M" ? (
        <div className="w-full h-full flex flex-col justify-start items-center gap-y-[24px] bg-color-BG">
          <div className="absolute w-full h-[240px] bg-color-A z-0"></div>

          <div className="relative w-full flex flex-col items-center">
            <div className="absolute w-1 h-[110px] left-[35px] top-[45px] bg-color-TXT"></div>
            <div className="absolute w-4 h-4 left-[29px] top-[38px] rounded-full bg-color-TXT"></div>
            <div className="h-full flex-1 flex-col justify-start items-start mt-[20px]">
              <div className="px-[64px] py-[16px]">
                <h1 className="text-color-TXT text-[28px] font-bold">
                  COUNTACT US
                </h1>
                <p className="text-color-TXT-1 text-[14px] ">
                  Let's talk about your needs
                  <br />
                  Drop us a line through the form below and we'll get back to
                  you
                </p>
              </div>
              <div className="w-full flex items-center justify-center px-[20px]">
                <div className="h-full w-full p-[20px]  flex flex-col justify-start  items-center rounded-xl gap-y-[24px] bg-white mb-[24px] shadow-2xl">
                  <TextField
                    label="Name"
                    size="small"
                    className="w-full"
                    value={name}
                    onChange={(e) => {
                      setNameErr("");
                      setName(e.target.value);
                    }}
                    error={nameErr}
                    helperText={`${nameErr}`}
                  />
                  <TextField
                    label="Last Name"
                    size="small"
                    className="w-full"
                    value={lastName}
                    onChange={(e) => {
                      setLastNameErr("");
                      setLastName(e.target.value);
                    }}
                    error={lastNameErr}
                    helperText={`${lastNameErr}`}
                  />
                  <TextField
                    label="Your Email"
                    size="small"
                    className="w-full"
                    value={email}
                    onChange={(e) => {
                      setEmailEr("");
                      setEmail(e.target.value);
                    }}
                    error={emailErr}
                    helperText={`${emailErr}`}
                  />
                  <TextField
                    label="Subject"
                    size="small"
                    className="w-full"
                    value={Subject}
                    onChange={(e) => {
                      setSubjectErr("");
                      setSubject(e.target.value);
                    }}
                    error={subjectErr}
                    helperText={`${subjectErr}`}
                  />
                  <TextField
                    multiline
                    rows={8}
                    label="Text"
                    className="w-full h-full"
                    value={text}
                    onChange={(e) => {
                      setTextErr("");
                      setText(e.target.value);
                    }}
                    error={textErr}
                    helperText={`${textErr}`}
                  />
                  <div className="w-full flex items-center justify-end mt-[12px] gap-x-[20px] bg-yellow-600">
                    <button
                      className="w-full h-[40px] flex justify-center items-center gap-x-2 transition-colors text-[13px] font-bold Type_1 px-[16px] py-[8px]"
                      onClick={sendEmail}
                    >
                      {isLoading ? (
                        <ReactLoading
                          type={"bubbles"}
                          color={"#ECECEB"}
                          height={45}
                          width={45}
                        />
                      ) : (
                        <>Send</>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="relative w-full h-full flex flex-col justify-start items-start gap-y-5 text-color-A mb-[20px] px-[20px]">
                <div className="w-full flex justify-start items-start gap-x-3">
                  <span className="w-[35px] h-[35px] flex justify-center items-center rounded-full bg-color-A-2">
                    <MdPhone className="text-[20px] text-color-BG" />
                  </span>
                  <div className="flex flex-col items-start justify-center">
                    <a
                      className="hover:text-color-TXT-dark cursor-pointer"
                      href="tel:8613352954383"
                    >
                      (+86) 13352954383
                    </a>
                    <a
                      className="hover:text-color-TXT-dark cursor-pointer"
                      href="tel:8675526664383"
                    >
                      (+86) 75526664383
                    </a>
                  </div>
                </div>
                <div className="w-full flex justify-start items-start gap-x-3">
                  <span className="w-[35px] h-[35px] flex justify-center items-center rounded-full transition-colors bg-color-A-2">
                    <MdEmail className="text-[18px] text-color-BG" />
                  </span>
                  <a
                    className="hover:text-color-TXT-dark cursor-pointer"
                    href="mailto:info@icworldelect.com"
                  >
                    info@icworldelect.com
                  </a>
                </div>
                <div className="w-full flex justify-start items-start gap-x-3">
                  <span className="w-[35px] h-[35px] flex justify-center items-center rounded-full transition-colors bg-color-A-2 ">
                    <MdLocationOn className="text-[20px] text-color-BG" />
                  </span>
                  <span className="text-[15px] flex-1">
                    15B, Finance Center, No22 Taizi Road, Nanshan Shenzhen,
                    Guangdong, China
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-between items-center gap-y-[24px] bg-color-BG">
          <div className="absolute w-full h-[300px] bg-color-A z-0"></div>
          <div className="relative w-full h-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 flex justify-between items-start gap-x-[32px] z-10 ">
            <div className="absolute w-1 h-[120px] left-[35px] top-[55px] bg-color-BG"></div>
            <div className="absolute w-4 h-4 left-[29px] top-[48px] rounded-full bg-color-BG"></div>
            <div className="h-full flex-1 flex-col justify-start items-start">
              <div className="px-[64px] py-[32px]">
                <h1 className="text-color-TXT text-[28px] font-bold">
                  COUNTACT US
                </h1>
                <p className="text-color-TXT-1 text-[14px] mt-[20px]">
                  Let's talk about your needs
                  <br />
                  Drop us a line through the form below and we'll get back to
                  you
                </p>
              </div>
              <div className="h-full w-full p-[32px] flex flex-col justify-start items-start rounded-xl gap-y-[24px] bg-color-BG shadow-2xl">
                <TextField
                  label="Name"
                  size="small"
                  className="w-full"
                  value={name}
                  onChange={(e) => {
                    setNameErr("");
                    setName(e.target.value);
                  }}
                  error={nameErr}
                  helperText={`${nameErr}`}
                />
                <TextField
                  label="Last Name"
                  size="small"
                  className="w-full"
                  value={lastName}
                  onChange={(e) => {
                    setLastNameErr("");
                    setLastName(e.target.value);
                  }}
                  error={lastNameErr}
                  helperText={`${lastNameErr}`}
                />
                <TextField
                  label="Your Email"
                  size="small"
                  className="w-full"
                  value={email}
                  onChange={(e) => {
                    setEmailEr("");
                    setEmail(e.target.value);
                  }}
                  error={emailErr}
                  helperText={`${emailErr}`}
                />
                <TextField
                  label="Subject"
                  size="small"
                  className="w-full"
                  value={Subject}
                  onChange={(e) => {
                    setSubjectErr("");
                    setSubject(e.target.value);
                  }}
                  error={subjectErr}
                  helperText={`${subjectErr}`}
                />
                <TextField
                  multiline
                  rows={8}
                  label="Text"
                  className="w-full h-full"
                  value={text}
                  onChange={(e) => {
                    setTextErr("");
                    setText(e.target.value);
                  }}
                  error={textErr}
                  helperText={`${textErr}`}
                />
                <div className="w-full flex items-center justify-end mt-[12px] gap-x-[20px]">
                  <button
                    className="w-full h-[40px] flex justify-center items-center gap-x-2 transition-colors text-[13px] font-bold Type_1 px-[16px] py-[8px]"
                    onClick={sendEmail}
                  >
                    {isLoading ? (
                      <ReactLoading
                        type={"bubbles"}
                        color={"#ECECEB"}
                        height={45}
                        width={45}
                      />
                    ) : (
                      <>Send</>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="relative w-[300px] h-full flex flex-col justify-between items-start mt-[100px]">
              <img
                src="../assets/images/img-contact.png"
                className="relative w-[300px] h-[300px] rounded-xl object-cover"
              />
              <div className="relative w-full h-full flex flex-col justify-start items-start gap-y-5 text-color-A pl-1 pr-1">
                <div className="w-full flex justify-start items-start gap-x-3">
                  <span className="w-[35px] h-[35px] flex justify-center items-center rounded-full bg-color-A-2 hover:bg-color-A">
                    <MdPhone className="text-[20px] text-color-BG" />
                  </span>
                  <div className="flex flex-col items-start justify-center">
                    <a
                      className="hover:text-color-TXT-dark cursor-pointer"
                      href="tel:8613352954383"
                    >
                      (+86) 13352954383
                    </a>
                    <a
                      className="hover:text-color-TXT-dark cursor-pointer"
                      href="tel:8675526664383"
                    >
                      (+86) 75526664383
                    </a>
                  </div>
                </div>
                <div className="w-full flex justify-start items-start gap-x-3">
                  <span className="w-[35px] h-[35px] flex justify-center items-center rounded-full transition-colors bg-color-A-2 hover:bg-color-A">
                    <MdEmail className="text-[18px] text-color-BG" />
                  </span>
                  <a
                    className="hover:text-color-TXT-dark cursor-pointer"
                    href="mailto:info@icworldelect.com"
                  >
                    info@icworldelect.com
                  </a>
                </div>
                <div className="w-full flex justify-start items-start gap-x-3">
                  <span className="w-[35px] h-[35px] flex justify-center items-center rounded-full transition-colors bg-color-A-2 hover:bg-color-A">
                    <MdLocationOn className="text-[20px] text-color-BG" />
                  </span>
                  <span className="text-[15px] flex-1">
                    15B, Finance Center, No22 Taizi Road, Nanshan Shenzhen,
                    Guangdong, China
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center w-full h-[200px]  text-[#d6d6d6] ">
            <div className="w-full h-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 ">
              <div className="w-[calc(100%-300px)] h-full  flex items-center justify-center">
                <div className="flex flex-col justify-center items-center">
                  <span className="font-bold text-[24px]">
                    IC World Electronics
                  </span>
                  <span className="text-[16px] font-bold text-[#d6d6d6]">
                    深 圳 艾 世 界 科 技 有 限 公 司
                  </span>
                </div>
                <span className="w-[3px] h-[100px] mx-[32px] rounded-full bg-[#d6d6d6]"></span>
                <div className="flex flex-col justify-center items-start">
                  <span className="font-medium text-[14px]">
                    Selling all electronic parts available in the market
                  </span>
                  <br />
                  <span className="font-medium text-[14px]">
                    Shipping to all over the world
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
      <ScrollToFirst />
    </>
  );
}
