const Alphabet = [
  "#",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const Manufactures = [
  {
    key: "#",
    value: [
      "1GLOBAL",
      "2J Antennas",
      "3DXTECH",
      "3G Shielding Specialties",
      "3M",
      "3PEAK",
      "3RLab",
      "3S (Solid State System)",
      "3M4D Systems",
      "5 Core",
      "5G Hub",
      "64seconds",
      "7STARLAKE",
      "80/20",
    ],
  },
  {
    key: "A",
    value: [
      "A D Metro",
      "A&D Engineering",
      "Aaronia AG",
      "Aavid (BOYD)",
      "ABB Power Conversions (OmniOn Power)",
      "ABC Taiwan Electronics Corp",
      "ABLE Power Products",
      "ABLIC",
      "Abracon",
      "Absolute Process Instruments",
      "AC Connectors",
      "AC Electronics",
      "AC Tasarim",
      "Accelerated / Digi International",
      "Accelerated Memory Production, Inc.",
      "ACCES I/O Products",
      "Accessories for America",
      "Acconeer AB",
      "Accurate Screw Machine",
      "ACES GROUP",
      "ACI (Automation Components, Inc.)",
      "ACKme (Zentri)",
      "ACL Staticide, Inc.",
      "Acopian",
      "Acroname",
      "ACS",
      "ACT (Advanced Cable Ties)",
      "ACTINIUS B.V.",
      "Actuonix Motion Devices, Inc.",
      "Acura Embedded Systems",
      "Acuva",
      "Adafruit",
      "Adam Equipment",
      "Adam Tech",
      "Adapteva",
      "Adaptive Technologies",
      "ADATA Industrial",
      "ADD3D (MakerChamber)",
      "Adels-Contact",
      "Adesto Technologies",
      "AADI (Analog Devices, Inc.)",
      "Adoretek",
      "ADSANTEC",
      "ADTEK",
      "AADURA LED Solutions",
      "Advanced Cable Ties",
      "Advanced Energy",
      "Advanced Linear Devices, Inc.",
      "Advanced Micro Lites",
      "Advanced Photonix",
      "Advanced Sensors / Amphenol",
      "Advanced Thermal Solutions, Inc.",
      "Advantech",
      "Adveos",
      "Aearo Technologies, a 3M company",
      "Aegis Power Systems",
      "AEH (All Electronics Hardware)",
      "AEI",
      "Aeon Matrix",
      "Aeonsemi Inc.",
      "Aeris",
      "Aeroflex (MACOM Technology Solutions)",
      "Aerospace Defense and Marine / TE Connectivity",
      "AerosUSA",
      "Affinity Medical Technologies - Molex",
      "AFSI (Amphenol Fiber Systems International)",
      "Agastat Relays / TE Connectivity",
      "AGILINK MICROWIRES",
      "AGILTRON INC.",
      "Agrowtek Inc.",
      "AI BLOX",
      "AIC tech",
      "AideTek",
      "Aim Dynamics",
      "AIM-Cambridge / Cinch Connectivity Solutions",
      "AIMS Power",
      "Aimtec",
      "Air Electro, Inc.",
      "Air LB (Amphenol Pcd)",
      "Air Science USA LLC",
      "AirBorn",
      "Airgain",
      "Airity Technologies",
      "Airpax / Sensata Technologies",
      "AIRTEC Pneumatics",
      "Aishi Capacitors (Hunan Aihua Group)",
      "AISMALIBAR",
      "AiStorm",
      "Aitek",
      "Aker Technology USA",
      "AKM",
      "AK-NORD GmbH",
      "AKYGA",
      "Alango",
      "Alchemy Power Inc.",
      "ALCOSWITCH Switches / TE Connectivity",
      "ALFA Network Inc.",
      "AlfaMag Electronics (Amgis)",
      "Alfra Tools (Rix Tools)",
      "All Electronics Hardware",
      "All Flex, Inc.",
      "All In Retail",
      "All Sensors Corporation",
      "Allegro MicroSystems",
      "Alliance",
      "Alliance (Essentra Components)",
      "Alliance Memory, Inc.",
      "Alliance Sensors Group a div of HG Schaevitz LLC",
      "Allied Components International",
      "Allied Vision",
      "Alloy LED",
      "Allsensing",
      "Allystar",
      "Almit (ANA TRADING)",
      "AlorAir",
      "Alorium Technology",
      "Alpenglow Industries",
      "Alpha and Omega Semiconductor, Inc.",
      "Alpha Lighting",
      "Alpha Micro Components",
      "Alpha Wire",
      "AlphaLab Inc.",
      "Alps Alpine",
      "Altech Corporation",
      "Altera (Intel)",
      "Altran Magnetics",
      "Altum RF",
      "AM Equipment",
      "Amano",
      "Amazing",
      "AMBER Wireless GmbH (Würth Elektronik)",
      "Ambiq Micro, Inc.",
      "AMBO TECHNOLOGY",
      "AMD",
      "AMEC Thermasol",
      "American Beauty Tools",
      "American Bright",
      "American Electrical, Inc.",
      "American Hakko Products, Inc.",
      "American Opto Plus LED Corp.",
      "American Recorder Technologies",
      "American Zettler",
      "Amerivacs",
      "Amerway Inc.",
      "Ametherm",
      "Amgis",
      "AMI (Altran Magnetics)",
      "AMI Semiconductor/onsemi",
      "Amotech",
      "AMP Connectors/TE Connectivity",
      "Ampco Safety Tools",
      "Amp'ed RF Tech",
      "Amphenol",
      "Amphenol Advanced Sensors",
      "Amphenol Aerospace Operations",
      "Amphenol Air LB",
      "Amphenol Alden",
      "Amphenol All Sensors",
      "Amphenol Anytek",
      "Amphenol Aorora",
      "Amphenol Audio",
      "Amphenol Cables on Demand",
      "Amphenol Commercial IO",
      "Amphenol Communications Solutions",
      "Amphenol CONEC",
      "Amphenol Connex (Amphenol RF)",
      "Amphenol Custom Cable",
      "Amphenol Entertainment",
      "Amphenol FCI Basics",
      "Amphenol Fiber Systems International",
      "Amphenol High Speed Backplane Products",
      "Amphenol High Speed IO",
      "Amphenol ICC (Amphenol Communications Solutions)",
      "Amphenol Industrial",
      "Amphenol InterCon Systems",
      "Amphenol Limited",
      "Amphenol LTD",
      "Amphenol LTW",
      "Amphenol Mezzanine Products",
      "Amphenol NEXUS Technologies",
      "Amphenol NovaSensor",
      "Amphenol Onanon",
      "Amphenol Pcd",
      "Amphenol Piher Sensing Systems",
      "Amphenol Positronic",
      "Amphenol Power Solutions",
      "Amphenol Procom",
      "Amphenol Prolabs",
      "Amphenol RF",
      "Amphenol SGX Sensortech",
      "Amphenol Sine Systems",
      "Amphenol Socapex",
      "Amphenol SSI Technologies",
      "Amphenol Storage & Server IO",
      "Amphenol SV Microwave",
      "Amphenol Telaire",
      "Amphenol The Modal Shop",
      "Amphenol Thermometrics",
      "Amphenol Times Microwave Systems",
      "Amphenol Tuchel Electronics",
      "Amphenol Wilcoxon Sensing Technologies",
      "Ampleon",
      "Amplifier Solutions Corporation",
      "Amprobe",
      "ams OSRAM",
      "Amstat Industries",
      "AMTECH",
      "Amtery Corporation",
      "AMTI Products",
      "Amulet Technologies, LLC.",
      "AMX3D",
      "Analog Devices / Maxim Integrated",
      "Analog Devices, Inc.",
      "Analog Power",
      "Analog Technologies",
      "Anamet Electrical Inc",
      "Anaren Wireless / TTM Technologies",
      "Anatech Electronics Inc.",
      "Anatech Microwave Company",
      "Anbon Semiconductor",
      "AnD Cable Products",
      "AnDAPT",
      "Anderson Power Products",
      "Andromeda Interfaces",
      "AndyMark",
      "Anfield Industries",
      "Angst+Pfister Sensors and Power",
      "Angstrohm / Vishay",
      "Antenna Company",
      "Antenna Technologies Limited Company",
      "Antenova",
      "Antimatter Research",
      "ANYSOLAR",
      "Anytek (Amphenol Anytek)",
      "Aorora (Amphenol Communications Solutions)",
      "Apacer",
      "APEM Inc.",
      "Apex Microtechnology",
      "Apex Tool Group",
      "APG",
      "APG Vision",
      "API Technologies Corp.",
      "ApisLogik",
      "APM Hexseal",
      "APPI-Technology",
      "Applied Kilovolts",
      "Applied Physics Systems",
      "AppliedSBC Systems",
      "APPROVED TECHNOLOGY",
      "APS",
      "Aptina/onsemi",
      "Aras Power Technologies",
      "Arc Suppression Technologies",
      "ARCH",
      "Archimedes Controls",
      "ARCOL (Ohmite)",
      "Arcolectric (Bulgin)",
      "Arcotek",
      "Arcotronics (KEMET)",
      "Arcus Technology",
      "Arducam",
      "Arduino",
      "ArduSimple (EPS WORKS SL)",
      "Arieltech",
      "Aries Electronics, Inc.",
      "ARIES Embedded",
      "ARK Electronics",
      "ArkX Laboratories",
      "Arlitech Electronic Corp.",
      "ARM",
      "Arrgh!!",
      "Arshon Technology Inc.",
      "Artaflex Inc.",
      "Artekit Labs",
      "ARTERY",
      "ARTESYN / Advanced Energy",
      "ARTIK (Samsung ARTIK)",
      "Artisan Power",
      "Asahi Crosslink",
      "Asahi Kasei Microdevices Corporation (AKM)",
      "Ascend Frequency Devices",
      "Ascentta",
      "ASI-Ez",
      "Aslong motor",
      "ASO Safety Solutions",
      "Asset Instruments Engineering",
      "ASSMANN WSW Components",
      "Astec America (ARTESYN)",
      "Astera Labs",
      "Astro Tool Corp.",
      "Astrodyne TDI",
      "Asus",
      "Asustor",
      "ATC - Diversified Electronics",
      "ATEKMIDAS",
      "ATGBICS® by Approved Technology Ltd",
      "Atlanta Micro",
      "Atlas Scientific",
      "Atmel (Microchip Technology)",
      "Atop Technologies",
      "ATP Electronics, Inc.",
      "Atrix International",
      "Attabox",
      "ATTEND Technology",
      "ATX LED",
      "Auctus Solutions",
      "Audiowell",
      "Auer Signal",
      "Ault / SL POWER",
      "Aurasemi",
      "Austin Hardware & Supply, Inc.",
      "Autec",
      "AuthenTrend",
      "Automated Component Specialists (ACS)",
      "Automation Center",
      "Automation Components, Inc.",
      "Automation Integration",
      "Autonics USA",
      "Autoslide",
      "Avago Technologies (Broadcom)",
      "Avanti",
      "AVCOMM",
      "Aven",
      "Avery Dennison",
      "AVMATRIX",
      "AVR Global Technologies, Inc.",
      "AVSENSORS",
      "AVX (KYOCERA AVX)",
      "Axicom Relays / TE Connectivity",
      "Axiomatic Technologies",
      "AZ Displays",
      "Azoteq",
      "Aztronic / Vishay",
      "Azumo",
    ],
  },
  {
    key: "B",
    value: [
      "B B Battery",
      "B&F Fastener Supply",
      "B&J USA, INC.",
      "B&K Precision",
      "B+B SmartWorx (Advantech)",
      "B+B Thermo-Technik GmbH",
      "B4 Limits",
      "B9Creations",
      "Bacancy",
      "Balluff",
      "Band Solutions, LLC",
      "Banner Engineering",
      "Bansbach easylift",
      "Bantam Tools",
      "BAPI/Building Automation Products Inc.",
      "Bardac Corporation",
      "BARNHILL BOLT CO., INC.",
      "BARO Vehicles",
      "Barry Industries / Vishay",
      "Bartels Mikrotechnik",
      "Bartovation",
      "BASF",
      "Basler",
      "Battery Alliance",
      "Battery Store",
      "BATTERY WORLD",
      "BatteryGuy",
      "BBM Battery",
      "BD&E (Bodkin Design & Engineering)",
      "BDE Technology",
      "BDNC (Holding) Limited",
      "Beacon EmbeddedWorks",
      "BeagleBoard.org",
      "BEANAIR SENSORS",
      "Beau Interconnect - Molex",
      "Becom Electronics",
      "Bedrock Audio",
      "BEI Kimco / Sensata Technologies",
      "BEI Sensors / Sensata Technologies",
      "BEKING OPTOELECTRONICS",
      "Bel",
      "Bel Fuse, Inc.",
      "Bel Power Solutions",
      "Belden",
      "Belden Hirschmann",
      "Bellini Tiziana S.r.l.",
      "Bellnix Co., LTD.",
      "BenchPro",
      "Bend Labs",
      "Benjamin VERNOUX/HydraBus",
      "BeRex Corp",
      "Bergquist / Henkel",
      "BERNIER CONNECT",
      "Bertech",
      "BeStar Technologies, Inc.",
      "Betronka",
      "Beyond LED Technology",
      "BEYONDTECH",
      "BHW Technologies",
      "BI Technologies / TT Electronics",
      "Bias Power",
      "Big Cat Cables",
      "Binho",
      "Bird Technologies",
      "Birk Manufacturing",
      "Birtcher / nVent",
      "Bishop-Wisecarver",
      "Bittware - Molex",
      "Bivar, Inc.",
      "BLA ETECH PVT LTD",
      "Black Oak Engineering",
      "Blaze Technology",
      "Blue Clover Devices",
      "Blue Harmonics",
      "Blue Robotics",
      "Bluebird Labs",
      "BlueCreation",
      "BLUELEC",
      "Blues",
      "Blueshift",
      "Bluetti",
      "BNNano",
      "BNS Solutions",
      "Bodkin Design & Engineering",
      "Bogen Magnetics GmbH",
      "Bojue Precision Machining",
      "Bolton Technical",
      "Bomar (Winchester Electronics)",
      "Bopla Enclosures",
      "Boréas Technologies",
      "Bosch Sensortec",
      "BotBlox/Kapek",
      "BotFactory",
      "Botron Company Inc.",
      "Boulder Engineering Studio",
      "Boundary Devices",
      "Bourns, Inc.",
      "Bowman Dispensers",
      "BOYD",
      "BPM Microsystems",
      "Brad Harrison - Molex",
      "Brady Corporation",
      "Brainboxes",
      "Bridgelux, Inc.",
      "Bridgetek",
      "Bright IIOT",
      "Bright Toward Industrial",
      "Bright Way Group (Continental Battery Systems)",
      "BriskHeat",
      "Briteluxe",
      "Broadcom",
      "Broadsens Corporation",
      "BroadWave Technologies, Inc.",
      "Brook-Livin",
      "Bruckewell",
      "BSE",
      "Buchanan Terminal Blocks / TE Connectivity",
      "Bud Industries, Inc.",
      "Buildtak",
      "BUILT Systems",
      "Bulgin",
      "Bumper Specialties, Inc.",
      "Burr-Brown (Texas Instruments)",
      "BusBoard Prototype Systems",
      "Business Systems Connection Inc.",
      "Bussmann (Eaton)",
      "BYTe Semiconductor",
      "BZBGEAR",
    ],
  },
  {
    key: "C",
    value: [
      "C&D Technologies (Murata Power Solutions)",
      "C&K Aerospace – a Division of Littelfuse",
      "C&K, now part of Littelfuse",
      "c3controls",
      "Cables on Demand (Amphenol Cables on Demand)",
      "Cabling123",
      "Caddock Electronics, Inc.",
      "CAEN RFID",
      "CAIG Laboratories, Inc.",
      "Cal Test Electronics",
      "CalChip Connect",
      "CAL-CHIP ELECTRONICS INC.",
      "Califia Lighting (Bivar)",
      "Calmark / nVent",
      "CalRamic",
      "Cambion Electronics",
      "Cambridge GaN Devices",
      "CamdenBoss",
      "Camozzi Automation",
      "Canaan Semiconductor Pty Ltd",
      "Canbat",
      "Canfield Technologies",
      "CANLAB s.r.o.",
      "Cannon (ITT Cannon)",
      "Canon",
      "Cantherm",
      "CapeSym",
      "Capital Advanced Technologies, Inc.",
      "Caplugs",
      "CAP-XX",
      "Carclo Technical Plastics",
      "Cardinal Components",
      "Carling Technologies / Littelfuse",
      "Carlisle Interconnect Technologies",
      "Carlo Gavazzi",
      "CAROL (Prysmian Group)",
      "Casambi",
      "Cassia Networks",
      "Catalyst Semiconductor/onsemi",
      "Cazaar",
      "CCEA Technical Lighting",
      "CCI (Conductive Containers, Inc.)",
      "CDA (Zhifengwei Technology)",
      "CDE (Cornell Dubilier Knowles)",
      "CEJN",
      "CEL (California Eastern Laboratories)",
      "Celduc",
      "Celerity Technologies",
      "Central Semiconductor",
      "Central Technologies",
      "Century Spring Corp.",
      "Cera-Mite / Vishay",
      "CEVA",
      "CEVA Hillcrest Labs",
      "CGS Resistors / TE Connectivity",
      "CGS Tape",
      "CH Products",
      "CHAIR MASTER",
      "Challenge Electronics",
      "Charter Engineering",
      "CHASM Advanced Materials",
      "Chemi-Con",
      "Chempure Brand Chemicals",
      "Chemtronics",
      "Cherry Americas",
      "Cheval",
      "Chicago Pneumatic Tool",
      "Chilisin Electronics",
      "ChinCheng Casters Co., Ltd.",
      "Chip Quik, Inc.",
      "Chip Shine / CSRF",
      "Chip-Bridge Technologies",
      "Chipown",
      "Chipsee",
      "Chiral Photonics",
      "Chogori Technologies",
      "CHOOVIO",
      "ChromeLED",
      "Cicoil",
      "CII / TE Connectivity",
      "Cinch Connectivity Solutions",
      "Cincon",
      "Circuit Design",
      "Circuit Dojo",
      "Circuit Insights",
      "CircuitMess",
      "Cirronet / RFM (Murata Power Solutions)",
      "Cirrus Logic",
      "CISSOID",
      "CIT (Carlisle Interconnect Technologies)",
      "CIT Relay and Switch",
      "Cita Technologies",
      "CITEL INC",
      "Citizen Electronics Co., Ltd.",
      "Citizen Finedevice Co., LTD.",
      "City Technology",
      "Clairitec SARL",
      "Clarostat (Honeywell Sensing and Productivity Solutions)",
      "Clean Energy Global",
      "CLIFF",
      "CMD (ON Semiconductor)",
      "CML Innovative Technologies",
      "CML Microcircuits",
      "CMOS SENSOR INC.",
      "CnC Tech",
      "COAX Connectors",
      "Cocom",
      "CODACA",
      "Coex",
      "Coherent",
      "Coherent Thermal Solutions",
      "COILCRAFT",
      "Coiltronics (Eaton)",
      "Cologne Chip",
      "Columbia Research Laboratories Inc.",
      "Comair Rotron",
      "Combustion Research Corporation",
      "Comchip Technology",
      "Comet America",
      "Commercial IO (Amphenol Communications Solutions)",
      "Commercial Vehicle Products / Littelfuse",
      "Commonlands",
      "Compass Instruments",
      "Components Express",
      "CompuCablePlusUSA",
      "Comus International",
      "Concept Technologie (Power Integrations)",
      "Concord Electronics",
      "Condor / SL POWER",
      "Conductive Containers, Inc.",
      "ConductRF",
      "CONEC (Amphenol CONEC)",
      "congatec",
      "Connective Peripherals",
      "Connex (Amphenol RF)",
      "Connor Winfield",
      "CONQUER ELECTRONICS",
      "ConsultNet Group LLC",
      "Conta-Clip",
      "Continental Battery Systems",
      "Contrinex - Molex",
      "Control By Web",
      "Control Products",
      "ControlAir",
      "CONTROLAR",
      "Conxall / Switchcraft",
      "Coolgear",
      "Cooling Source",
      "CoolMag",
      "Cooper Bussmann (Eaton)",
      "Copal Electronics (Nidec Components)",
      "Copper Mountain Technologies",
      "Coral Sense",
      "Corcom Filters / TE Connectivity",
      "Cordova Flow Controls",
      "CoreHW",
      "CoreLED Systems LLC",
      "Corelis",
      "Cornell Dubilier Knowles",
      "Correct Products",
      "Cosel",
      "Coto Technology",
      "CR Magnetics, Inc.",
      "CR Technology",
      "CraftStrom",
      "Crank Software",
      "Creality 3D",
      "Cree LED",
      "Crescent (Apex Tool Group)",
      "Crimp Academy",
      "Critical Link",
      "Crouzet",
      "Croven Crystals (Quantic Croven)",
      "Crown Matting Technologies",
      "Crowntech Photonics",
      "Crydom / Sensata Technologies",
      "Crystal IS",
      "Crystalfontz",
      "Crystek Corporation",
      "Crytur",
      "CS Hyde Company",
      "CTi Sensors",
      "C-Ton Industries",
      "CTR Electronics",
      "CTS Corporation",
      "CUI Devices",
      "CUI Inc",
      "Current Lighting",
      "Curtis Industries",
      "Curtis Instruments",
      "Custom Computer Services",
      "CviLux",
      "CW Industries",
      "CWB Electronics",
      "Cyber Power Systems (USA)",
      "Cyberdata Corporation",
      "Cynergy3 / Sensata Technologies",
      "Cyntec / Delta Electronics",
      "Cypress Semiconductor (Infineon Technologies)",
      "Cytron Technologies",
    ],
  },
  {
    key: "D",
    value: [
      "D.SignT",
      "D6 Labs",
      "DAB-Embedded",
      "Daburn",
      "Dakota Micro, Inc.",
      "Dale / Vishay",
      "Danisense",
      "Dantona Industries, Inc.",
      "Darfon",
      "Dart Controls",
      "Darwin Tech",
      "Data Modul Inc.",
      "Datacolor",
      "DataComm Electronics, Inc.",
      "Dataforth",
      "Datawave Wireless",
      "DAVE Embedded Systems",
      "Davies Molding, LLC.",
      "DB Unlimited",
      "DComponents",
      "ddm hopt+schuler",
      "Deca",
      "DECIDE4ACTION",
      "DeepWater Exploration",
      "Deepwave Digital",
      "Degree Controls",
      "DEHN",
      "Delevan",
      "Delkin Devices",
      "Delta Components",
      "Delta Controls",
      "Delta Electronics",
      "Delta Electronics / Cyntec",
      "Delta Electronics / EMI",
      "Delta Electronics / Fans",
      "Delta Electronics / Industrial Automation",
      "Delta Electronics / Power",
      "Deltatech / Sensata Technologies",
      "Demsay Elektronik",
      "Densitron",
      "Deschner Corporation",
      "Desco",
      "DET LLC/Diamond Edge Technology LLC",
      "DEUTSCH Connectors / TE Connectivity",
      "DEUTSCH ICT / TE Connectivity",
      "Devtank",
      "DFRobot",
      "DGH Corporation",
      "Dialight",
      "Dialog Semiconductor",
      "Diamond Systems",
      "Dielectric Laboratories",
      "Dielectric Laboratories (Knowles)",
      "Dierre Group",
      "Digi",
      "DIGIKEY STANDARD",
      "Digilent, Inc.",
      "Digi-Pas Technologies",
      "Digital Power Corporation",
      "Digital Six Laboratories, LLC",
      "Digital View Inc.",
      "DigiVac",
      "DINKLE Corporation USA",
      "DINStorage",
      "Diodes Incorporated",
      "Diotec Semiconductor",
      "DIRAK",
      "di-soric",
      "Display Logic",
      "Display Visions",
      "Displaytech",
      "DITEL",
      "DiTom Microwave Inc.",
      "Divelbiss Corporation",
      "Diwell Electronics",
      "DLC Display",
      "DLI",
      "D-Line",
      "DLP Design, Inc.",
      "DMI",
      "DMSI International",
      "DMX Engineering",
      "DNmicron",
      "Dojo Five",
      "DONG IL TECHNOLOGY",
      "Doodle Labs",
      "Dormeyer (Division of Johnson Electric)",
      "Dou Yee Enterprises",
      "Dow-Key Microwave",
      "Dracal Technologies",
      "Draloric/Vishay",
      "DreamVu",
      "Dremel",
      "dresden elektronik",
      "DSI/C & B Industries",
      "DunAn Microstaq Inc (DMQ)",
      "Dunbar Products",
      "Dunwell Tech, Inc.",
      "Duracell",
      "Dustronics",
      "Dusun",
      "DUT Electronics",
      "DVTEST",
      "Dycotec Materials",
      "Dynapar Corp",
      "Dynastream Innovations Inc. (Garmin Canada Inc.)",
      "Dynics",
    ],
  },
  {
    key: "E",
    value: [
      "E Connector Solutions",
      "E Ink",
      "E S D Control Centre Ltd.",
      "E+E Elektronik",
      "e2ip technologies",
      "EAO",
      "Earth People Technology",
      "EarthLCD",
      "Earthling 3.0",
      "EASE Electronics",
      "East Electronics",
      "EasyBraid (Menda/EasyBraid/Tronex)",
      "Eaton",
      "Eaton Souriau-Sunbank",
      "Eaton-Bussmann Electrical Division",
      "Eaton-Electronics Division",
      "EBCONNECTIONS",
      "Ebelong",
      "ebm-papst Inc.",
      "Eccel Technology",
      "ECCO",
      "Echelon by Adesto",
      "Eclipse Magnetics",
      "Eclipse Tools",
      "Ecliptek",
      "eco4life",
      "ECS Inc. International",
      "Ectron Corporation",
      "ED2 Corp",
      "EDAC Inc.",
      "EDATEC",
      "Edgelight",
      "EDSYN Inc.",
      "Efabless",
      "EFCO",
      "Effulgent Inc.",
      "Efinix, Inc.",
      "Egis Mobile Electric",
      "EI Sensor Technologies",
      "EIC Semiconductor, Inc.",
      "EIM Technology",
      "Eisele",
      "EIZO Rugged Solutions",
      "EKSMA Optics",
      "Elba Lubes",
      "Elco (AVX)",
      "Elcom International Pvt Ltd.",
      "Elcon Connectors / TE Connectivity",
      "ELECROW LIMITED",
      "Electric Imp",
      "Electric Mirror",
      "Electric Motors & Specialties",
      "Electro Corp (Honeywell Sensing and Productivity Solutions)",
      "Electro-Films (EFI) / Vishay",
      "Electroline",
      "ElectronAix",
      "Electronic Assembly (Display Visions)",
      "Electronic Concepts Inc.",
      "Electronic Prototypes",
      "Electro-Sensors, Inc.",
      "Electroswitch",
      "Electrotech",
      "Electroverge",
      "Elesa USA Corporation",
      "Elettrotek Kabel North America",
      "eLichens",
      "Elite RF",
      "Ellenex",
      "Ellsworth Adhesives",
      "Elna America",
      "Elosoft",
      "Elpress",
      "Elprotronic",
      "ELT SENSOR CORP",
      "EM Microelectronic",
      "EM Research",
      "EMA Design Automation",
      "EMAC, Inc.",
      "Embedded Artists",
      "Embention",
      "EMC Test Design",
      "Emcod Lighting Group",
      "Emerson Connectivity Solutions",
      "Emerson Embedded Power (ARTESYN)",
      "Emerson SolaHD",
      "EMI Filter Co",
      "EMI Solutions",
      "EMIT",
      "EMO Systems",
      "Encitech Connectors",
      "Enclustra",
      "Encoder Products Company",
      "enDAQ",
      "Endress+Hauser",
      "eneflux armtek magnetics inc.",
      "ENEPAQ",
      "Enerdoor",
      "Energizer Battery Company",
      "Energy Micro (Silicon Labs)",
      "EnerSys",
      "ENG ELECTRIC CO., LTD",
      "Enhance Electronics",
      "ENM Counting Instruments",
      "enmo Technologies",
      "Enmotus, Inc.",
      "EnOcean",
      "Entaniya",
      "Enteligent",
      "Envig",
      "EOS Power",
      "EPC",
      "EPC Space",
      "EPCOS (TDK)",
      "EPIGAP OSA Photonics",
      "Epoxy Technology",
      "EPS WORKS SL (ArduSimple)",
      "Epson",
      "EPtronics, Inc.",
      "Equinox Technologies",
      "EREM (Apex Tool Group)",
      "Ergo Advantage",
      "Ergotronix, Inc.",
      "ERNI / TE Connectivity",
      "ERP Power",
      "ESL Vision",
      "Espressif Systems",
      "ESPROS Photonics AG",
      "Essen Deinki",
      "Essentra Access Solutions",
      "Essentra Access Solutions / Mesan",
      "Essentra Components",
      "Essentra Components / Alliance",
      "Essentra Components / Micro Plastics, Inc.",
      "Essentra Components / Richco, Inc.",
      "ESTA / Vishay",
      "ESTA / Vishay",
      "Estatec",
      "eSUN",
      "E-Switch",
      "E-T-A",
      "ETA-USA",
      "ETI Systems",
      "Etron Technology",
      "EuControls",
      "Eupec (Infineon)",
      "Euri Lighting",
      "EUROPEAN THERMODYNAMICS",
      "Everactive",
      "Eveready (Energizer Battery Company)",
      "Everlight Electronics",
      "Everspin Technologies, Inc.",
      "EVVO",
      "EW Electronics",
      "Ewellite Photonics",
      "Exar (MaxLinear)",
      "ExAscend",
      "Excelitas Technologies",
      "EXCELSYS / Advanced Energy",
      "Excelta",
      "Exeltech",
      "Exiscan",
      "Express 24",
      "Extech (FLIR Extech)",
      "EXXELIA",
      "Eyecloud, Inc.",
      "E-Z LOK",
      "EZconn",
      "eze System, Inc.",
      "E-Z-Hook",
      "EZQuest, Inc.",
      "Ezurio",
    ],
  },
  {
    key: "F",
    value: [
      "F3 Wireless",
      "Fabru",
      "FaceKey",
      "Fairchild (onsemi)",
      "Fair-Rite Products Corp.",
      "Fairview Microwave",
      "Falconer Electronics, Inc.",
      "Famatel USA",
      "Fan-S Division / Qualtek Electronics Corp.",
      "Fanstel Corp.",
      "Fascomp Electronic Hardware",
      "FATH Inc.",
      "FAVOR ELECTRONICS",
      "FCI Basics (Amphenol Communications Solutions)",
      "FCT Electronics - Molex",
      "FDK America",
      "Feasycom",
      "Fechometal",
      "Fedco Batteries",
      "FEIG ELECTRONIC",
      "FEMA Electronics",
      "FERROXCUBE",
      "Festo",
      "Feztek",
      "Fiber Instrument Sales",
      "FiberFin",
      "FiberSource, Inc.",
      "FIBOX Enclosures",
      "Field Components",
      "Finder Relays, Inc.",
      "Finecables",
      "Fingerprint Cards AB",
      "Finisar (Coherent)",
      "FIRST CABLE LINE INC.",
      "FIT (Foxconn Interconnect Technology)",
      "Fix Supply",
      "Flamar - Molex",
      "Flambeau, Inc.",
      "Flashforge USA",
      "Flex Power Modules",
      "FLEXEMATIC",
      "Flexpoint Sensor Systems",
      "FLEXWAVE",
      "Flexxon",
      "Flip Electronics",
      "FLIR",
      "FLIR (Teledyne FLIR)",
      "FLIR Extech",
      "FLIR Integrated Imaging Solutions",
      "Floyd Bell, Inc.",
      "Fluke Electronics",
      "Fluke Networks",
      "Flusso",
      "Focus LCDs",
      "Forlinx Embedded",
      "Form Charge",
      "Formerica Optoelectronics Inc.",
      "Fotofab",
      "Foundation Devices",
      "Fox Electronics",
      "Foxconn OE Technologies",
      "Fractus Antennas (Ignion)",
      "FRÄNKISCHE INDUSTRIAL PIPES",
      "FRÄNKISCHE USA",
      "Free2move",
      "FreePoint Technologies",
      "Freescale / NXP Semiconductors",
      "Freescale Semiconductor, Inc. (NXP Semiconductors)",
      "FreeWave Technologies, Inc.",
      "Fremont Micro Devices",
      "FRESH DIGIT",
      "FRESNELFACTORY",
      "FRIWO Gerätebau GmbH",
      "Frontier Electronics",
      "FSP Technology",
      "FTDI (Future Technology Devices International, Ltd.)",
      "FTS",
      "Fujikura",
      "Fujitsu Semiconductor Memory Solution",
      "FULLSTEM",
      "Fumex",
      "Furuno (Kaga Electronics USA)",
      "Fuspower",
      "Futaba",
      "Future",
      "Future Designs, Inc.",
      "Future Technology Devices International, Ltd.",
    ],
  },
  {
    key: "G",
    value: [
      "G&H Photonics",
      "Gaia Converter",
      "Gain ICs",
      "Galco Industrial Electronics",
      "Gamma Electronics",
      "Ganmar Technologies",
      "GaNPower",
      "GAPTEC Electronic",
      "GardTec",
      "Garmin Canada Inc.",
      "Gas Sensing Solutions Ltd",
      "Gateworks Corporation",
      "GC Electronics",
      "GCT",
      "GE Aerospace",
      "GE Critical Power (OmniOn Power)",
      "Gearbox Labs",
      "Geckodrive",
      "GEDORE Tools, Inc.",
      "GEEHY SEMICONDUCTOR",
      "GELID SOLUTIONS",
      "Gelmec",
      "Gemalto (Telit)",
      "Gemalto (Thales DIS)",
      "Genasun by Sunforge LLC",
      "General Cable (Prysmian Group)",
      "General Electronic Devices",
      "General Semiconductor (Vishay)",
      "GeneSiC Semiconductor",
      "Genicom",
      "Genuino (Arduino)",
      "GES High Voltage",
      "GETT.AMERICA",
      "Geyer Electronic America, Inc.",
      "GHI Electronics, LLC",
      "GI FAR Technology",
      "GigaDevice",
      "Gigavac / Sensata Technologies",
      "GLF Integrated Power",
      "Global Connector Technology, Limited (GCT)",
      "Global Power Technology",
      "Global Specialties",
      "Global Telecom",
      "Globalscale",
      "GlobalTech Display",
      "GlobTek, Inc.",
      "GMW Associates",
      "Go Simply Connect",
      "Goermicro (Goertek Microelectronics)",
      "Goertek Microelectronics",
      "Goford Semiconductor",
      "Golledge Electronics",
      "Good Ark Semiconductor",
      "GotRad",
      "Gowanda",
      "GP Systems GmbH",
      "GPS of Things, Inc.",
      "GracoRoberts",
      "GradConn",
      "Graves Electronics",
      "Grayhill, Inc.",
      "Green Ignite Inc.",
      "Greenconn Technology",
      "Greenlee Communications",
      "Grepow Inc.",
      "Gridwiz",
      "GroundStudio",
      "Gruber Industries",
      "GSI Technology",
      "GSPK Circuits",
      "GWConnect - Molex",
    ],
  },
  {
    key: "H",
    value: [
      "H & H Innovative Concepts",
      "H&D Wireless",
      "H&H Industrial Products",
      "H&L Manufacturing",
      "H.G. Schaevitz, LLC / Alliance Sensors Group",
      "Hacksmith Tools",
      "Hagiwara Solutions",
      "Hakko",
      "Hallsten Innovations",
      "Ham Radio Workbench",
      "Hamlin / Littelfuse",
      "Hammond Manufacturing",
      "HangJing (Suzhou HangJing Electronic Technology)",
      "Hantronix",
      "Hapco",
      "Harimatec",
      "Harmony Electronics",
      "HARP",
      "HARTING",
      "Hartland Controls / Littelfuse",
      "Harvatek Corporation",
      "Harwin",
      "Hasco Relays",
      "Hatch Lighting",
      "HAUBER Elektronik",
      "Hayata",
      "HBTE",
      "HDP Power",
      "Headway Trading",
      "Heatron",
      "Hefei Jingweite Electronics",
      "Helium",
      "Helix Linear",
      "Helix Semiconductors",
      "HellermannTyton",
      "HELUKABEL",
      "Henkel / Bergquist",
      "Henkel/Loctite",
      "HenLv Power",
      "Henrich Electronics Corporation",
      "Heraeus Nexensos (YAGEO Nexensos)",
      "Heyco Products",
      "High Speed Backplane Products (Amphenol Communications Solutions)",
      "High Speed IO (Amphenol Communications Solutions)",
      "Highpower Security Products LLC",
      "High-Tech Conversions",
      "Hillcrest Labs (CEVA)",
      "HI-LO Systems",
      "Himax Technologies",
      "HiRel Systems / Vishay",
      "Hirose Electric Co., Ltd.",
      "Hirschmann",
      "Hirst Magnetic Instruments",
      "Hitec Commercial Solutions LLC",
      "HiTek (EXCELSYS)",
      "Hittite (Analog Devices)",
      "HKC",
      "HMS Networks",
      "Hoffman",
      "Hoffmann Group",
      "Hologram",
      "Holsworthy Resistors / TE Connectivity",
      "Holt Integrated Circuits, Inc.",
      "Holy Stone Enterprise",
      "Homaxi",
      "HOMEVISION TECHNOLOGY INC.",
      "Honeywell Aerospace",
      "Honeywell Sensing and Productivity Solutions",
      "Hong Kong X’tals Limited (HKC)",
      "Horizon Plus Electronics",
      "Horus Scope",
      "Hosonic Electronic",
      "HPC Optics",
      "HT Micron",
      "Hubbell Lighting Components (Current Lighting)",
      "Huber+Suhner",
      "Hunan Aihua Group (Aishi Capacitors)",
      "Hunan Bynav Technology",
      "HVM Technology, Inc.",
      "HY Electronic (Cayman) Limited",
      "Hyperline",
      "HYPERSPACE",
      "Hyperspec AI",
      "Hyrobotics",
    ],
  },
  {
    key: "I",
    value: [
      "I W Tremont",
      "I.F. Engineering Corp. and CrossPoint Technologies",
      "I/O Interconnect",
      "IAC Industries",
      "IAR Systems Software Inc",
      "iBASE Technology",
      "IBOCO",
      "IC3D Printers",
      "ICCNexergy (Inventus Power)",
      "IceMOS Technology",
      "ICI (Infrared Cameras Inc.)",
      "ICOMTECH, INC.",
      "ICOWORKS",
      "ICP DAS USA Inc.",
      "ICS (Intrepid Control Systems)",
      "ICY DOCK USA",
      "ID Connection",
      "Ideal Power",
      "Ideal-tek",
      "IDEC",
      "IDT (Renesas Electronics Corporation)",
      "iEi Technology",
      "IESY",
      "iFixit",
      "ifm Efector",
      "Ignion",
      "Igus",
      "II-VI (Coherent)",
      "II-VI Marlow (Coherent Thermal Solutions)",
      "Ikalogic",
      "iKey, Ltd.",
      "Illinois Capacitor (Cornell Dubilier Knowles)",
      "ILLUMRA",
      "ILSI",
      "ILUMINAR Lighting",
      "Image Industries",
      "Image Quality Labs",
      "Images SI, Inc.",
      "Imagination Supply Co",
      "iMD",
      "Imperix",
      "IMS Connector Systems",
      "InchMetric Tool Company",
      "indie Semiconductor FFO GmbH",
      "Industrial Fiber Optics, Inc.",
      "Industrial Magnetics, Inc.",
      "Industrial Safety Controls, Inc.",
      "Industrial Shields",
      "Industrial Video & Control",
      "IndustrialeMart",
      "IndustrialSupplies.com",
      "INEED MOTOR",
      "Infineon Technologies",
      "INFINITY FIBER",
      "Infraeo",
      "Infrared Cameras Inc.",
      "Initial State Technologies, Inc.",
      "Inno Instrument",
      "Innodisk",
      "INNOSCOPES",
      "InnoSenT GmbH",
      "Innovasic Semiconductor / Analog Devices, Inc.",
      "Innovative Microscopes (INNOSCOPES)",
      "Innovative Sensor Technology",
      "Innovisionsemi",
      "Inolux",
      "INPAQ Technology Co., Ltd.",
      "Inpixon",
      "InPlay",
      "In-Position Technologies",
      "InPower",
      "iNRCORE",
      "Insight SiP",
      "Insignis Technology Corporation",
      "INSIZE",
      "Inspired Energy",
      "Inspired LED",
      "Integra Enclosures",
      "Integra Optics an Infinite Electronics Brand",
      "Integra Technologies",
      "Integrated Device Technology (IDT)",
      "Integrated Device Technology (Renesas Electronics Corporation)",
      "Integrated Power Designs",
      "Integrated Silicon Solution, Inc. (ISSI)",
      "Intel",
      "Inteliheat North America",
      "INTELLIGENT MEMORY LTD.",
      "Intellinet Network Solutions",
      "Intematix",
      "InterCon Systems (Amphenol Communications Solutions)",
      "Interconnect Systems - Molex",
      "InterFET",
      "Interlink Electronics",
      "International Components Corp.",
      "International Power DC Power Supplies",
      "International Rectifier (Infineon Technologies)",
      "International Test Instruments Corporation",
      "Interpower",
      "Intersil (Renesas Electronics Corporation)",
      "Intrepid Control Systems",
      "Introspect Technology",
      "Intuicom",
      "Intwine Connect LLC",
      "InvenSense/TDK",
      "Inventchip Technology",
      "Inventek Systems",
      "Inventus Power",
      "Io Audio Technologies",
      "IONA Tech",
      "IOT747",
      "IOT-BOTS.COM",
      "IoThink Solutions",
      "IoTize",
      "Iowa Scaled Engineering",
      "IP Displays",
      "I-PEX",
      "iP-Solutions",
      "IQD Frequency Products",
      "IQnexus",
      "IR (Infineon Technologies)",
      "IRISO Electronics",
      "Ironwood Electronics",
      "IRTOUCH Systems Co., Ltd.",
      "IRTronix",
      "Isabellenhütte",
      "ISC",
      "Isel USA",
      "ISL Products International",
      "Isocom Components",
      "ISSI (Integrated Silicon Solution, Inc.)",
      "IST (Innovative Sensor Technology)",
      "itbrainpower.net/R&D Software Solutions",
      "ITECH ELECTRONIC",
      "Iteco",
      "ITG Electronics, Inc.",
      "ITT Cannon",
      "ITT Interconnect Solutions (ITT Cannon)",
      "ITW Chemtronics (Chemtronics)",
      "ITW LINX",
      "iVativ Inc.",
      "IVC Displays, Inc.",
      "iWave Systems",
      "IXYS / Littelfuse",
      "IXYS Integrated Circuits Division",
      "IXYS RF",
    ],
  },
  {
    key: "J",
    value: [
      "J.W. Miller / Bourns",
      "J.W. Winco",
      "Jabil",
      "Jabil Cutting Tools",
      "Jackson Control",
      "Jacob",
      "JAE Electronics",
      "JAM",
      "JAM (Japan Automatic Machine)",
      "Jameson LLC",
      "Janesville Tool & Mfg. Inc.",
      "Janus Remote Communications",
      "Jasper Electronics",
      "JATTA",
      "Jauch Quartz",
      "JAYASHREE",
      "JBC TOOLS USA INC.",
      "JBL Products, Inc.",
      "JC Antenna",
      "Jensen Global Inc.",
      "JESCO Lighting Group",
      "JHFoster",
      "JIE YI ELECTRONICS LIMITED",
      "Jinlong Machinery (Vybronics)",
      "JKL Components Corporation",
      "Johanson Dielectrics, Inc.",
      "Johanson Manufacturing",
      "Johanson Technology",
      "John Henry Foster (JHFoster)",
      "Johnson / Cinch Connectivity Solutions",
      "Johnson Electric - Ledex Dormeyer Saia",
      "Johnson Motor",
      "Jonard Tools",
      "Jones Tech",
      "Jorjin",
      "Joulescope",
      "JRC (Nisshinbo Micro Devices)",
      "JRH Electronics",
      "JS SWITCH",
      "JST",
      "Judco Manufacturing, Inc.",
      "JULABO",
      "JUZHENINTL",
    ],
  },
  {
    key: "K",
    value: [
      "Kable Kontrol",
      "Kaga Electronics USA",
      "Kairos82nd",
      "Kamaya",
      "Kang Yang International",
      "Kavlico Pressure Sensors / Sensata Technologies",
      "KB Covers & Keyboards",
      "KDG INDUSTRIAL",
      "KDS America",
      "Keene Village Plastics",
      "Keil (ARM)",
      "Keller America Inc.",
      "Kelly Pneumatics",
      "KEMET",
      "Kemtron / TE Connectivity",
      "Kenco Label & Tag",
      "Kendall Howard",
      "Kennedy Labs",
      "Kentzy International",
      "Kepco Power",
      "Kester",
      "Keystone Electronics Corp.",
      "KF Control",
      "Khadas",
      "Khatod",
      "Kickstart Design",
      "Kilo International",
      "Kilovac Relays / TE Connectivity",
      "Kinetic Technologies",
      "Kingbright",
      "Kingfisher International",
      "Kings (Winchester Electronics)",
      "Kingston",
      "KinnexA",
      "Kionix",
      "Kioxia America, Inc.",
      "Kitronik",
      "Klein Tools",
      "Knick Interface",
      "KNIPEX Tools",
      "knitter-switch",
      "Knowles",
      "Knowles DLI",
      "Knowles Integrated Microwave",
      "Knowles Johanson Manufacturing",
      "Knowles NOVACAP",
      "Knowles Precision",
      "Knowles Syfer",
      "Knowles Voltronics",
      "KOA Speer Electronics, Inc.",
      "Koehlke Components",
      "KOINO",
      "KOLT",
      "Konnect RF",
      "KORATECH",
      "KREEMO",
      "Krohn-Hite Corporation",
      "KSLD (Kyocera SLD Laser)",
      "Kübler, Inc.",
      "KUKA Robotics",
      "KUNBUS GmbH",
      "KUROKESU",
      "Kurtz Ersa, Inc.",
      "Kycon",
      "KYOCERA AVX",
      "KYOCERA Corporation",
      "Kyocera Display",
      "Kyocera SLD Laser",
    ],
  },
  {
    key: "L",
    value: [
      "LABELMATE USA",
      "LabJack",
      "LabsLand",
      "LabTech",
      "LadyBug Technologies LLC",
      "Laird - Performance Materials",
      "Laird Connectivity (LSR)",
      "Laird Thermal Systems",
      "Lantronix",
      "LAPIS Technology",
      "LAPP",
      "Lascar Electronics",
      "Laser Technology, Inc.",
      "Lasertack GmbH",
      "Lattice Semiconductor",
      "L-com",
      "LEA NETWORKS",
      "Lead Year Enterprise",
      "LEADER SOLAR CABLE AND CONNECTOR",
      "Leader Tech Inc.",
      "Leapcraft",
      "LECIP",
      "LeCroy (Teledyne LeCroy)",
      "LED Engin (ams OSRAM)",
      "LED Lighting Inc.",
      "LEDdynamics, Inc.",
      "Ledex Dormeyer Saia",
      "LEDiL",
      "LEDQuant",
      "LEM USA, Inc.",
      "LEMO",
      "Leopard Imaging Inc.",
      "Lepton (Teledyne FLIR)",
      "Leuze",
      "LEX System",
      "Libre Wireless Technologies",
      "LICAP Technologies",
      "Lighthorse Technologies Inc.",
      "Lighting Science",
      "LightWare LiDAR",
      "Lilliput Electronics USA",
      "Lime Microsystems",
      "Limited (Amphenol Pcd)",
      "LIMITLESS SHIELDING LIMITED",
      "Lin Engineering",
      "Lincoln Technology Solutions",
      "Linear Integrated Systems, Inc.",
      "Linear Technology (Analog Devices, Inc.)",
      "Link Labs",
      "Linortek",
      "Linrose Electronics, Inc.",
      "Linx Technologies (TE Connectivity Linx)",
      "LITEON",
      "Littelfuse",
      "Littelfuse / C&K",
      "Littelfuse / C&K Aerospace",
      "Littelfuse / Carling Technologies",
      "Littelfuse / Commercial Vehicle Products",
      "Littelfuse / Hartland Controls",
      "Littelfuse / IXYS",
      "Littelfuse / IXYS Integrated Circuits Division",
      "Littelfuse / IXYS RF",
      "Littelfuse / Western Automation",
      "LM Technologies",
      "LMB Heeger, Inc.",
      "LNL 3D Solutions",
      "Load Controls",
      "Loadstar Sensors",
      "LOCTITE / Henkel",
      "Lodestone Pacific",
      "LoggerFlex",
      "Logic PD (Beacon EmbeddedWorks)",
      "Logical Systems",
      "LogiSwitch",
      "LogTag",
      "Loop Slooth (Circuit Insights)",
      "LORSER INDUSTRIES, INC.",
      "LOTES",
      "Lotus Microsystems",
      "LOW PIM RF/Mobiyu Corporation",
      "LPD TRADE INC",
      "LP-RESEARCH Inc.",
      "LSI/CSI",
      "LTF Technology",
      "LTI (Laser Technology, Inc.)",
      "LTW (Amphenol LTW)",
      "Luckylight Electronics",
      "LulzBot",
      "Lumberg Automation",
      "Lumberg INC",
      "Lumenite Control Technology, Inc.",
      "Lumex, Inc.",
      "Lumileds",
      "Lumimax Optoelectronics Technology",
      "Luminary Micro/Texas Instruments",
      "Lumino",
      "Luminus Devices",
      "Lumissil Microsystems",
      "Luna Optoelectronics (Advanced Photonix)",
      "Luxmux Technology Corp.",
      "LUXTECH",
      "LX Microscopes by UNITRON",
      "Lynn Products",
      "Lyn-Tron",
    ],
  },
  {
    key: "M",
    value: [
      "M+A Matting",
      "M2M Technologies (IQnexus)",
      "M5Stack",
      "Machinechat",
      "MACOM Technology Solutions",
      "Macraigor Systems LLC",
      "Macromatic Industrial Controls",
      "Macronix",
      "Made Systems",
      "MadgeTech",
      "Maestro Wireless Solutions (Lantronix)",
      "Mag Daddy",
      "Mag Layers",
      "Magellan Distribution",
      "Magic Power",
      "MagicDAQ",
      "Magigoo (Thought3D)",
      "Mag-LED Solutions",
      "Magna-Power Electronics",
      "Magnasphere Corp.",
      "MagneLink",
      "Magnetic Shield Corporation",
      "Magnetics",
      "Magnet-Schultz of America",
      "Magnitude Lighting",
      "MAGTECH INDUSTRIES CORP.",
      "Major League Electronics",
      "Makeblock",
      "Makeblock (xTool)",
      "MakerChamber",
      "Makermotor",
      "MAKESafe Tools, Inc.",
      "Malico",
      "Mallory Sonalert Products",
      "Man & Machine Inc.",
      "Manyue",
      "Maple Systems",
      "Mark-10",
      "Marktech Optoelectronics",
      "Marlin Technologies",
      "Marlow Industries (Coherent Thermal Solutions)",
      "Marshall DC Lighting",
      "Marutsuelec",
      "Marvell Semiconductor, Inc.",
      "Marway Power Solutions",
      "Masach",
      "Master Appliance Corp.",
      "Maswell (Suzhou Maswell Communication Technology)",
      "MATRIX Industries",
      "Matrix Orbital",
      "Matrix Technology Inc.",
      "Matrix TSL",
      "Matsuo Electric",
      "Maudlin Products",
      "Maury Microwave",
      "Max Control Panels",
      "Max Echo",
      "MAX USA CORP.",
      "MaxBotix Inc.",
      "Maxim Integrated / Analog Devices",
      "MaxLinear",
      "MAXSA Innovations",
      "MaxStream (Digi International)",
      "Maxtena",
      "MAXVISION Technology",
      "Maxwell Technologies",
      "MCB Electronics",
      "MCB Industrie/Vishay",
      "McGill Microwave Systems",
      "McGraw-Hill Education",
      "mCube",
      "MDA CONTROLS INC.",
      "MDE Semiconductor Inc.",
      "MDH Technology",
      "MEAN WELL",
      "Measurement Computing Corporation",
      "Measurement Specialties / TE Connectivity",
      "MEC switches",
      "Mechanical Products",
      "Mechatronics",
      "Mechatronics Bearing Group",
      "MEDs Interconnect",
      "Meftronics",
      "MegaChips",
      "Melexis",
      "MELTRIC",
      "memetis",
      "Memory Protection Devices",
      "Memotronics",
      "MEMSIC",
      "Menda/EasyBraid/Tronex",
      "Mensor",
      "Mentech Technology USA Inc.",
      "Mercku",
      "Mercury United Electronics",
      "Meritek",
      "Mersen",
      "Mesan (Essentra Access Solutions)",
      "Metelics (MACOM Technology Solutions)",
      "Metis Engineering",
      "METZ CONNECT",
      "Mezzanine Products (Amphenol Communications Solutions)",
      "MG Chemicals",
      "MGS ELECTRONICA",
      "MH Connectors",
      "MHW International Corporation",
      "Micrel (Microchip Technology)",
      "Micrium",
      "Micro Commercial Components (MCC)",
      "Micro Connectors, Inc.",
      "Micro Crystal",
      "Micro Plastics, Inc. (Essentra Components)",
      "MICRO SENSOR CO., LTD.",
      "Micro/sys",
      "MicroCare",
      "Microchip Technology",
      "Microcom Technologies",
      "Microfire LLC",
      "Micro-Measurements / Vishay Precision Group",
      "Micron Technology",
      "Micronas / TDK",
      "Micronel USA",
      "Micronet",
      "MicroPhonon",
      "Micropower Battery Company",
      "MicroPower Direct",
      "Micropsi Industries",
      "Microsemi (Microchip Technology)",
      "Microtex Electronics",
      "Microwave Technology",
      "Mid America Taping and Reeling",
      "Mid Atlantic Rugged Systems",
      "Midas Displays",
      "Midas Touch",
      "Mide Technology",
      "Midwest Machine Tool Supply",
      "Midwest Microwave / Cinch Connectivity Solutions",
      "Midwest Motion Products",
      "MightyZAP",
      "MikroElektronika",
      "Miles Platts",
      "Milhardware",
      "Mill-Max",
      "Mills / Vishay",
      "Millspaw Electronics",
      "Milspecwest, LLC",
      "Milwaukee / Vishay",
      "Minebea Intec",
      "MinebeaMitsumi",
      "Ming Chiang",
      "Mini-Circuits",
      "MINIPRO",
      "Mirrorcle Technologies",
      "MISCO",
      "MitchElectronics",
      "MITEQ (Narda-MITEQ)",
      "mitex LLC",
      "Mitsubishi Materials U.S.A",
      "Mitsumi",
      "Mixtile-Focalcrest",
      "MM Newman Corporation",
      "MMB Networks",
      "MMD",
      "MMT Machrone",
      "Mobile Mark Antenna Solutions",
      "Mobiveil Technologies",
      "Mochiyoshi Engineering Development",
      "Moddable",
      "Moffatt Products",
      "Molex",
      "Molex Affinity Medical Technologies",
      "Molex Beau Interconnect",
      "Molex Bittware",
      "Molex Brad Harrison",
      "Molex Contrinex",
      "Molex FCT Electronics",
      "Molex Flamar",
      "Molex GWConnect",
      "Molex Interconnect Systems",
      "Molex Nallatech",
      "Molex Nistica",
      "Molex NuCurrent",
      "Molex Oplink Communications, LLC",
      "Molex Phillips-Medisize",
      "Molex PolyMicro Technologies",
      "Molex SensorCon",
      "Molex Temp-Flex",
      "Molex Triton Manufacturing",
      "Molex Woodhead",
      "Monitech Industrial Inc.",
      "Monnit",
      "MonoDAQ",
      "Monolithic Power Systems",
      "Monsoon Solutions Inc.",
      "Monsterrhino",
      "MONTECH (SHANGHAI)",
      "Monzite",
      "Mornsun",
      "Morpheus TEK",
      "Mosaic Manufacturing",
      "Moshi",
      "MOSLEADER",
      "Mosys (Peraso)",
      "Moujen",
      "Mount-It PRO!",
      "Movella",
      "Move-X",
      "MP",
      "MPD (Memory Protection Devices)",
      "MPS (Monolithic Power Systems)",
      "Mr. Chain/ M R Products",
      "MTE Solutions",
      "MtronPTI",
      "MU Research and Development Group (MURADG)",
      "Mueller Electric Co.",
      "Multi Products Company",
      "MULTIDIMENSION",
      "MultiLane",
      "Multi-Tech Systems, Inc.",
      "MURADG",
      "Murata Electronics",
      "Murata Power Solutions",
      "Murrplastik",
      "MYBOTSHOP GmbH",
      "myDevices",
      "MYIR Tech Limited",
    ],
  },
  {
    key: "N",
    value: [
      "N2 Power",
      "Nabil",
      "NAC Holdings Limited",
      "Naina Semiconductor Ltd.",
      "Nakagawa Manufacturing USA, Inc.",
      "Nallatech - Molex",
      "Nanmac",
      "Nano3DPrint/Carbon Design Innovations",
      "Nanosecond",
      "Nanotron (Inpixon)",
      "Nanova Environmental",
      "Narda-MITEQ",
      "National Control Devices",
      "National Instruments (NI)",
      "National Semiconductor/Texas Instruments",
      "NavePoint",
      "Navitas Semiconductor",
      "Navman Wireless (Telit)",
      "NDK",
      "Nearson",
      "neatMon, Inc.",
      "Neo Crucible",
      "NEO Messtechnik",
      "NeoCortec AS",
      "Neohm Resistors / TE Connectivity",
      "Neonode",
      "NEP Electronics",
      "NetBurner, Inc.",
      "Netlist Inc.",
      "NetPower",
      "Netsol",
      "New Age Enclosures",
      "New Boundary Technologies, Inc.",
      "New Energy",
      "New Japan Radio (Nisshinbo Micro Devices)",
      "New Scale Technologies",
      "NEW TRY",
      "Newava Technology",
      "Newhaven Display, Intl.",
      "Newland",
      "Newnex",
      "Nexa3D",
      "NexCOBOT CO., LTD.",
      "Nexperia",
      "Nextek",
      "Nextera Video",
      "NextGen Components",
      "Nextron",
      "NEXUS (Amphenol NEXUS Technologies)",
      "NI",
      "NIC Components",
      "Nichicon",
      "Nicomatic",
      "Nicslab Ops, Inc.",
      "Nidec Components",
      "Nidec Copal Electronics (Nidec Components)",
      "NightShade Electronics",
      "NightSwitch",
      "NIHM, LLC",
      "NimbeLink (Airgain)",
      "Nippon Chemi-Con",
      "Nippon Pulse America",
      "Nisshinbo Micro Devices",
      "Nistica - Molex",
      "NJR (Nisshinbo Micro Devices)",
      "NK Technologies",
      "NKK Switches",
      "NMB",
      "NOFFZ",
      "Nolato Jabar",
      "Nominal Controls",
      "Noratel",
      "NorComp",
      "Nordic Semiconductor",
      "Noren Thermal Solutions",
      "Noritake Co., Inc.",
      "North Atlantic Industries (NAI)",
      "North Slope Chillers",
      "North Star Sensors LLC",
      "Northern Mechatronics Inc.",
      "Northwest Scada",
      "NOSHOK",
      "NOVACAP",
      "NovaSensor (Amphenol Advanced Sensors)",
      "Novasom Industries",
      "Novelda",
      "Novus Automation",
      "Novus Power Products, LLC",
      "NPPower International",
      "NST (New Scale Technologies)",
      "NTE Electronics, Inc.",
      "NTS Direct",
      "NuCurrent - Molex",
      "Numato Lab",
      "NuPhotonics",
      "Nuventix (BOYD)",
      "Nuvoton Technology Corporation",
      "NuWaves RF Solutions",
      "NVE Corporation",
      "nVent",
      "nVent Birtcher",
      "nVent Calmark",
      "nVent Hoffman",
      "nVent Schroff",
      "NVT Phybridge",
      "NXP Semiconductors",
    ],
  },
  {
    key: "O",
    value: [
      "O.C. White Co.",
      "OCS",
      "Octavo Systems",
      "ODU",
      "OEG Relays / TE Connectivity",
      "Oeveo",
      "OHM Americas",
      "Ohmite",
      "OK Industries (Jonard Tools)",
      "OKdo",
      "OLC Inc.",
      "Ole Wolff Electronics Inc.",
      "Olimex",
      "Omnetics",
      "OmniOn Power",
      "OmniPreSense",
      "OmniVision Technologies",
      "Omron Automation",
      "Omron Electronic Components",
      "OMS Motion",
      "Ondrives.US Corp",
      "OneSimCard",
      "Onion Corporation",
      "onsemi",
      "On-Shore Technology, Inc.",
      "Oplink, a Molex company",
      "Opsero",
      "OptConnect",
      "Optek Technology/TT Electronics",
      "Optical Zonu Corporation",
      "OptiFuse",
      "Optiks Mechatronics Pvt. Ltd.",
      "Optilab",
      "Optilumen Inc.",
      "Optimal Filter Solutions",
      "Option NV",
      "Optix America",
      "Opto Diode Corporation",
      "Orbbec",
      "Orbel",
      "Orbital Mining Corporation",
      "O'Reilly Media, Inc.",
      "Orient",
      "Orient Display",
      "OriginGPS",
      "Orion Fans",
      "Oscium",
      "Osela",
      "OSENSA Innovations",
      "OSEPP Electronics",
      "Oshino Lamps",
      "OSI Optoelectronics",
      "OSRAM Opto Semiconductors (ams OSRAM)",
      "OTTE Tape",
      "Oupiin",
      "OVERCONN SRL",
      "OVERVIEW",
      "OWON Technology Lilliput Electronics",
      "Ox Tools",
      "Oyen Digital",
    ],
  },
  {
    key: "P",
    value: [
      "P1dB, Inc.",
      "PACE Worldwide",
      "Pacer Instruments",
      "Pacific Goal",
      "Pacific Microchip",
      "Packet Digital LLC",
      "PacTec",
      "Pactech",
      "Pakster",
      "Paladin Tools (Greenlee Communications)",
      "Palmer Wahl Instruments, Inc.",
      "PalmSens",
      "Panasonic",
      "PanaVise",
      "Panduit Corporation",
      "PANJIT",
      "Parallax, Inc.",
      "Parker Chomerics",
      "Parlex Corp.",
      "Particle",
      "Particles Plus",
      "Passive Plus",
      "Pasternack",
      "Patco Electronics",
      "Patco Services",
      "PCA Electronics, Inc.",
      "PCD / Amphenol",
      "PCE Instruments",
      "PCTEL",
      "P-DUKE Technology",
      "Peak Electronic Design Ltd",
      "Peerless by Tymphany",
      "PEI-Genesis",
      "Pelco Component Technologies",
      "Pelonis Technologies",
      "Penchem Technologies Sdn Bhd",
      "PENCOM",
      "Pennsville Components",
      "Penta Laboratories",
      "Peplink",
      "Pepperl+Fuchs",
      "Peraso",
      "Peregrine Semiconductor (pSemi)",
      "Performance Motion Devices",
      "Pericom Semiconductor Corp. (Diodes Incorporated)",
      "Perinet",
      "PermaSafe Surface and Air Sanitation Solutions",
      "Pervasive Displays",
      "Petoi / Bittle / Nybble",
      "PFLITSCH",
      "Phase Dock Inc.",
      "Phase Sensitive Innovations",
      "PHD Energy",
      "PHIHONG USA",
      "Philips (LUMILEDS)",
      "Phillips-Medisize - Molex",
      "Phoenix America",
      "Phoenix Contact",
      "Phoenix Mecano",
      "Phoenix Passive Components / Vishay",
      "Phoenix Sensors",
      "Photo Solutions, Inc.",
      "Photosound Technologies",
      "Phytec America",
      "Phyton, Inc.",
      "Phytools",
      "Pi Supply",
      "PIC GmbH",
      "Picker Components",
      "PicoBricks",
      "PicoLAS",
      "Piezo Electric Technology",
      "Piezo System Jena",
      "Piher Sensing Systems, an Amphenol company",
      "Pimoroni",
      "Piton Engineering",
      "pi-top",
      "Pixelink",
      "Plastronics Sockets & Connectors",
      "Platt Cases",
      "Playing With Fusion",
      "Plazmo Industries",
      "Pletronics, Inc",
      "PMDM",
      "PN Junction Semiconductor",
      "Pocket VNA",
      "Poem Technology, LLC",
      "Point Labs",
      "Pokit Innovations",
      "Pololu Corporation",
      "Polymaker",
      "Polymer Optics Ltd.",
      "PolyMicro Technologies - Molex",
      "polySpectra",
      "Polytech / Vishay",
      "Polytech / Vishay",
      "Pomona Electronics",
      "Pontiac Coil, Inc.",
      "Portescap",
      "Posifa Technologies",
      "POSITAL",
      "Positronic (Amphenol Positronic)",
      "Potter & Brumfield Relays / TE Connectivity",
      "Powell Electronics",
      "Power Components Sales LLC",
      "Power Connector",
      "Power Dynamics, Inc.",
      "Power Integrations",
      "Power Partners / TT Electronics",
      "Power Solutions (Amphenol Communications Solutions)",
      "Power Sonic",
      "Powerbox",
      "Powerbx Inc.",
      "Powercast",
      "Powerex, Inc.",
      "PowerField Energy",
      "PowerFilm Inc.",
      "Powerhouse Two",
      "Powerland",
      "Powerlet",
      "Power-One (Bel Power Solutions)",
      "PowerRESPONDER",
      "PowerSight",
      "PowerStor (Eaton)",
      "Powertip Technology Inc.",
      "Powertronics",
      "POWERWAREHOUSE",
      "Practical Instrument Electronics",
      "PRD Plastics",
      "PreAct Technologies",
      "Preci-Dip",
      "Precise Time and Frequency, LLC",
      "Precision Design Associates, Inc.",
      "Precision Electronics Corporation",
      "Precision PCBS",
      "Precision Resistive Products Inc.",
      "Precision Technology, Inc.",
      "Prem Magnetics",
      "Premier Magnetics",
      "Premium PSU",
      "Premo S.L.",
      "Primax Technologies",
      "Prinsta",
      "ProAnt",
      "Probes Unlimited, Inc.",
      "ProComSol, Ltd.",
      "Prodigy Technovations",
      "Prodrive Technologies",
      "Productech",
      "Products Unlimited Transformers & Relays / TE",
      "Connectivity",
      "Professional Cable",
      "Prohmtect",
      "ProLabs",
      "Prosperity Dielectrics",
      "Prostat",
      "ProTek Devices",
      "Protektive Pak",
      "Protoplant",
      "Proxxon",
      "Prysmian Group",
      "pSemi",
      "PSI Technics GmbH",
      "Psiber Data Systems, Inc.",
      "P-tec",
      "PTR HARTMANN",
      "PTS Technologies",
      "PTSolns",
      "PUI Audio, Inc.",
      "PULS",
      "Pulsar ",
      "Pulse Electronics",
      "Pulse Larsen Antenna",
      "PulseCore Semiconductor/onsemi",
      "PulseR (iNRCORE)",
      "Pulsiv Limited",
      "Purex Fume Extraction (BSE)",
      "PUSH PLASTIC",
      "PVC Pipeworks",
      "Pycom",
    ],
  },
  {
    key: "Q",
    value: [
      "Q-Cee's / TE Connectivity",
      "Qoitech",
      "Qorvo",
      "QSI",
      "QST",
      "QT Brightek",
      "Quadcept",
      "Qualcomm",
      "Qualcomm® RF Front-End (RFFE) filter products",
      "QualGear",
      "Quallink Technology Inc.",
      "Qualtek Electronics Corp.",
      "Qual-Tron, Inc.",
      "Quantic",
      "Quantic Croven",
      "Quantic Eulex",
      "Quantic Evans",
      "Quantic Paktron",
      "Quantic UTC",
      "Quantic Wenzel",
      "Quantum Devices",
      "Quantum Integration",
      "Quarton, Inc.",
      "Quatech / B+B SmartWorx",
      "Quectel",
      "Quelighting",
      "Quest Manufacturing",
      "Quest Semi",
      "Quest Technology International",
      "QuickLogic",
      "QuWireless",
      "QVS",
      "QwikProducts",
    ],
  },
  {
    key: "R",
    value: [
      "R&R Lotion",
      "RAB COMPONENTS INC.",
      "Rabbit Semiconductor (Digi)",
      "Radial Magnets, Inc.",
      "Radiall USA, Inc.",
      "Radian",
      "Radio Bridge Inc.",
      "RadioControlli",
      "Radiocrafts",
      "RAF",
      "RAFI",
      "Raise3D",
      "RAKwireless Technology",
      "Raltron",
      "Ranatec",
      "RangeANT",
      "Raspberry Pi",
      "Rathbun",
      "RATOC Systems",
      "Raychem Cable Protection/TE Connectivity",
      "Raytac",
      "Raztec Sensors",
      "RCD Components",
      "RCL Microwave",
      "RDI Inc.",
      "Re Mixers",
      "Reach Technology",
      "RECOM Power",
      "Rectron USA",
      "Red Lion",
      "Red Pitaya",
      "REDEL / LEMO",
      "RedWave Labs",
      "REED Instruments",
      "Reed Semiconductor Corp.",
      "Reed Switch Developments Corp.",
      "Rees, Inc.",
      "REGAL",
      "Reliance North America",
      "Remington Industries",
      "Rendeer Systems",
      "Renesas Electronics Corporation",
      "Renetec, Inc.",
      "Rennsteig Tools",
      "Reonix Automation",
      "Resensys",
      "RESI",
      "ReVibe Energy",
      "RevX Systems",
      "REYAX",
      "RF Industries",
      "RF Solutions",
      "RFbeam Microwave GmbH",
      "RFE/Fuzetec",
      "RFMD",
      "RFMi",
      "RFX Group",
      "RIA Connect / METZ CONNECT",
      "Richco, Inc. (Essentra Components)",
      "Richtek",
      "RICOH/Nisshinbo Micro Devices",
      "Riedon",
      "Rieker Inc.",
      "Rigado",
      "Rigol Japan",
      "RIGOL Technologies",
      "Rincon Power",
      "Riot Edge",
      "Rip-Tie",
      "Rise K12",
      "Rishabh Instruments",
      "Ritron Wireless Solutions",
      "RIVER/River Eletec Corporation",
      "Riverdi",
      "Rix Tools",
      "R-K Electronics, Inc.",
      "RLE Technologies",
      "RNA (Reliance North America)",
      "ROBOTIS",
      "RobotShop",
      "Roboworks/Roboworks Pty Ltd",
      "Rochester Electronics",
      "Rochester Sensors",
      "Rochester Sensors UK",
      "Roederstein / Vishay",
      "Rogan Corporation",
      "ROHM Semiconductor",
      "Roscid Technologies",
      "Rose Batteries",
      "Rose Enclosures",
      "Rose+Krieger",
      "Rosenberger",
      "Rotrics",
      "Roving Blue",
      "Roving Networks (Microchip Technology)",
      "RPM Systems",
      "Rubycon",
      "Ruffy Controls",
      "Ruland Manufacturing",
      "Runic Technology",
      "RushUp",
      "Russtech Engineering",
    ],
  },
  {
    key: "S",
    value: [
      "S.I. Tech, Inc.",
      "SAB North America",
      "Saelig Company Inc.",
      "SAF",
      "SAG",
      "Sagrad",
      "Sahara Case LLC",
      "Sahasra",
      "Saia (Division of Johnson Electric)",
      "Saiko Systems",
      "SainStore",
      "SALICRU USA LLC",
      "Samsung ARTIK",
      "Samsung Electro-Mechanics",
      "Samsung Semiconductor",
      "Samtec, Inc.",
      "Sangdest Microelectronics / Nanjing (SMC Diode Solutions)",
      "Sanken Electric Co., Ltd.",
      "SanMotion Products (Sanyo Denki)",
      "Sanoxy",
      "SANREX",
      "Santek",
      "San-tron",
      "SanUPS Products (Sanyo Denki)",
      "Sanyo Denki",
      "Sanyo Denki SanMotion Products",
      "Sanyo Denki SanUPS Products",
      "Sanyo Semiconductor/onsemi",
      "Sanyou Relay",
      "Sapu",
      "Sarnikon",
      "SB Components",
      "SCANTEK - Sound & Vibration Measurement Solutions",
      "Schaffner EMC, Inc.",
      "Schmalz",
      "SchmalzTech, LLC",
      "SchmartBoard",
      "Schneider Electric",
      "Schrack Relays / TE Connectivity",
      "Schroff / nVent",
      "SCHUNK",
      "SCHURTER",
      "ScioSense",
      "SCS",
      "SD",
      "SDS Industries",
      "Sealcon",
      "Sealing Devices, Inc.",
      "Secure Cable Ties",
      "Seeed",
      "Seek Thermal",
      "Segger Microcontroller Systems",
      "Seiko Instruments, Inc.",
      "SEIP (Sumitomo Electric Interconnect Products)",
      "Selco Products",
      "Selec Controls",
      "Sellcorp Services",
      "Seltech",
      "Semflex / Cinch Connectivity Solutions",
      "Semiconductor Circuits, Inc.",
      "Seminole Wire & Cable",
      "SemiQ",
      "Semireg Industries",
      "Semitec",
      "Semtech",
      "Senasys",
      "Senix",
      "Sensaphone",
      "Sensata Pressure & Temperature Sensors & Switches",
      "Sensata Technologies",
      "Sensata Technologies – Airpax",
      "Sensata Technologies – BEI Kimco",
      "Sensata Technologies – BEI Sensors",
      "Sensata Technologies – Crydom",
      "Sensata Technologies – Cynergy3",
      "Sensata Technologies – Deltatech",
      "Sensata Technologies – Gigavac",
      "Sensata Technologies – Kavlico Pressure Sensors",
      "SensComp",
      "Senseair",
      "SensiML",
      "Sensirion",
      "SensoPart",
      "Sensor Electronic Technology",
      "Sensorade",
      "SensorCon - Molex",
      "Sensorview America",
      "SENTHER",
      "Seoul Semiconductor",
      "Septentrio",
      "Sequans Communications",
      "Sequent Microsystems",
      "Seron Electronics",
      "Serpac Electronic Enclosures",
      "SETsafe | SETfuse",
      "Sevskiy GmbH",
      "SFERA LABS",
      "SG Wireless Limited",
      "SGX Sensortech (Amphenol SGX Sensortech)",
      "Shallco",
      "Sharp Microelectronics",
      "Shaxon",
      "Sheetak, Inc.",
      "SHENZHEN ANXINKE TECHNOLOGY",
      "Shenzhen City Beking Lighting",
      "Shenzhen Lakeview Electronics",
      "Shenzhen Refond Optoelectronics Electronics",
      "Shenzhen Sunlord Electronics",
      "SHENZHEN UNITENSE INNOVATION ELECTRONICS",
      "Shenzhen Yangxing Technology",
      "Shibaura Temperature Sensors",
      "Shiu Li Technology",
      "Shoreline IoT",
      "SIA Tek",
      "Siargo",
      "Siborg Systems Inc.",
      "SICK",
      "Siemens",
      "Sierra Electronics",
      "Sierra Wireless",
      "Sierra Wireless by Talon",
      "Sifam Tinsley Instrumentation, Inc.",
      "Sigfox",
      "Siglent Technologies",
      "Sigma Batteries",
      "Sigma Designs",
      "Sigma Inductors / TE Connectivity",
      "Signal Antenna Systems",
      "Signal Microwave",
      "Signal Processing Group Inc.",
      "Signal Transformer",
      "SIKA USA Inc.",
      "Silego Technology (Dialog Semiconductor)",
      "Silicon Craft Technology",
      "Silicon Designs",
      "Silicon Labs",
      "Silicon Motion",
      "Silicon Power",
      "Silicon Witchery",
      "Silver Fox",
      "SILVER PALM TECHNOLOGIES, LLC",
      "Silvertel",
      "Simco-Ion",
      "SIMCom Wireless Solutions Limited",
      "SimpleChips",
      "Simplex Motion",
      "Simplified MFG",
      "Simply NUC",
      "Simpson Electric",
      "SINBON",
      "Sine Control Technology",
      "SINE Systems / Amphenol",
      "Siretta",
      "SIRIO Antenne",
      "SIT INDEVA Inc.",
      "SiTime",
      "Siward",
      "Sixfab, Inc.",
      "Skeleton Technologies",
      "Skenos Technologies, Inc.",
      "SkyeTek",
      "Skyworks Solutions, Inc.",
      "SL POWER / Advanced Energy",
      "SLD Laser (Kyocera SLD Laser)",
      "Slice Engineering",
      "Smart Prototyping/NOA Labs",
      "Smart Sensor Devices",
      "Smartrek",
      "SMC Diode Solutions",
      "SMW",
      "SNAPLOC",
      "Snaptron",
      "Soberton, Inc.",
      "SOC Technologies (System-On-Chip Technologies)",
      "Socapex (Amphenol Pcd)",
      "Socle Technology Corporation",
      "SOHATECH",
      "SOIL",
      "SolaHD",
      "Solar Lighting International",
      "Solar Made",
      "Solar MEMS",
      "Soldered Electronics",
      "Solid State Inc.",
      "Solidlite",
      "SolidRun",
      "SolidSense Connect",
      "SOLLEC",
      "SOLO Motor Controllers",
      "Songtian Electronics",
      "Soracom",
      "SOSHIN ELECTRIC",
      "Sound Devices, LLC",
      "Souriau-Sunbank by Eaton",
      "Southwire Company",
      "Space Coast Electronics",
      "Spanalytics",
      "Spang Magnetics (Magnetics)",
      "Spansion (Cypress Semiconductor)",
      "SPARK Microsystems",
      "SparkFun",
      "Spartan Power",
      "Spec Sensors (A Division of Interlink Electronics)",
      "Spectra Symbol",
      "Spectral Engines",
      "Sper Scientific",
      "SPG USA, INC.",
      "SpotSee",
      "Springer Controls",
      "Spruce Science",
      "SRA Soldering Products",
      "Sruite Electronic Technology",
      "SSC Controls Company",
      "SSI Electronics, Inc.",
      "SSI Technologies, Inc.",
      "SST Sensing",
      "Stackpole Electronics, Inc.",
      "Staco Energy Products Co.",
      "Stage Ninja",
      "Stahlin",
      "Standard Electric Holdings",
      "Standex Electronics",
      "Stanley Electric",
      "Staple Pilot",
      "START International",
      "StarTech.com",
      "Static Clean",
      "Static Solutions, Inc.",
      "StaticStop",
      "Steel Guard Safety",
      "Steinel",
      "Stele Tech",
      "Stetron",
      "Steute",
      "Stewart Connector",
      "STI Vibration Monitoring",
      "STI-CO Industries",
      "STL",
      "STMicroelectronics",
      "Storage & Server IO (Amphenol Communications Solutions)",
      "Storm Interface",
      "Suco Technologies Inc.",
      "Sullins Connector Solutions",
      "Sumida Corporation",
      "Sumitomo",
      "Sumitomo Electric Interconnect Products (SEIP)",
      "Sump Alarm, Inc.",
      "Sun Innovations",
      "Sunfounder",
      "Sungale/SCPT Inc.",
      "Sungmun Electronics",
      "Sunhillo",
      "SunLED",
      "Sunlord (Shenzhen Sunlord Electronics)",
      "Sunon",
      "Sunpark",
      "Suntsu Electronics, Inc.",
      "SuperApex",
      "Superior Asphalt Concepts",
      "Superior Sensor Technology",
      "Superior Tech",
      "Superworld Electronics",
      "SuperXon",
      "Supirit Inc.",
      "Sure Controls, Inc.",
      "Sure Electronics",
      "SURGE",
      "SUSI Adapters",
      "Susumu",
      "SUZHOU HANGJING ELEC&TECH CO., LTD",
      "Suzhou Maswell Communication Technology Co. Ltd",
      "SV Microwave (Amphenol SV Microwave)",
      "Swanstrom Tools",
      "Swift Bridge Technologies",
      "Swissbit",
      "Switch Components",
      "Switchcraft / Conxall",
      "SYBA USA",
      "Synapse Wireless",
      "SynDaver",
      "Syndesy Technologies, Inc.",
      "Synergy",
      "Syntiant",
      "Synzen",
      "System-On-Chip Technologies",
    ],
  },
  {
    key: "T",
    value: [
      "T3D (Thought3D)",
      "Tabor Electronics Ltd.",
      "Tadiran Batteries",
      "TAEC Product (Toshiba Electronic Devices and Storage Corporation)",
      "TAEJIN",
      "Tag-Connect",
      "Tagore Technology",
      "Taica Corporation",
      "Tai-Saw Technology TST",
      "TAI-TECH",
      "Taitien",
      "Taiwan Chinsan Electronic",
      "Taiwan Semiconductor",
      "TAIYO YUDEN",
      "TAKEX",
      "Talema",
      "Tallysman Wireless",
      "Talon Meters",
      "Tamura",
      "TANE ALARM PRODUCTS",
      "Taoglas",
      "TAPCO",
      "TapeCase",
      "Tasco, Inc.",
      "TAW Electronics",
      "TBI Motion",
      "TCH Hardware",
      "TCI",
      "TDK Corporation",
      "TDK EPCOS",
      "TDK InvenSense",
      "TDK Micronas",
      "TDK Tronics (Tronics)",
      "TDK-Lambda",
      "TE Connectivity",
      "TE Connectivity Aerospace Defense and Marine",
      "TE Connectivity Agastat Relays",
      "TE Connectivity ALCOSWITCH Switches",
      "TE Connectivity AMP Connectors",
      "TE Connectivity Axicom Relays",
      "TE Connectivity Buchanan Terminal Blocks",
      "TE Connectivity CGS Resistors",
      "TE Connectivity CII",
      "TE Connectivity Corcom Filters",
      "TE Connectivity DEUTSCH Connectors",
      "TE Connectivity DEUTSCH INDUSTRIAL & COMMERCIAL",
      "TRANSPORTATION",
      "TE Connectivity Elcon Connectors",
      "TE Connectivity ERNI",
      "TE Connectivity Holsworthy Resistors",
      "TE Connectivity Kemtron",
      "TE Connectivity Kilovac Relays",
      "TE Connectivity Laird",
      "TE Connectivity Linx",
      "TE Connectivity Measurement Specialties",
      "TE Connectivity Neohm Resistors",
      "TE Connectivity OEG Relays",
      "TE Connectivity Potter & Brumfield Relays",
      "TE Connectivity Products Unlimited Transformers & Relays",
      "TE Connectivity Q-Cee's",
      "TE Connectivity Raychem Cable Protection",
      "TE Connectivity Raychem Circuit Protection / Littelfuse",
      "TE Connectivity Schrack Relays",
      "TE Connectivity Sigma Inductors",
      "TE Technology",
      "Tecate Group",
      "Teccor / Littelfuse",
      "Tecdia Inc.",
      "Techflex",
      "TechLogix Networx",
      "TechNexion",
      "Techno / Vishay",
      "TECHNO LED LIGHTS",
      "Techship",
      "Techspray",
      "TechTools",
      "Tecnomatic Corp",
      "Tegam",
      "Tekima",
      "Tekscan",
      "TEKTELIC Communications",
      "Tektos Design",
      "Tek-Trol",
      "Telaire (Amphenol Advanced Sensors)",
      "TELE Controls, Inc.",
      "Telebyte",
      "Teledyne FLIR",
      "Teledyne LeCroy",
      "Telegärtner",
      "Telemecanique Sensors",
      "Telit Cinterion",
      "Tell-i",
      "Teltonika",
      "Temp-Flex - Molex",
      "Tempo Communications",
      "Temprecord International",
      "Temprel",
      "Tenda Technologies",
      "Tensility International Corporation",
      "Tera Grand",
      "Terabee",
      "Terasic",
      "TESSERA TECHNOLOGY INC.",
      "Test Products International (TPI)",
      "Tesuto",
      "TEWA Sensors LLC",
      "TEXA USA",
      "Texas Instruments",
      "TEXAS MICROFIBER",
      "Texmate",
      "TextSpeak",
      "TF Semiconductor Solutions",
      "TFabWorks",
      "TG3 Electronics",
      "T-Global Technology",
      "Thales DIS (Formerly Gemalto)",
      "Thales DIS (Telit)",
      "Thales Visionix, Inc.",
      "The Fredericks Company",
      "The Imaging Source",
      "The Modal Shop - An Amphenol Company",
      "The Western Design Center Inc.",
      "Thermco Products, Inc.",
      "Thermo Heating Elements",
      "Thermoelectric Conversion Systems Ltd.",
      "Thermometrics (Amphenol Advanced Sensors)",
      "Thin Film Technology Corp.",
      "THine Solutions",
      "ThingMagic, a JADAK Brand",
      "Thinxtra Solutions Limited",
      "Thomas Research Products (Current Lighting)",
      "Thornton Plastics",
      "Thought3D",
      "Three-In-One Enterprises Co. Ltd.",
      "Tibbo",
      "TierraTek",
      "Tiger Power",
      "TimePilot",
      "Times Microwave Systems",
      "TinyCircuits",
      "TITAN Haptics",
      "TK USA",
      "TMB",
      "TMY Technology Inc.",
      "To-Conne Co., Ltd. (TYC)",
      "Tohnichi",
      "TOHO ELECTRONICS INC.",
      "TOKIN (KEMET)",
      "TOKO / Murata",
      "TokyLabs",
      "Top Shelf Acoustics",
      "Torex Semiconductor Ltd.",
      "Toshiba Electronic Devices and Storage Corporation",
      "Toshiba Memory America, Inc. (Kioxia America, Inc.)",
      "Touchstone Semiconductor",
      "TPC Wire & Cable",
      "TPI (Test Products International)",
      "TPK America LLC",
      "Trackimo",
      "TRACO Power",
      "Trafag Sensors and Controls",
      "Traktronix",
      "TransData, Inc.",
      "Transducers Direct",
      "Transforming Technologies",
      "Transition Automation",
      "Transko",
      "Transphorm",
      "Trek (EXCELSYS)",
      "Trenz Electronic",
      "Treston",
      "Triad Magnetics",
      "Triad Semiconductor",
      "Tridonic Inc.",
      "Tri-Mag, LLC",
      "TRINAMIC Motion Control GmbH",
      "Triplett Test Equipment and Tools",
      "Tripp Lite by Eaton",
      "Tri-Star Industries",
      "Triton Manufacturing - Molex",
      "Trompeter / Cinch Connectivity Solutions",
      "Tronex (Menda/EasyBraid/Tronex)",
      "Tronics",
      "TRP Connector",
      "Trumeter",
      "Trustcap Technology",
      "TSC (Taiwan Semiconductor)",
      "TSE / Tai-Shing Electronics",
      "TT Electronics",
      "TT Electronics / BI Technologies",
      "TT Electronics / Optek Technology",
      "TT Electronics / Power Partners",
      "TTM Technologies / Anaren Wireless",
      "Tuchel / Amphenol",
      "TurboFlex Heaters",
      "TurnOnGreen",
      "Twin Industries",
      "TXC Corporation",
      "Tyco Electronics",
      "Tycon Systems, Inc.",
      "Tymphany (Peerless by Tymphany)",
    ],
  },
  {
    key: "U",
    value: [
      "U.S. Sensor (Littelfuse)",
      "UbiBot",
      "u-blox",
      "UDINFO",
      "UDOO",
      "UEi Test Instruments",
      "uHave Control",
      "UJU",
      "Ultra Librarian®",
      "ULTRAVOLT / Advanced Energy",
      "UMW",
      "UNC GROUP",
      "Under Control Instruments",
      "UNEO",
      "Ungar / Weller (Apex Tool Group)",
      "Unictron",
      "Unigen",
      "UNION SEMICONDUCTOR INTERNATIONAL LIMITED",
      "United Chemi-Con",
      "UnitedSiC (Qorvo)",
      "Uni-Trend Technology",
      "UNIVERSAL-SOLDER Electronics",
      "UP - Bridge the Gap",
      "Upside Down Labs",
      "U-Reach Data Solutions, Inc.",
      "US Custom Billet",
      "US Digital",
      "US Electronics Inc.",
      "US-Lasers, Inc.",
      "USound GmbH",
      "UTC",
      "Uticor Technologies",
      "UV Resources",
      "UVCense",
      "Uvitron International",
    ],
  },
  {
    key: "V",
    value: [
      "VACUUMSCHMELZE GmbH & Co. KG.",
      "Vantron Technology",
      "VaOpto",
      "Varcode",
      "Variohm",
      "Varitronix International Ltd.",
      "VARTA",
      "VCC (Visual Communications Company)",
      "VDO360",
      "VEAM (ITT Cannon)",
      "VEC Supply",
      "Vector Climate",
      "Vector Electronics & Technology, Inc.",
      "Vedtronics",
      "Veinland GmbH",
      "Vektrex",
      "Velab Co.",
      "Velocity IoT",
      "Venatronics",
      "Venkel LTD",
      "Veridify Security",
      "Verivolt",
      "Verotronic Technologies",
      "Versa Technology",
      "VersaLogic Corporation",
      "VersaSense",
      "Versatile Power",
      "Vesper",
      "Vessel Tools",
      "Vicor",
      "Vicotee",
      "VIETES",
      "Vifa (Peerless by Tymphany)",
      "VigilLink",
      "Vigo Photonics",
      "Viking Tech",
      "Viking Technology",
      "VINA Tech",
      "Virtins Technology",
      "Virtium",
      "Virtual Industries, Inc.",
      "VISATON",
      "Vishay",
      "Vishay / Barry",
      "Vishay / Beyschlag / Draloric",
      "Vishay / Cera-Mite",
      "Vishay / Dale",
      "Vishay / ESTA",
      "Vishay / Huntington Electric, Inc.",
      "Vishay / Polytech",
      "Vishay / Sfernice",
      "Vishay / Siliconix",
      "Vishay / Spectrol",
      "Vishay / Sprague",
      "Vishay / Vitramon",
      "Vishay BC Components",
      "Vishay Dale / Thin Film",
      "Vishay Foil Resistors (VPG Foil Resistors)",
      "Vishay General Semiconductor – Diodes Division",
      "Vishay Semiconductor - Opto Division",
      "Vision Engineering",
      "Visionary Electronics",
      "Visual Communications Company, LLC",
      "Vitelec / Cinch Connectivity Solutions",
      "Vitrek",
      "Vitrohm",
      "VMS",
      "Volgen (Kaga Electronics USA)",
      "Voltaic Systems",
      "Voltronics (Knowles)",
      "Vortec",
      "Vox Power",
      "VPG Foil Resistors",
      "VPG Micro-Measurements",
      "VPG Sensors",
      "Vybronics",
      "Vzense",
    ],
  },
  {
    key: "W",
    value: [
      "WAGO",
      "Wakefield Thermal",
      "Walsin Technology",
      "Wamco, Inc.",
      "Wandboard",
      "Watterott electronic",
      "Wave.N",
      "WAVEPIA",
      "Waytronic",
      "WCI (World Class Illumination)",
      "WEC",
      "WECO Electrical Connectors",
      "WeEn Semiconductors Co., Ltd",
      "Weidmuller",
      "WEIPU Connector",
      "Weller (Apex Tool Group)",
      "WERMA",
      "Westermo Data Communications",
      "Western Automation / Littelfuse",
      "WetKeys® Washable Keyboards",
      "Whitman Controls",
      "wi2wi/Precision Devices",
      "Wickmann / Littelfuse",
      "Wiha",
      "WIKA",
      "Wilcoxon (Amphenol Wilcoxon Sensing Technologies)",
      "Wilton Tech",
      "Wiltronic",
      "WIMA",
      "Winbond Electronics Corporation",
      "Winchester Electronics",
      "Winstar Display",
      "Winstronics International",
      "WINSYSTEMS INC.",
      "Wintec Industries",
      "WipeOS",
      "Wise-Integration",
      "WISILICA INC.",
      "Wiss (Apex Tool Group)",
      "WiTagg, Inc.",
      "WITHWAVE CO LTD",
      "WIZnet",
      "Wolfspeed",
      "Woodhead - Molex",
      "worktables.com",
      "World Micro",
      "Würth Elektronik",
      "Würth Elektronik iBE",
      "Würth Elektronik ICS",
      "Würth Elektronik Midcom",
      "WyreStorm Technologies",
    ],
  },
  {
    key: "X",
    value: [
      "X Rocker & Ace Casual Furniture",
      "Xaptum",
      "Xavitech",
      "Xcelite (Apex Tool Group)",
      "XeelTech GmbH",
      "Xeltek",
      "Xenarc Technologies",
      "XetaWave",
      "XFMRS",
      "Xidas",
      "Xilinx (AMD)",
      "XiOptics",
      "XMOS",
      "XP Power",
      "Xsens (Movella)",
      "XSSY",
      "XtalTQ",
      "XTAR Technology Inc.",
      "xTool",
      "Xvisio Technology",
      "XYMTEC Industries",
    ],
  },
  {
    key: "Y",
    value: [
      "Y.C. Cable (East)",
      "YAGEO",
      "YAGEO Group",
      "YAGEO Nexensos",
      "YAGEO XSEMI",
      "Yamaichi Electronics",
      "Yamar Electronics Ltd.",
      "YANSEN",
      "Yen Sun",
      "YIC",
      "Ymin",
      "Yokowo",
      "Yonggui Electric",
      "YS TECH USA INC",
      "Yuasa Battery",
      "Yuji America",
      "YXC",
    ],
  },
  {
    key: "Z",
    value: [
      "Z+F USA Inc.",
      "Zaber Technologies",
      "ZCables",
      "Z-Communications",
      "Zentri (Silicon Labs)",
      "ZERO Connect",
      "Zeroplus",
      "Zetex Semiconductors (Diodes Inc.)",
      "Zettler Magnetics",
      "ZEUS Battery Products",
      "ZF Electronics",
      "Zilog / Littelfuse",
      "ZKTeco USA",
      "ZofzPCB",
      "ZPE Systems",
      "Zubax Robotics",
    ],
  },
];
export { Alphabet, Manufactures };
