import "../assets/Css/Styles/general.css";

import { MdError } from "react-icons/md";
import { AiFillWarning } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";

import { useContext } from "react";
import globalContext from "../Contexts/GlobalContext";

export default function AlertMessage({ bottom = 55, left = 260 }) {
  const contextData = useContext(globalContext);

  if (contextData.alertConfirmation == true) {
    return (
      <>
        <div
          style={{ bottom: `${bottom}px`, left: `${left}px` }}
          className={`absolute w-auto h-auto min-w-[300px] min-h-[90px] flex flex-col items-start justify-center gap-x-2 gap-y-1 p-3 font-FYekan text-[12px] border-[1px] border-[#3b67b8] text-[#002bb8] bg-blue-200 anim--FadeInLeft z-[1000]`}
        >
          <div className="flex gap-x-2">
            <MdError size={20} />
            <p>{contextData.alertMessageConfirmation}</p>
          </div>
          <div className="flex items-end justify-end w-full gap-x-2">
            <button
              className="px-4 py-1 border-[1px] Type_3 rounded-[8px]"
              onClick={() => {
                contextData.setAlertResultConfirmation(false);
                contextData.setAlertView(false);
              }}
            >
              خیر
            </button>
            <button
              className="px-4 py-1 border-[1px] Type_4 rounded-[8px]"
              onClick={() => {
                contextData.setAlertResultConfirmation(true);
                contextData.setAlertView(false);
              }}
            >
              بله
            </button>
          </div>
          <div
            style={{ animationDuration: `${contextData.alertDuration}ms` }}
            className="absolute bottom-0 right-0 bg-[#3b67b8] h-[3px] anim--FullWidth"
          ></div>
        </div>
      </>
    );
  } else {
    if (contextData.alertMode == "ERROR") {
      return (
        <>
          <div
            style={{ bottom: `${bottom}px`, rileftght: `${left}px` }}
            className={`absolute w-auto min-w-[350px] h-[50px] flex items-center justify-start gap-x-2 px-3 font-FYekan text-[12px] border-[1px] border-[#b83b3b] text-[#b80000] bg-red-200 anim--FadeInLeft z-[1000]`}
          >
            <MdError size={20} />
            <p>{contextData.alertMessage}</p>
            <div
              style={{ animationDuration: `${contextData.alertDuration}ms` }}
              className="absolute bottom-0 right-0 bg-[#b83b3b] h-[3px] anim--FullWidth"
            ></div>
          </div>
        </>
      );
    } else if (contextData.alertMode == "SUCCES") {
      return (
        <>
          <div
            style={{ bottom: `${bottom}px`, left: `${left}px` }}
            className={`absolute w-auto min-w-[350px] h-[50px] flex items-center justify-start gap-x-2 px-3 font-FYekan text-[12px] border-[1px] border-[#42e350] text-[#00b00f] bg-green-200 anim--FadeInLeft z-[1000]`}
          >
            <BsCheckCircleFill size={20} />
            <p>{contextData.alertMessage}</p>
            <div
              style={{
                animationDuration: `${contextData.alertDuration}ms`,
              }}
              className="absolute bottom-0 right-0 bg-[#42e350] h-[3px] anim--FullWidth"
            ></div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{ bottom: `${bottom}px`, left: `${left}px` }}
            className={`absolute w-auto min-w-[350px] h-[50px] flex items-center justify-start gap-x-2 px-3 font-FYekan text-[12px] border-[1px] border-[#ffdf52] text-[#baa204] bg-yellow-200 anim--FadeInLeft z-[1000]`}
          >
            <AiFillWarning size={20} />
            <p>{contextData.alertMessage}</p>
            <div
              style={{ animationDuration: `${contextData.alertDuration}ms` }}
              className="absolute bottom-0 right-0 bg-[#baa204] h-[3px] anim--FullWidth"
            ></div>
          </div>
        </>
      );
    }
  }
}
