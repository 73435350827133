import React from "react";

export default function Offline() {
  return (
    <div className="w-full h-screen top-0 left-0 fixed flex flex-col items-center justify-center bg-color-BG ">
      <img src="../assets/images/404.svg" className="w-[250px] h-[250px]" />
      <p className="w-full text-center text-[18px] text-color-TXT-dark mt-[16px]">
        Check your internet connection and Try Again
      </p>
      <div className="w-full flex items-center justify-center">
        <button
          className="bg-color-B text-color-BG  font-bold rounded-md px-[40px] py-[8px] mt-[32px]"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </button>
      </div>
    </div>
  );
}
