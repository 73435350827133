import React from "react";

export default function HomeBannerSlider({ bannerUrl, title1, title2, desc }) {
  return (
    <div className="relative w-full h-full  flex items-center justify-center">
      <img src={bannerUrl} className="w-full h-full " />
      <span className=" absolute  top-[25px] left-[25px] min-[1024px]:left-[50px] text-[12px] min-[768px]:text-[20px] text-color-BG-1 z-10">
        {title1}
      </span>
      <span className="absolute w-[100px] min-[768px]:w-[250px] text-start bottom-[155px] left-[25px] min-[1024px]:left-[50px] text-color-BG text-[18px] min-[768px]:text-[26px] font-bold ">
        {title2}
      </span>

      <span className="absolute w-[150px] min-[768px]:w-[250px] text-start bottom-[100px] left-[25px] min-[1024px]:left-[50px] text-color-BG text-[10px] min-[768px]:text-[14px]">
        {desc}
      </span>
    </div>
  );
}
