import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import globalContext from "../Contexts/GlobalContext";

export default function MHeader() {
  const navigate = useNavigate();
  const contextData = useContext(globalContext);
  return (
    <div className="min-[740px]:hidden sticky top-0 left-0 w-full h-[64px] bg-[#265B8B] flex items-center justify-start px-[20px] gap-x-[16px] z-[999]">
      <span
        className="text-[#ECECEB] text-[28px]"
        onClick={() => {
          contextData?.setIsMenuBarOpen("true");
        }}
      >
        <GiHamburgerMenu />
      </span>
      <div className="">
        <span
          className="text-[24px] font-bold  rounded-[5px] cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <span className="text-[#84C7F2]">IC World</span>
          <span className="text-[#ECECEB]"> Electronics</span>
        </span>
      </div>
    </div>
  );
}
