import "../assets/Css/Styles/general.css";
import "swiper/css";
import "swiper/css/pagination";
import React, { useContext, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaSearch } from "react-icons/fa";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import FeaturedProducts from "../components/FeaturedProducts";
import ScrollToFirst from "../components/ScrollToFirst";
import { Link, useNavigate } from "react-router-dom";
import HomeBannerSlider from "../components/HomeBannerSlider";
import axios from "axios";
import { SERVERMODE } from "../constants/serverMode";
import { AlertAlarm, handleServerMode } from "../Js/functions";
import globalContext from "../Contexts/GlobalContext";
import { useCookies } from "react-cookie";
import ReactLoading from "react-loading";

export default function Home() {
  const BASE_URL = process.env.REACT_APP_ROOT_API_ADDRESS;
  const Nav = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();
  const contextData = useContext(globalContext);
  const [partNumberSearchKey, setPartNumberSearchKey] = useState("");
  const [isSearchingPtn, setIsSearchingPtn] = useState(false);

  const productBannerLeft = [
    "Anti-Static, ESD, Clean Room",
    "Audio Products",
    "Battery Products",
    "Cable Assemblies",
    "Capacitors",
    "Computer Equipment",
    "Connectors, Interconnects",
    "Kits",
    "Integrated Circuits (ICs)",
    "Sensors, Transducers",
    "Switches",
    "Test and Measurement",
    "Circuit Protection",
  ];

  const searchPartNumber = (searchValue) => {
    if (searchValue.length >= 3) {
      setIsSearchingPtn(true);
      var reqBody = {
        ic: `${btoa(`1:25`).replaceAll("=", "")}`,
        searchKey: searchValue,
      };
      axios
        .post(`${BASE_URL}/product/ptn`, reqBody)
        .then((res) => {
          var responseMessage = res?.data?.message;
          var responseMode = res?.data?.mode;
          if (responseMode === SERVERMODE.REQUEST_OK) {
            Nav(
              `/product?ptn=${searchValue}&ic=${btoa(`1:25`).replaceAll(
                "=",
                ""
              )}`
            );
            setIsSearchingPtn(false);
          } else {
            handleServerMode(
              responseMode,
              responseMessage,
              contextData,
              cookies,
              removeCookies,
              Nav
            );
            setIsSearchingPtn(false);
          }
        })
        .catch((err) => {
          var errMessage = err?.response?.data?.message;
          if (errMessage?.length > 5) {
            AlertAlarm(contextData, errMessage, "ERROR", false, "");
          } else {
            AlertAlarm(contextData, "Something Went Wrong", "ERROR", false, "");
          }

          setIsSearchingPtn(false);
        });
    }
  };
  return (
    <>
      <Header />
      <div className="relative w-full h-full  flex flex-col justify-start items-center bg-color-BG">
        <div className="relative w-full h-[60px] min-[768px]:h-[120px] flex justify-center items-center bg-color-A z-20">
          <div className="hidden min-[740px]:block absolute h-[3px] w-full bg-color-BG-1"></div>
          <div className="relative  w-full h-full pb-[20px] min-[768px]:pb-0 px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 flex justify-between items-center  z-10 text-[16px] font-medium text-color-TXT-1">
            <div className="hidden min-[740px]:block w-[150px] text-[20px] font-bold">
              SEARCH PRODUCTS
            </div>
            <div className="h-[40px] w-full flex justify-center items-center rounded-lg border-color-BG-1 border-[3px] bg-color-A">
              <input
                placeholder="Enter keyword or part number ..."
                value={partNumberSearchKey}
                onChange={(e) => {
                  setPartNumberSearchKey(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") searchPartNumber(partNumberSearchKey);
                }}
                className="flex-1 h-full border-color-Dark bg-transparent pl-4 outline-none pb-[3px] text-color-TXT shadow-sm placeholder:text-[12px]"
              />
              <button
                disabled={isSearchingPtn || partNumberSearchKey.length < 3}
                className={`w-[60px] min-w-[740px]:w-[150px] h-full transition-colors rounded-r-[5px] ${
                  isSearchingPtn || partNumberSearchKey.length < 3
                    ? "bg-color-BG-1"
                    : "bg-color-BG-1 hover:bg-color-BG"
                } text-color-A flex items-center justify-center `}
                onClick={() => {
                  searchPartNumber(partNumberSearchKey);
                }}
              >
                {isSearchingPtn ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#FFF"}
                    height={20}
                    width={20}
                  />
                ) : (
                  <FaSearch />
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="relative max-w-[1200px] w-full h-full flex flex-col justify-between items-start m-[24px] px-[20px] min-[1240px]:px-0 z-10">
          <div className="w-full  flex flex-col items-center justify-between gap-y-[24px]  min-[740px]:flex  min-[740px]:flex-row min-[740px]:items-start min-[740px]:justify-between  gap-x-[24px] ">
            <div className="w-full h-[350px] min-[740px]:w-[250px] min-[740px]:h-[500px] bg-white shadow-sm flex flex-col items-center justify-between rounded-[5px] text-color-TXT-dark font-semibold text-[13px] px-[20px] py-[4px]">
              <span className="w-full text-[20px] text-left font-bold text-color-A">
                PRODUCTS
              </span>
              <div className="w-full flex-1 flex flex-col items-center justify-between my-[16px]">
                {productBannerLeft.map((item) => (
                  <Link
                    className="w-full text-left cursor-pointer hover:text-color-A transition ease-in-out hover:translate-x-2"
                    to={`/products?productSearch=${item}`}
                  >
                    <span className="w-full ">{item}</span>
                  </Link>
                ))}
              </div>

              <button
                className="w-full h-[32px] bg-color-B mb-[8px] rounded-md  text-color-BG font-bold Type_4"
                onClick={() => {
                  Nav("/products");
                }}
              >
                View All
              </button>
            </div>
            <div className="w-full h-[350px] min-[740px]:w-[calc(100%-250px)] min-[740px]:h-[500px]  shadow-sm rounded-[5px]">
              <Swiper
                Pagination={true}
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper rounded-[5px]"
              >
                <SwiperSlide>
                  <HomeBannerSlider
                    bannerUrl="../assets/images/banner/banner-1.jpg"
                    title1="  Integrated Circuits (ICs)"
                    title2="COMPONENT SUPPLYING"
                    desc=" Our Expert Suppliers of ICS can fight your supply battles"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <HomeBannerSlider
                    bannerUrl="../assets/images/banner/banner-2.jpg"
                    title1="  Integrated Circuits (ICs)"
                    title2="COMPONENT SUPPLYING"
                    desc=" Our Expert Suppliers of ICS can fight your supply battles"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <HomeBannerSlider
                    bannerUrl="../assets/images/banner/banner-3.jpg"
                    title1="  Integrated Circuits (ICs)"
                    title2="COMPONENT SUPPLYING"
                    desc=" Our Expert Suppliers of ICS can fight your supply battles"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[1200px] flex items-center justify-center  px-[20px] min-[1240px]:px-0">
          <FeaturedProducts />
        </div>
        <div className="w-full max-w-[1200px] text-color-TXT-dark font-bold px-[20px] min-[1240px]:px-0 mt-[16px]">
          Manufacturers
        </div>
        <div className="hidden min-[768px]:flex min-[768px]:items-center min-[768px]:justify-center w-full max-w-[1200px]  mt-[8px] mb-[40px]  py-[4px]">
          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={7}
              speed={3000}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/1global.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/aaronia.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/able-power-products.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/ablic.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/adtek.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/aismalibar.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/aker.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/amtech.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/fath.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/cassia.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/fabru.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/ranatec.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/boyd.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/choovio.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/xvisio.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/cliff.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/e-switch.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/eaton.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/vishay.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/comair.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/e2ip-technologies.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[150px] h-[50px]">
                  <img
                    src="../assets/images/manufacturers/bel_fuse.png"
                    className="relative w-full h-full rounded-md   py-[4px] object-contain  bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="min-[768px]:hidden w-full max-w-[1200px] flex items-center justify-center mt-[8px] mb-[40px]  py-[4px]">
          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={2}
              speed={3000}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/1global.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/aaronia.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/able-power-products.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/ablic.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/adtek.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/aismalibar.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/aker.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/amtech.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/fath.png"
                    className="relative w-full h-full rounded-md  object-contain p-[14px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[70px]">
                  <img
                    src="../assets/images/manufacturers/cassia.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#FFF] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <Footer />
      <ScrollToFirst />
    </>
  );
}
