const FEATURED_PRODUCTS = [
  {
    id: 1,
    productTitle: "SPE T1 Cable Assemblies",
    productImg:
      "../assets/images/products/molex_SPE-T1-cable-assemblies_image.jpg",
    productDesc:
      "Providing the standard T1 industrial interface and single-twisted-pair cabling to make Ethernet connectivity easy and affordable, including the transmission of data from the cloud to devices and sensors, while supporting miniaturization.",
    hasLabel: false,
    labelImg: "../assets/images/products/download.jpg",
  },

  {
    id: 2,
    productTitle: "Performance as Tough as Your Application",
    productImg:
      "../assets/images/products/AlphaWire_Xtra-GuardFlexFlexControlCable_Image.png",
    productDesc:
      "Xtra-Guard® Performance Cable's round construction makes it easy to install, seal, and route. This cable is designed to meet your needs and deliver reliable performance with nearly 5,000 standard constructions and a broad range of gauges, conductor counts, shielding options, and jacket color choices.",
    hasLabel: false,
    labelImg: "../assets/images/products/download.jpg",
  },
  {
    id: 3,
    productTitle: "New G9KA-E High Power PCB AC Relay",
    productImg: "../assets/images/products/omron_G9KA-E-PCB-relay_image.jpg",
    productDesc:
      "300A capable of supporting high capacity switching & carry currents . Low initial contact resistance reduces heat generation  . Support designs with high voltage requirements up to 1,000VAC  . Ideal in PV inverters/power conditioners & EV chargers",
  },
  {
    id: 4,
    productTitle: "Long-Lead Film Capacitors",
    productImg:
      "../assets/images/products/tdk_film-caps-pc-terminals_image.jpg",
    productDesc:
      "Select from EMI suppression types or MKT/MKP from TDK . Lead lengths: 26 ±2 mm, 17 ±1 mm, or 17 ±3 mm . Optimal stock levels  . Sample kit available",
  },
  {
    id: 5,
    productTitle: "SMT Common-Mode Line Filter",
    productImg: "../assets/images/products/wurth_WE-CNSW-filter_image.jpg",
    productDesc:
      "The current compensated data line filters offer high common mode noise suppression, particularly at high frequencies, ensuring clean signals. Its winding symmetry minimizes interference with high-speed signals. Available in sizes 0603 and 0805/1206/1812 and are AEC-Q200 qualified.",
  },
  {
    id: 6,
    productTitle: "Wolfspeed C3M™ Planar MOSFET Technology",
    productImg:
      "../assets/images/products/wolfspeed_C3M-planar-technology_image.jpg",
    productDesc:
      "Wolfspeed' advanced SiC MOSFET technology is offered in low-inductance discrete packing. These packages allow engineers to take full advantage of the high-frequency capability of the latest C3M planar MOSFET chips.",
  },
  {
    id: 7,
    productTitle: "EAO's New E-Stop Configurator",
    productImg:
      "../assets/images/products/eao_new-e-stop-configurator_image.jpg",
    productDesc:
      "Easily configure more than 130 components into more than 2,000 different  Easily configure more than 130 components into more than 2,000 different ",
  },
  {
    id: 8,
    productTitle: "XPC 1.5 with Push-X Technology",
    productImg:
      "../assets/images/products/PhoenixContact_XPC-1-5-Push-X-Technology_Image.jpg",
    productDesc:
      "Phoenix Contact's XPC series of connectors enable the fast and secure termination of conductors. Rigid and flexible conductors with or without ferrules are connected directly, effortlessly, and tool-free with innovative Push-X technology.",
  },
  {
    id: 9,
    productTitle: "Apollo4 Blue Plus SoC",
    productImg:
      "../assets/images/products/ambiq-apollo4-blue-plus-soc_image.jpg",
    productDesc:
      " raises the bar with the Apollo4 Blue Plus SoC. With one of the lowest dynamic powers for microcontrollers currently on the market, the SoC allows designers of next-generation wearables and battery-operated smart devices to take their innovative products to the next level.",
  },
];

export { FEATURED_PRODUCTS };
