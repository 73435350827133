import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";

export default function MManufactures() {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full min-h-screen flex flex-col items-center justify-between  bg-color-BG">
      <div className=" w-full  bg-color-A z-0">
        <div className=" p-[20px]">
          <h1 className="text-color-TXT text-[24px] font-bold">
            MANUFACTURERS
          </h1>
          <p className="text-color-TXT-1 text-[14px] mt-[20px] text-justify">
            ICWorldelect is an authorized distributor of electronic.
            <br />
            This means The customers can rest assured that the product they
            order is authentic and comes to ICWorldelect directly from the
            manufacturer.
          </p>
          <button
            className="w-full flex justify-center items-center text-[13px] font-bold Type_2 px-[16px] py-[8px] mt-[24px]"
            onClick={() => {
              navigate("/manufacturers/linecard");
            }}
          >
            Manufacturer Linecard
          </button>
        </div>
      </div>
      <div className="relative w-full h-full flex-1 flex items-center justify-center  py-[20px] z-10 ">
        <div className=" w-full flex flex-col justify-between items-center rounded-xl gap-y-[32px]">
          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={1.9}
              speed={3000}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/1global.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/aaronia.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/able-power-products.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/ablic.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/adtek.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/aismalibar.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/aker.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/amtech.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/fath.png"
                    className="relative w-full h-full rounded-md  object-contain p-[14px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide  p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/cassia.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={1.9}
              speed={7000}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/balluff.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/bel_fuse.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/bluelec.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/boyd.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/caig.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/ceva.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/comair.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/digi_international.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/knowles.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/desco.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={1.9}
              speed={4000}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/e2ip-technologies.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/eao_corporation.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/chipsee.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/choovio.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/chromeled.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/cliff.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/vishay.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/ranatec.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={1.9}
              speed={6000}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/cui.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/dialight.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/dirak.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/dremel.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/e-switch.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/fabru.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/eaton.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/endress-hauser.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/enocean.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/xvisio.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="w-full h-full flex justify-between items-center overflow-auto ">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              slidesPerView={1.9}
              speed={3500}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/arducam.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/schroff.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/advantage.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/formerica.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/harimatic.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/klein_tools.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/made-systems.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/mm-newman.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/north-star-sensors.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="manufactures-swiper-slide p-[4px]">
                <div className="w-[41vw] h-[80px]">
                  <img
                    src="../assets/images/manufacturers/parallax.png"
                    className="relative w-full h-full rounded-md  object-contain p-[8px] bg-[#f1f1f1] shadow-[0px_0px_5px_rgba(0,0,0,0.3)] hover:shadow-xl"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
