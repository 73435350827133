import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { Alphabet, Manufactures } from "../constants/constanst_manufactures";
import { FaArrowUp } from "react-icons/fa";
import ScrollToFirst from "../components/ScrollToFirst";

export default function Manufacturers_Linecard() {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState("#");
  const moveToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      setSelectedSection(sectionId);
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const targetDiv = [
        document.getElementById(Alphabet[0]),
        document.getElementById(Alphabet[1]),
        document.getElementById(Alphabet[2]),
        document.getElementById(Alphabet[3]),
        document.getElementById(Alphabet[4]),
        document.getElementById(Alphabet[5]),
        document.getElementById(Alphabet[6]),
        document.getElementById(Alphabet[7]),
        document.getElementById(Alphabet[8]),
        document.getElementById(Alphabet[9]),
        document.getElementById(Alphabet[10]),
        document.getElementById(Alphabet[11]),
        document.getElementById(Alphabet[12]),
        document.getElementById(Alphabet[13]),
        document.getElementById(Alphabet[14]),
        document.getElementById(Alphabet[15]),
        document.getElementById(Alphabet[16]),
        document.getElementById(Alphabet[17]),
        document.getElementById(Alphabet[18]),
        document.getElementById(Alphabet[19]),
        document.getElementById(Alphabet[20]),
        document.getElementById(Alphabet[21]),
        document.getElementById(Alphabet[22]),
        document.getElementById(Alphabet[23]),
        document.getElementById(Alphabet[24]),
        document.getElementById(Alphabet[25]),
        document.getElementById(Alphabet[26]),
      ];
      const targetDivPosition = [
        targetDiv[0].getBoundingClientRect().top,
        targetDiv[1].getBoundingClientRect().top,
        targetDiv[2].getBoundingClientRect().top,
        targetDiv[3].getBoundingClientRect().top,
        targetDiv[4].getBoundingClientRect().top,
        targetDiv[5].getBoundingClientRect().top,
        targetDiv[6].getBoundingClientRect().top,
        targetDiv[7].getBoundingClientRect().top,
        targetDiv[8].getBoundingClientRect().top,
        targetDiv[9].getBoundingClientRect().top,
        targetDiv[10].getBoundingClientRect().top,
        targetDiv[11].getBoundingClientRect().top,
        targetDiv[12].getBoundingClientRect().top,
        targetDiv[13].getBoundingClientRect().top,
        targetDiv[14].getBoundingClientRect().top,
        targetDiv[15].getBoundingClientRect().top,
        targetDiv[16].getBoundingClientRect().top,
        targetDiv[17].getBoundingClientRect().top,
        targetDiv[18].getBoundingClientRect().top,
        targetDiv[19].getBoundingClientRect().top,
        targetDiv[20].getBoundingClientRect().top,
        targetDiv[21].getBoundingClientRect().top,
        targetDiv[22].getBoundingClientRect().top,
        targetDiv[23].getBoundingClientRect().top,
        targetDiv[24].getBoundingClientRect().top,
        targetDiv[25].getBoundingClientRect().top,
        targetDiv[26].getBoundingClientRect().top,
      ];

      for (let i = 0; i < 27; i++) {
        if (
          targetDivPosition[i] < window.innerHeight &&
          targetDivPosition[i] >= 0
        ) {
          setSelectedSection(Alphabet[i]);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header />
      <div className="relative w-full h-full min-h-screen flex flex-col justify-start items-center  bg-color-BG">
        <div className="hidden fixed w-full h-[120px] min-[1024px]:flex justify-center items-center bg-color-A z-20">
          <span className="absolute h-[3px] w-full bg-color-BG-1"></span>
          <div className="relative  w-full h-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0 flex justify-between items-center z-10 text-[16px] font-medium text-color-TXT-1">
            <div className="w-[180px] text-[20px] font-bold hidden min-[1240px]:block">
              MANUFACTURER LINECARD
            </div>
            {Alphabet.map((it) => (
              <div
                className={`cursor-pointer h-[22px] w-[22px] min-[1240px]:w-[32px] min-[1240px]:h-[32px] text-[14px] min-[1240px]:text-[16px] flex justify-center items-center rounded-full border-color-BG-1 border-[2px] pb-[1px] ${
                  selectedSection === it
                    ? "bg-color-BG text-color-A"
                    : "bg-color-A hover:bg-color-BG hover:text-color-A"
                } `}
                onClick={(e) => {
                  moveToSection(it);
                }}
              >
                {it}
              </div>
            ))}
          </div>
        </div>

        <div className="w-full h-[185px] fixed min-[1024px]:hidden flex flex-col items-center justify-center bg-color-A z-20 pb-[20px]">
          <div className="pl-[24px] w-full resize-none">
            <h1 className="text-[14px] text-left font-bold text-color-BG whitespace-nowrap">
              MANUFACTURER LINECARD
            </h1>
            <p className="text-[12px] text-left font-normal text-color-BG-1 whitespace-nowrap">
              select the first Letter of your Manufacturers:
            </p>
          </div>
          <div className="w-full h-full grid grid-cols-9 items-center justify-center rounded-md mt-[12px] gap-y-[12px] pl-[24px] pr-[20px]">
            {Alphabet.map((it) => (
              <div
                className={`cursor-pointer h-[30px] w-[30px]  text-[16px] flex justify-center items-center rounded-full  border-color-BG-1 border-[2px] pb-[1px] ${
                  selectedSection === it
                    ? "bg-color-BG text-color-A"
                    : "bg-color-A text-color-TXT-1"
                } `}
                onClick={(e) => {
                  moveToSection(it);
                }}
              >
                {it}
              </div>
            ))}
          </div>
        </div>

        <div className="relative w-full h-full px-[20px] min-[1240px]:max-w-[1200px] min-[1240px]:px-0  flex flex-col justify-between items-start mt-[180px] min-[1024px]:mt-[125px] mb-[24px] z-10">
          {Manufactures.map((it) => (
            <>
              <span
                key={it.key}
                id={it.key}
                className="w-full h-full text-[20px] font-semibold border-b-[2px] mt-[24px] border-color-A text-color-A"
              >
                {it.key}
              </span>
              <div className="w-full grid grid-cols-1 min-[1024px]:grid-cols-3 mt-[8px]">
                {it?.value?.map((ii) => (
                  <div className="flex justify-start items-center gap-x-[16px] text-[14px] font-medium">
                    {ii}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>

        <ScrollToFirst />
      </div>
      <Footer />
    </>
  );
}
